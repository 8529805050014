<template>
  <div class="w-100">
    <!-- Collapsible table section -->
    <div v-for="(section, index) in sections" :key="section">
      <CollapsibleButton
        :id="section"
        :visible="activeSections.includes(section)"
        :label="$t(section)"
        @click="toggleSection(section)"
      >
        <template #content>
          <b-table-simple class="z-table">
            <!-- Table header -->
            <b-thead class="z-table-header">
              <!-- Behaviour type header -->
              <b-th v-if="isISQ" class="px-4 py-2 align-middle z-table-left-col">{{
                $t('behaviourType')
              }}</b-th>
              <b-th class="p-0">
                <div class="d-flex justify-content-between">
                  <!-- Question header -->
                  <TableMiddleColumn :content="$t('question')" class="py-2" />
                  <!-- Answers header -->
                  <TableRightColumn :data="answerHeaderData" contentKey="label" />
                </div>
              </b-th>
            </b-thead>
            <!-- Table body -->
            <b-tbody class="z-table-body">
              <b-tr
                v-for="(dataSection, sectionIndex) in tableItems[index]"
                :key="isISQ ? dataSection[0].behaviourType : dataSection.id"
              >
                <!-- Left column -->
                <b-td v-if="isISQ" class="px-4 text-center z-table-left-col">{{
                  $t(dataSection[0].behaviourType)
                }}</b-td>
                <!-- Middle + right column -->
                <b-td class="p-0">
                  <div
                    v-for="(question, questionIndex) in dataSection"
                    :key="question.order"
                    :class="[
                      'd-flex',
                      'justify-content-between',
                      [getRowBackgroundColor(tableItems[index], sectionIndex, questionIndex)],
                    ]"
                  >
                    <!-- Question-->
                    <TableMiddleColumn :content="`${question.order + 1}. ${question.title}`" />

                    <!-- Answers -->
                    <TableRightColumn
                      :data="question.answers"
                      contentKey="scoreAnswer"
                      isAnswerColumn
                    />
                  </div>
                  <!-- Total scores -->
                  <div
                    :class="[
                      'd-flex',
                      'justify-content-between',
                      [getRowBackgroundColor(tableItems[index], sectionIndex, dataSection.length)],
                    ]"
                  >
                    <!-- Middle column-->
                    <TableMiddleColumn :content="$t('total')" />

                    <!-- Total scores -->
                    <TableRightColumn :data="totalScores[index][sectionIndex]" class="z-score" />
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </CollapsibleButton>
    </div>
  </div>
</template>

<script>
import CollapsibleButton from '@/components/CollapsibleButton';
import TableRightColumn from '@/components/TableRightColumn';
import { sortArray } from '@/utils/functions';
import { influenceStyleTypes, graphSections } from '@/utils/enums';

import TableMiddleColumn from './TableMiddleColumn';

/**
 * @file
 * @description ISQ/ISI table list component
 * @author Kristine de Vries
 */
export default {
  name: 'TableList',
  components: {
    CollapsibleButton,
    TableMiddleColumn,
    TableRightColumn,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    isISQ: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      sections: Object.values(influenceStyleTypes),
      activeSections: [],
      answerHeaderData: [],
      totalScores: [],
    };
  },
  computed: {
    /**
     * @description Get table items for all sections
     * @author Kristine de Vries
     */
    tableItems() {
      return this.sections.map(section => this.getSectionData(section));
    },
  },
  created() {
    // Initiate table data
    this.answerHeaderData = this.getAnswerHeader();
    this.totalScores = this.tableItems.map(influenceStyleData =>
      influenceStyleData.map(sectionData => this.getTotalScores(sectionData)),
    );
  },
  methods: {
    /**
     * @description Toggles active state of a selected section
     * @author Kristine de Vries
     */
    toggleSection(section) {
      if (this.activeSections.includes(section)) {
        this.activeSections = this.activeSections.filter(item => item !== section);
      } else {
        this.activeSections = [...this.activeSections, section];
      }
    },
    /**
     * @description Get data for given influence style section
     * @author Kristine de Vries
     */
    getSectionData(influenceStyle) {
      const behaviourTypes = graphSections[influenceStyle];

      // For ISQ return data per behaviourType
      if (this.isISQ) {
        return behaviourTypes.map(({ label: behaviourType }) =>
          sortArray(this.data[behaviourType]),
        );
      }

      // For ISI return data per influenceStyle
      return [this.data[influenceStyle]];
    },
    /**
     * @description Returns an array of strings for the answers column header
     * @author Kristine de Vries
     */
    getAnswerHeader() {
      const { answers } = Object.values(this.data)?.[0]?.[0] || {};
      if (answers) {
        return answers.map(({ firstName, lastName }) => ({
          key: firstName ? `${firstName}${lastName}` : 'you',
          label: firstName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : this.$t('you'),
        }));
      }
      return [];
    },
    /**
     * @description Retruns an array of total scores for each influence style section
     * @author Kristine de Vries
     */
    getTotalScores(data) {
      const { answers } = data?.[0] || {};
      const totalScores = [];

      if (answers) {
        const numberOfDataGroups = answers.length;
        for (let i = 0; i < numberOfDataGroups; i += 1) {
          totalScores.push(
            data
              .map(q => q.answers.find(a => a.order === i))
              .reduce((acc, curr) => {
                const { scoreAnswer } = curr;
                return typeof scoreAnswer === 'number' ? acc + scoreAnswer : acc;
              }, 0),
          );
        }
      }
      return totalScores;
    },
    /**
     * @description Returns correct background color for each table row depending on the amount of
     * question items in each of behaviourType sections within the influence style sections, current
     * behavior type section index and current question item index
     * index
     * @author Kristine de Vries
     */
    getRowBackgroundColor(influenceStyleData, sectionIndex, rowIndex) {
      const sectionLengths = influenceStyleData.map(array => array.length + 1);
      const previousItemLength = sectionLengths
        .slice(0, sectionIndex)
        .reduce((acc, curr) => acc + curr, 0);

      return (previousItemLength + rowIndex) % 2 === 0 ? '' : 'bg-secondary';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_transitions.scss';
@import '@styles/partials/_tables.scss';
</style>
