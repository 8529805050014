<template>
  <div class="w-100">
    <div class="z-password-input-wrapper">
      <!-- Password input -->
      <b-form-input
        v-model="password"
        :placeholder="$t('password')"
        :type="isVisible ? 'text' : 'password'"
        class="z-password-input"
        @keyup.enter.native="submit"
      />

      <!-- Show/Hide password button -->
      <IconButton
        v-if="!!password.length"
        :src="isVisible ? 'hidePassword' : 'showPassword'"
        class="d-flex align-items-center justify-content-center p-0 z-password-toggle-icon"
        @click="togglePasswordVisibility"
      />
    </div>

    <!-- Submit button -->
    <PrimaryButton
      :label="$t('validate')"
      :loading="loading"
      :disabled="!password.length"
      class="my-4"
      @click="submit"
    />

    <!-- Secondary buttons -->
    <div class="d-flex flex-row align-items-center justify-content-between">
      <SecondaryButton :label="$t('otherEmail')" @click="navigate('EMAIL')" />
      <SecondaryButton :label="$t('needHelp')" @click="openModal" />
    </div>
  </div>
</template>

<script>
/**
 * @file
 * @description Password step component of the login flow
 * @author Kristine de Vries
 */
import IconButton from '@/components/IconButton';
import PrimaryButton from '@/components/PrimaryButton';
import SecondaryButton from '@/components/SecondaryButton';
import { modalTypes } from '@/utils/enums';

const { loginPasswordHelp } = modalTypes;

export default {
  name: 'Password',
  components: {
    IconButton,
    PrimaryButton,
    SecondaryButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      isVisible: false,
    };
  },
  methods: {
    /**
     * @description Emit navigation event with given path
     * @author Kristine de Vries
     */
    navigate(path) {
      this.$emit('navigate', path);
    },
    /**
     * @description Emit open modal event
     * @author Kristine de Vries
     */
    openModal() {
      this.$emit('openModal', loginPasswordHelp);
    },
    /**
     * @description Toggles visibility nof the password field
     * @author Kristine de Vries
     */
    togglePasswordVisibility() {
      this.isVisible = !this.isVisible;
    },
    /**
     * @description Submit user's password input
     * @author Kristine de Vries
     */
    submit() {
      this.$emit('submit', this.password);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-password-input-wrapper {
  position: relative;
}

.z-password-input {
  margin-top: $spacer * 3.5;
}

.z-password-toggle-icon {
  position: absolute;
  right: $spacer * 0.875;
  top: $spacer * 0.625;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
