<template>
  <div class="pt-8 z-summary">
    <h2
      class="mb-6 z-summary-title"
      v-html="$t('ISQISISummaryTitle', { formType: `${$t(formType)}` })"
    />

    <p
      v-if="isFormFinished"
      class="my-6 z-summary-message"
      v-html="$t('ISQISISummaryDescription')"
    />
    <!-- Button linking to result graph -->
    <PrimaryButton :label="$t('viewGraph')" @click="navigateToGraph(formType)" />
  </div>
</template>

<script>
import PrimaryButton from '@/components/PrimaryButton';
/**
 * @file
 * @description ISQ/ISI summary screen
 * @author Thiago Fazzi
 * @author Kristine de Vries
 * @author Miguel Aguiar
 */
export default {
  name: 'ISQISISummary',
  components: {
    PrimaryButton,
  },
  props: {
    isFormFinished: {
      type: Boolean,
      required: true,
      default: false,
    },
    formType: {
      type: String,
      require: true,
      default: '',
    },
  },
  methods: {
    /**
     * @description Navigate to ISQ or ISI result graph
     * @author Miguel Aguiar
     */
    navigateToGraph(type) {
      this.$router.push({ name: 'ISQISIResults', params: { type } });
    },
  },
};
</script>

<style lang="scss">
@import '@styles/base.scss';

.z-summary {
  padding-bottom: $spacer * 6;

  &-title {
    line-height: 1.8;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
  }

  &-message {
    font-size: $font-size-lg;
  }
}
</style>
