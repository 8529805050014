<template>
  <b-table-simple class="z-table">
    <!-- Table body -->
    <b-tbody>
      <b-tr v-for="(row, rowIndex) in data" :key="rowIndex" class="z-table-questionnaire-row">
        <b-td
          v-for="(column, columnIndex) in row"
          :key="`${rowIndex - columnIndex}`"
          :style="{ '--background-color': getCellBackgroundColor(rowIndex, columnIndex, column) }"
          :class="['px-0', getCellStyleClass(type, rowIndex, column)]"
          :colspan="getColspan(type, rowIndex, column)"
        >
          <b-button
            v-if="column.isAnswer"
            variant="transparent"
            class="p-0"
            @click="highlightAnswer(column)"
          >
            {{ column.value }}
          </b-button>
          <span v-else>{{ column.value }}</span>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import eventBus from '@/utils/eventBus';
import colors from '@/assets/styles/partials/_export.module.scss';

const { white, secondary } = colors;

let CELL_COLOR_QUESTIONNAIRE_FEEDBACK = white;

/**
 * @file
 * @description 360 Questionnaire table content component,
 * when both 360 questionnaire measurements are available
 * @author Kristine de Vries
 */
export default {
  name: 'TableContentQuestionnaire',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
    headerRow: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * @description Get the colspan attribute of each column
     * @author Kristine de Vries
     */
    getColspan(type, rowIndex, column) {
      // RATING type rows
      if (type === 'RATING') {
        const { isAnswer, isTotalScore } = column;
        if (rowIndex % 3 === 0) return 30;
        if (isAnswer || isTotalScore) return 1;
        return 2;
      }
      // OPEN question rows
      return rowIndex % 2 === 0 ? 2 : 1;
    },
    /**
     * @description Get the cell background color
     * @author Kristine de Vries
     */
    getCellBackgroundColor(rowIndex, columnIndex, column) {
      const { isAnswer, isTotalScore } = column;

      // Question rows
      if (rowIndex % 3 === 0) return secondary;

      // Answer/ total score rows
      if (isAnswer || isTotalScore) {
        //  Every two cells switch the background color of answer/total score columns
        if (columnIndex !== 0 && columnIndex % 2 === 0) {
          CELL_COLOR_QUESTIONNAIRE_FEEDBACK =
            CELL_COLOR_QUESTIONNAIRE_FEEDBACK === white ? secondary : white;
        }
        return CELL_COLOR_QUESTIONNAIRE_FEEDBACK;
      }
      // Supporter initial rows
      return columnIndex % 2 === 0 ? white : secondary;
    },
    /**
     * @description Get the cell style
     * @author Kristine de Vries
     */
    getCellStyleClass(type, rowIndex, column) {
      const { isAnswer, isTotalScore, highlighted } = column;

      if (type === 'RATING') {
        // Question rows
        if (rowIndex % 3 === 0) {
          return 'px-4 z-table-closed-question-column';
        }
        // Answer value rows
        if (isAnswer || isTotalScore) {
          return [
            'text-center z-table-closed-score-column z-score',
            isAnswer && 'z-answer',
            highlighted && 'z-highlighted',
          ];
        }
        // Supporter initial rows
        return 'text-center z-table-closed-supporter-column z-supporter-initials';
      }
      // OPEN type questions
      return [
        'px-4',
        rowIndex % 2 === 0 ? 'z-table-open-supporter-column' : 'z-table-open-answer-column',
      ];
    },
    /**
     * @description Emit highlightAnswer event
     * @author Kristine de Vries
     */
    highlightAnswer(item) {
      eventBus.$emit('highlightAnswer', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_tables.scss';

.z-table-closed-question-column {
  background-color: var(--background-color);
}

.z-table-closed-supporter-column {
  width: calc(1 / 15 * 100%);
  background-color: var(--background-color);
  border: 1px solid $shadow-grey;
}

.z-table-closed-score-column {
  width: calc(1 / 30 * 100%);
  background-color: var(--background-color);
  border: 1px solid $shadow-grey;
}

.z-supporter-initials {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

.z-table-open-supporter-column {
  background-color: $secondary;
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

.z-table-open-answer-column {
  width: 50%;
  background-color: $white;
  border: 1px solid $shadow-grey;
  vertical-align: top !important;
}
</style>
