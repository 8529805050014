<template>
  <div class="d-flex flex-column justify-content-between z-app-container">
    <router-view />
    <!-- Footer -->
    <Footer v-if="$route.meta.displayFooter" />
  </div>
</template>

<script>
import Footer from '@/components/layout/Footer';

export default {
  name: 'RouterBase',
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.z-app-container {
  min-height: 100vh;
}
</style>
