import Vue from 'vue';
import { SET_STATE_ITEM, UPDATE_STATE_ITEM, UPDATE_USER_ITEM, RESET_STATE } from './actions';

export default {
  /**
   * @description Sets an item in the state
   * @author Kristine de Vries
   */
  [SET_STATE_ITEM](state, payload) {
    const { name, data } = payload;
    Vue.set(state, name, data);
  },
  /**
   * @description Updates an item in the local state
   * @author Kristine de Vries
   */
  [UPDATE_STATE_ITEM](state, payload) {
    const { name, id, data } = payload;
    Vue.set(state, name, { ...state[name], [id]: { ...state[name][id], ...data } });
  },
  /**
   * @description Updates a property in the user object
   * @author Thiago Fazzi
   */
  [UPDATE_USER_ITEM](state, payload) {
    const { name, data } = payload;
    state.user[name] = data;
  },
  /**
   * @description Reset the state to the default state
   * @author Kristine de Vries
   */
  [RESET_STATE](state) {
    Object.assign(state, {});
  },
};
