<template>
  <b-progress class="z-progress-bar" height=".5rem" :max="maxSteps + 1">
    <b-progress-bar
      :value="questionIndex + 1"
      :class="[
        questionIndex + 1 === maxSteps + 1 ? 'z-progress-bar-finished' : 'z-progress-bar-progress',
      ]"
    />
  </b-progress>
</template>

<script>
/**
 * @file
 * @description Progress bar that indicates the user's progress in a form or module.
 * @author Kristine de Vries
 */
export default {
  name: 'ProgressBar',
  props: {
    maxSteps: {
      type: Number,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: true,
      default: 1,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/base.scss';

.z-progress-bar {
  border-radius: 0;

  &-progress {
    border-radius: 0 0.5rem 0.5rem 0;

    &-finished {
      border-radius: 0;
    }
  }
}
</style>
