<template>
  <div class="w-100 z-table-middle-col">
    <p class="px-4 py-1 mb-0">{{ content }}</p>
  </div>
</template>
<script>
/**
 * @file
 * @description ISQ/ISI table middle column component
 * @author Kristine de Vries
 */
export default {
  name: 'TableMiddleColumn',
  props: {
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_tables.scss';
</style>
