/**
 * @file
 * @description Amplify config declaration
 * @author Kristine de Vries
 */
const AWSConfig = {
  aws_project_region: process.env.APPSYNC_REGION,
  aws_cognito_identity_pool_id: process.env.COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.APPSYNC_REGION,
  aws_user_pools_id: process.env.USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.USER_POOLS_WEB_CLIENT_ID,
  oauth: {},
  aws_appsync_graphqlEndpoint: process.env.APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.APPSYNC_AUTHENTICATION_TYPE,
  aws_cloud_logic_custom: [
    {
      name: process.env.REST_API_NAME,
      endpoint: process.env.REST_API_ENDPOINT,
      region: process.env.APPSYNC_REGION,
    },
  ],
  aws_user_files_s3_bucket: process.env.USER_FILES_S3_BUCKET,
  aws_user_files_s3_bucket_region: process.env.APPSYNC_REGION,
};

export default AWSConfig;
