<template>
  <div class="d-flex flex-column w-100 flex-grow-1">
    <Header
      :maxSteps="maxSteps"
      :questionIndex="questionIndex"
      :progressBar="currentStep !== 'INTRODUCTION'"
      :title="title"
      class="sticky-top"
    />
    <transition mode="out-in" :name="navigation === 'next' ? 'z-slide-next' : 'z-slide-previous'">
      <div :key="currentStep" class="flex-grow-1 z-form-wrapper">
        <!-- Introduction -->
        <Introduction
          v-if="currentStep === 'INTRODUCTION'"
          :title="title"
          :description="description"
          :formType="form.type"
          @startForm="() => navigateForm('next', 'QUESTIONNAIRE')"
        />

        <!-- Questionnaire -->
        <Questionnaire
          v-if="currentStep === 'QUESTIONNAIRE'"
          :questionIndex="questionIndex"
          :maxSteps="maxSteps"
          :questions="formQuestions"
          :currentQuestion="currentQuestion"
          :answers="answers"
          :isFormAnswerable="isFormAnswerable"
          :isFormFinished="form.status === 'FINISHED'"
          :editable="form.open"
          :isSkippable="isSkipButtonVisible"
          :formType="form.type"
          @answerChange="handleAnswerChange"
          @navigate="navigateQuestionnaire"
          @navigateToIntroduction="() => navigateForm('previous', 'INTRODUCTION')"
        />
      </div>
    </transition>

    <!-- Questionnaire footer container -->
    <transition appear mode="out-in" name="z-fade">
      <div
        v-if="currentStep === 'QUESTIONNAIRE' && form.open"
        class="fixed-bottom bg-white py-3 pt-5 z-button-wrapper"
      >
        <b-container class="mb-4 mt-6">
          <b-form-row>
            <!-- Back Button -->
            <b-col col :offset-md="isSkipButtonVisible ? 1 : 2" md="4">
              <PrimaryButton
                v-if="description || questionIndex !== 0"
                :label="questionIndex === 0 ? $t('back') : $t('previous')"
                :disabled="loading"
                variant="secondary"
                @click="
                  questionIndex === 0
                    ? navigateForm('previous', 'INTRODUCTION')
                    : navigateQuestionnaire(-1)
                "
              />
            </b-col>

            <!-- Skip Button -->
            <b-col col md="2" v-if="isSkipButtonVisible">
              <PrimaryButton
                :label="$t('skip')"
                :disabled="isSkipButtonDisabled"
                variant="secondary"
                @click="navigateQuestionnaire(1, 'SKIP')"
              />
            </b-col>

            <!-- Next Button -->
            <b-col col md="4">
              <PrimaryButton
                :label="!isLastStep ? $t('next') : $t('finish')"
                :loading="loading"
                :disabled="isNextButtonDisabled"
                @click="navigateQuestionnaire(1, 'SUBMIT')"
              />
            </b-col>
          </b-form-row>
        </b-container>
      </div>
    </transition>
    <!-- Footer -->
    <Footer v-if="currentStep !== 'QUESTIONNAIRE' || form.status !== 'STARTED'" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrimaryButton from '@/components/PrimaryButton';
import { showError, userAgent } from '@/mixins/functionalMixins';
import Footer from '@/components/layout/Footer';
import { formFlowSteps, cardStatusLabels, questionTypes, userRoles } from '@/utils/enums';
import {
  getTranslations,
  getQuestionAnswer,
  createCompositeKey,
  validateEmail,
  removeEmptyProperties,
} from '@/utils/functions';

import Introduction from './components/Introduction';
import Questionnaire from './components/Questionnaire';
import Header from './components/Header';

const { TRAINER } = userRoles;
const { INTRODUCTION, QUESTIONNAIRE, SUMMARY } = formFlowSteps;
const { OPEN, SCALE } = questionTypes;
const {
  CLOSED: { id: CLOSED },
} = cardStatusLabels;

/**
 * @file
 * @description Form detail screen
 * @author Thiago Fazzi
 * @author Kristine de Vries
 */
export default {
  name: 'Form',
  components: {
    Header,
    Questionnaire,
    Introduction,
    PrimaryButton,
    Footer,
  },
  mixins: [userAgent, showError],
  data() {
    return {
      formId: this.$route.params.id,
      eventId: null,
      answers: [],
      currentStep: INTRODUCTION,
      questionIndex: 0,
      loading: false,
      navigation: 'previous',
      isFormAnswerable: false,
      browser: 'OTHER',
    };
  },
  created() {
    this.eventId = this.getStateItems('currentEventId');
    this.isFormAnswerable = this.checkIfFormIsAnswerable(this.formQuestions);
    this.answers =
      this.formQuestions
        .filter(({ type }) => type !== SUMMARY)
        .map(question => getQuestionAnswer(question)) || [];
    // Get initial form step
    this.currentStep = this.getInitialStep();
    // Get initial questionnaire index
    this.questionIndex = this.getInitialQuestionIndex();
    // Get current browser
    this.browser = this.getBrowser();
  },
  computed: {
    ...mapGetters(['getStateItems', 'getFormQuestions', 'getFormsWithStatus']),
    /**
     * @description Get current form
     * @returns {Object}
     * @author Kristine de Vries
     */
    form() {
      const { finishedForms } = this.getStateItems('user');
      const eventId = this.getStateItems('currentEventId');
      const forms = this.getFormsWithStatus();
      const currentForm = forms.find(({ id }) => id === this.formId);
      // Check if current form is finished and open or closed
      const finishedForm = finishedForms[createCompositeKey(eventId, this.formId)];

      return {
        ...currentForm,
        open: finishedForm ? finishedForm.open : !(currentForm.status === CLOSED),
      };
    },
    /**
     * @description Return participant's current registrationId
     * If current user's role is `TRAINER` fallback to `null`
     * @author Kristine de Vries
     */
    registrationId() {
      const { role } = this.getStateItems('user');
      return this.getStateItems('currentRegistrationId', role === TRAINER ? null : undefined);
    },
    /**
     * @description Get title translation from the form
     * @returns {string}
     * @author Thiago Fazzi
     */
    title() {
      return getTranslations(this.form).title;
    },
    /**
     * @description Get description translation from the form
     * @returns {string}
     * @author Thiago Fazzi
     */
    description() {
      return getTranslations(this.form).description;
    },
    /**
     * @description Get an array of current form questions from vuex state
     * @returns {Array}
     * @author Kristine de Vries
     */
    formQuestions() {
      const questions = this.getFormQuestions(this.formId);
      return this.isFormAnswerable ? [...questions, { type: 'SUMMARY', answers: [] }] : questions;
    },
    /**
     * @description Get current questionnaire item
     * @returns {Object}
     * @author Kristine de Vries
     */
    currentQuestion() {
      return this.formQuestions[this.questionIndex];
    },
    /**
     * @description Get current question answer
     * @returns {Object}
     * @author Kristine de Vries
     */
    currentAnswer() {
      return this.answers[this.questionIndex];
    },
    /**
     * @description Get total number of steps in the questionnaire
     * @returns {number}
     * @author Kristine de Vries
     */
    maxSteps() {
      return this.formQuestions.length - 1;
    },
    /**
     * @description Check if we are currently on the last step of the form
     * @returns {boolean}
     * @author Kristine de Vries
     */
    isLastStep() {
      return this.questionIndex === this.maxSteps;
    },
    /**
     * @description Check if primary button is currently disabled
     * @returns {Boolean}
     * @author Kristine de Vries
     */
    isNextButtonDisabled() {
      const { open } = this.form;
      const { required, type: currentType, explanationField } = this.currentQuestion;

      // If component is loading return true
      if (this.loading) {
        return true;
      }
      // Otherwise check if form is opened and the current question required and has an answer
      // If form is closed allow user's to navigate, but not to submit the form
      const lastAnsweredIndex =
        this.formQuestions
          .filter(({ type }) => type !== SUMMARY)
          .map(item => item.answers.length > 0)
          .lastIndexOf(true) || 0;

      if (open && required) {
        const answerType = questionTypes[currentType]?.answerType;
        const { contactManagerName, contactManagerEmail, explanation } = this.currentAnswer;
        let hasAnswer = this.currentAnswer?.[answerType];

        if (answerType === 'contactManager') {
          hasAnswer =
            contactManagerName && contactManagerEmail && validateEmail(contactManagerEmail);
        }

        // If question is required and has an explanation field check that also the explanation field is filled in
        return explanationField ? !hasAnswer || !explanation : !hasAnswer;
      }
      return open ? false : lastAnsweredIndex < this.questionIndex;
    },
    /**
     * @description Check if skip button should be visible
     * @returns {Boolean}
     * @author Milo Silva
     */
    isSkipButtonVisible() {
      const { required, type: questionType } = this.currentQuestion;
      const { completed } = getQuestionAnswer(this.currentQuestion);

      // If question is not required, has not been answered previously
      // and is either SCALE or OPEN type, show skip button
      return !required && [OPEN.id, SCALE.id].includes(questionType) && !completed;
    },
    /**
     * @description Check if skip button should be disabled
     * @returns {Boolean}
     * @author Milo Silva
     */
    isSkipButtonDisabled() {
      const { type: questionType } = this.currentQuestion;
      const answerType = questionTypes[questionType]?.answerType;
      const answerField = this.currentAnswer[answerType];

      return answerField !== null && answerField !== undefined && answerField !== '';
    },
  },
  methods: {
    ...mapActions(['mutateItem', 'updateFinishedForms']),
    /**
     * @description Get initial form step
     * @returns {string}
     * @author Kristine de Vries
     */
    getInitialStep() {
      const { open } = this.form;
      if (open) {
        if (this.formQuestions.some(item => item.answers.length)) {
          return QUESTIONNAIRE;
        }
        return this.description ? INTRODUCTION : QUESTIONNAIRE;
      }
      return QUESTIONNAIRE;
    },
    /**
     * @description Get initial questionnaire index depending on current form status
     * @returns {number}
     * @author Kristine de Vries
     */
    getInitialQuestionIndex() {
      const { open: formOpen } = this.form;

      const lastAnsweredQuestion = this.formQuestions
        .map(({ facilitatorId, answers }) =>
          facilitatorId
            ? !!answers.length && answers.some(answer => answer.facilitatorId === facilitatorId)
            : !!answers.length,
        )
        .lastIndexOf(true);

      const lastIndex = this.formQuestions.length - 1;

      // If the form is open, set starting index to item proceeding last answered question
      // or the last index of questions list.
      // If form is closed, show the summary screen
      if (formOpen) {
        return Math.min(lastAnsweredQuestion + 1, lastIndex);
      }
      return this.isFormAnswerable ? lastIndex : 0;
    },
    /**
     * @description Checks if form is answerable
     * @returns {Boolean}
     * @author Milo Silva
     * @author Kristine de Vries
     */
    checkIfFormIsAnswerable(questions) {
      return questions.some(question => {
        switch (question.type) {
          case 'HTML':
          case 'VIDEO':
            return false;
          default:
            return true;
        }
      });
    },
    /**
     * @description Update current answers
     * @author Kristine de Vries
     */
    updateAnswers(newValue) {
      this.answers = this.answers.map((currentAnswer, index) =>
        index === this.questionIndex ? { ...currentAnswer, ...newValue } : currentAnswer,
      );
    },
    /**
     * @description Update answers in local state when an answer changes
     * @author Kristine de Vries
     */
    handleAnswerChange(field, value) {
      // If user is submitting an optionAnswer or a scoreAnswer - check the new value against the current value.
      // If they are equal set the value back to null
      if (field === 'optionAnswer' || field === 'scoreAnswer') {
        this.updateAnswers({
          [field]: this.currentAnswer[field] === value ? null : value,
        });

        // For contact manager questions set both contactManagerName and contactManagerEmail in the answer
      } else if (field === 'contactManager') {
        const { contactManagerName, contactManagerEmail } = value;
        this.updateAnswers({ contactManagerName, contactManagerEmail });
      } else {
        this.updateAnswers({ [field]: value });
      }
    },
    /**
     * @description Navigate form (between Introduction and Questionnaire)
     * @author Kristine de Vries
     */
    navigateForm(direction, newStep) {
      this.navigation = direction;
      this.currentStep = newStep;
    },
    /**
     * @description Navigate Questionnaire slides
     * @author Kristine de Vries
     */
    navigateQuestionnaire(difference, action) {
      if (difference > 0 && this.isLastStep) {
        this.submitForm();
      } else if (difference > 0) {
        if (action === 'SUBMIT') this.submitAnswer(this.questionIndex);
        else if (action === 'SKIP') this.skipAnswer(this.questionIndex);
      } else {
        this.questionIndex += difference;
      }
    },
    /**
     * @description Submit answer to the DB
     * @author Thiago Fazzi
     * @author Kristine de Vries
     */
    async submitAnswer(answerIndex) {
      try {
        this.loading = true;
        const answer = this.answers[answerIndex];
        const question = this.formQuestions[answerIndex];
        const { type } = question;

        const oldAnswer = getQuestionAnswer(question);

        const answerChanged = this.checkIfAnswerChanged(type, oldAnswer, answer);

        // Additional properties to be saved with the answer
        const additionalAnswerData = {
          completed: true,
          registrationId: this.registrationId,
          platform: this.browser,
        };

        if (answerChanged && oldAnswer.id) {
          const result = await this.mutateItem({
            name: 'answers',
            mutationName: 'updateAnswer',
            item: removeEmptyProperties({
              id: oldAnswer.id,
              ...answer,
              ...additionalAnswerData,
            }),
          });
          this.updateAnswers(answerIndex, result);
        } else if (answerChanged) {
          const { id: userId, tenantId } = this.getStateItems('user');

          const result = await this.mutateItem({
            name: 'answers',
            mutationName: 'createAnswer',
            item: {
              ...answer,
              userId,
              tenantId,
              eventId: this.eventId,
              ...additionalAnswerData,
            },
          });

          this.updateAnswers(answerIndex, result);
        }

        this.questionIndex += 1;
      } catch (e) {
        this.showError();
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Submits answer with skipped field set to indicate that the answer was skipped
     * @author Thiago Fazzi
     * @author Kristine de Vries
     */
    async skipAnswer(answerIndex) {
      try {
        this.loading = true;
        const question = this.formQuestions[answerIndex];
        const { id: questionId } = question;
        const { id: userId, tenantId } = this.getStateItems('user');

        const result = await this.mutateItem({
          name: 'answers',
          mutationName: 'createAnswer',
          item: {
            questionId,
            userId,
            tenantId,
            eventId: this.eventId,
            registrationId: this.registrationId,
            platform: this.browser,
            completed: true,
            skipped: true,
          },
        });

        this.updateAnswers(answerIndex, result);
        this.questionIndex += 1;
      } catch (e) {
        this.showError();
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Submit form to the DB
     * @author Thiago Fazzi
     */
    async submitForm() {
      try {
        this.loading = true;
        const formType = this.form.type;
        await this.updateFinishedForms({
          item: {
            formId: this.formId,
            eventId: this.eventId,
            open: false,
          },
        });
        if (formType !== 'ISQ' && formType !== 'ISI') {
          this.$router.push({ name: 'dashboard' });
        }
      } catch (e) {
        this.showError({
          body: this.$t('errorSavingAnswer'),
        });
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Checks if the answer has changed
     * @param {String} type
     * @param {Object} oldAnswer
     * @param {Object} newAnswer
     * @returns {boolean}
     * @author Thiago Fazzi
     */
    checkIfAnswerChanged(type, oldAnswer, newAnswer) {
      const {
        textAnswer,
        scaleAnswer,
        optionAnswer,
        scoreAnswer,
        explanation,
        contactManagerName,
        contactManagerEmail,
      } = oldAnswer;

      switch (type) {
        case 'OPEN':
          return newAnswer.textAnswer
            ? newAnswer.textAnswer !== textAnswer || newAnswer.explanation !== explanation
            : textAnswer !== null || explanation !== null;
        case 'SCALE':
          return newAnswer.scaleAnswer
            ? newAnswer.scaleAnswer !== scaleAnswer || newAnswer.explanation !== explanation
            : scaleAnswer !== null || explanation !== null;
        case 'RADIO':
          return newAnswer.optionAnswer
            ? newAnswer.optionAnswer !== optionAnswer || newAnswer.explanation !== explanation
            : optionAnswer !== null || explanation !== null;
        case 'ISQ':
        case 'ISI':
        case 'RATING':
          return newAnswer.scoreAnswer
            ? newAnswer.scoreAnswer !== scoreAnswer
            : scoreAnswer !== null;
        case 'CONTACTMANAGER':
          return newAnswer.contactManagerName && newAnswer.contactManagerEmail
            ? newAnswer.contactManagerName !== contactManagerName ||
                newAnswer.contactManagerEmail !== contactManagerEmail
            : contactManagerName !== null || contactManagerEmail !== null;
        case 'HTML':
        case 'VIDEO':
          return !oldAnswer.completed;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';
@import '@styles/partials/_transitions.scss';

.z-form-wrapper {
  height: 100%;
}

.z-button-wrapper {
  border-top: 0.5px solid $shadow-grey;
}
</style>
