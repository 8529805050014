export default {
  login: 'Inloggen',
  validate: 'Valideer',
  emailAddress: 'E-mailadres',
  loginCodeSent:
    'Er is een inlogcode naar je e-mail of telefoonnummer\nverzonden. Deze is 3 minuten geldig.',
  codeNotWorking: 'Lukt het niet?',
  otherEmail: 'Ander e-mailadres?',
  needHelp: 'Hulp nodig?',
  password: 'Wachtwoord',
  help: 'Help',
  emailHelpDescription:
    'Kom je er niet helemaal uit? Neem contact met ons op via %{supportEmail} of \n%{supportPhone}.',
  passwordHelpDescription:
    'Kom je er niet helemaal uit? Kies één van de opties hieronder of neem contact met ons op. Wij staan voor je klaar op %{supportEmail} of %{supportPhone}.',
  invalidEmailTitle: 'Onbekend e-mailadres',
  invalidEmailDescription:
    'Je e-mailadres is niet geregistreerd in ons systeem. Controleer je gegevens en probeer opnieuw.',
  loginCode: 'Inlogcode',
  loginCodeDescription:
    'Controleer of je de inlogcode hebt ontvangen op je e-mail of telefoonnummer.',
  loginCodeResent: 'De inlogcode is opnieuw verstuurd naar je e-mail of telefoonnummer.',
  resendCode: 'Stuur code opnieuw',
  useCode: 'Gebruik code',
  usePassword: 'Gebruik wachtwoord',
  invalidCode: 'Inlogcode onjuist. Controleer je gegevens en probeer opnieuw.',
  invalidPassword:
    'Het wachtwoord is onjuist. Controleer je gegevens of probeer het opnieuw. Lukt het niet neem dan contact op met ons via %{supportEmail} of %{supportPhone}.',
  tooManyFailedAttempts: 'Probeer het later nog eens',
  tooManyFailedAttemptsDescription:
    'Je hebt te vaak een nieuwe code aangevraagd. Probeer het later opnieuw.',
  emailUs: 'Mail ons',

  // Dashboard
  dashboard: 'Dashboard',
  dashboardHeaderTitle: 'Beste %{firstName}',
  dashboardHeaderSubtitle:
    'Op deze compacte webversie van de Zuidema Influence Hub App, kun je je questionnaire invullen en de resultaten ervan bekijken. Ook kun je de vragen van de intake, evaluatie en effectmeting hier beantwoorden als je dat prettiger vind. Deze webversie werkt het beste op Edge, Firefox, Chrome en Safari. Ga naar de Zuidema Influence Hub App op je telefoon of tablet om toegang te krijgen tot alle andere functionaliteiten, zoals je profiel, pre-reads, take aways en notities.',
  new: 'Nieuw',
  finished: 'Afgerond',
  continue: 'Ga verder',
  available: 'Beschikbaar %{unlockTime}',
  locked: 'Nog niet toegankelijk',
  closed: 'Gesloten',
  after: 'over',
  tomorrow: 'morgen',
  dayAfterTomorrow: 'overmorgen',
  days: 'dagen',
  week: 'week',
  weeks: 'weken',
  month: 'maand',
  months: 'maanden',
  ISQResultsTitle: 'Resultaten Influence Style Questionnaire (ISQ)',
  ISIResultsTitle: 'Resultaten Influence Style Inventory (ISI)',
  ISQResultsSubtitle: 'Toon en analyseer jouw grafiek',
  myEvents: "Mijn programma's",

  // ISQ Result screen
  ISI: 'ISI',
  ISQ: 'ISQ',
  ISQISIResultsDescription:
    'Je kunt de lijntjes van supporters uit en aan zetten door bovenin je scherm op het filter symbool te klikken.',
  ISQISIResultsTableDescription:
    'Bekijk per invloedstijl de scores en highlight wat je opvallend vindt door erop te klikken.',
  OVERREDEN: 'Overreden',
  STELLEN: 'Stellen',
  OVERBRUGGEN: 'Overbruggen',
  INSPIREREN: 'Inspireren',
  WEGBEWEGEN: 'Wegbewegen',
  VOORSTELLENDOEN: 'Voorstellen\ndoen',
  ARGUMENTEREN: 'Argumenteren',
  WAARDEREN: 'Waarderen',
  NORMENSTELLEN: 'Normen\nStellen',
  FACILITEREN: 'Faciliteren/\nSanctioneren',
  BETREKKEN: 'Betrekken',
  LUISTEREN: 'Luisteren',
  ONTHULLEN: 'Onthullen',
  KOPPELEN: 'Koppelen',
  VISIEDELEN: 'Visie delen',
  AFSTANDNEMEN: 'Afstand\nnemen',
  ONTWIJKEN: 'Ontwijken',
  you: 'Jij',
  behaviourType: 'Invloedgedrag',
  question: 'Vraag',
  total: 'Totaal',
  supporters: 'Supporters',

  // Questionnaire results screen
  questionnaireResultsTitle: 'Resultaten 360° feedback Leadership Questionnaire',
  questionnaireResultsSubtitle: 'Controleer je resultaten',
  questionnaireResultsDescription:
    '<p>Analyseer de scores:</p><ul><li>Op welke aspecten heb je hoge scores?</li><li>Op welke aspecten zijn je scores lager?</li><li>Wat is volgens jou je belangrijkste uitdaging?</li></ul><p>Je kunt de individuele scores op de vragen die je opvallend vind highlighten door erop te klikken.</p>',
  ratingQuestions: 'Beoordelingsvragen',
  supporter: 'Supporter %{order}',

  // Header
  logout: 'Uitloggen',

  // Footer
  questions: 'Heb je een vraag?',
  call: 'Bel',
  mail: 'e-mail',

  // Zuidema contact information
  zuidemaUrlNL: 'www.zuidema.nl',
  zuidemaPhoneNumber: '+31 (0) 33 434 58 00',
  zuidemaEmail: 'customerservice@zuidema.nl',

  // General
  tryAgain: 'Probeer het opnieuw',
  error: 'Foutmelding',
  somethingWentWrong:
    'Er is een probleem opgetreden. Probeer het opnieuw. Lukt het niet neem dan contact op met ons via %{supportEmail} of %{supportPhone}.',
  noAccess: 'Je hebt geen toegang tot deze app',

  // Form Types
  INTAKE: 'Intake',
  EVALUATION: 'Evaluatie',
  EFFECT: 'Effect Meting',

  // User roles
  Trainer: 'trainer',
  Actor: 'actor',

  // Forms
  start: 'Start {formType}',
  questionProgress: 'Vraag {step} van de {maxSteps}',
  textareaPlaceholder: 'Typ hier je antwoord',
  explain: 'Licht je antwoord toe',
  answerSummary: 'Antwoorden Overzicht',
  ISQISISummaryTitle: 'Je hebt de %{formType} afgerond',
  ISQISISummaryDescription:
    '<p>Je antwoorden zijn succesvol opgeslagen.</p><p>De resultaten worden in een grafiek getoond. Daarin zie je welk invloedgedrag je volgens je Supporters en jezelf vaak of minder vaak gebruikt.</p>',
  viewGraph: 'Bekijk je grafiek',
  notAnswered: 'Niet beantwoord',
  back: 'Terug',
  previous: 'Vorige',
  next: 'Volgende',
  finish: 'Afronden',
  almostNever: 'Nooit of zelden',
  veryOften: 'Zeer vaak',
  invalidEmail: 'Voer een geldig e-mailadres in',
  errorSavingAnswer: 'Er ging iets mis tijdens het opslaan van je antwoord.',
  name: 'Naam',
  contactManagerNamePlaceholder: 'bijvoorbeeld Lisa de Jong',
  email: 'E-mail',
  emailPlaceholder: 'bijvoorbeeld example@info.com',
  tooltipRatingQuestions:
    '1. Nooit of zelden<br>2. Soms<br>3. Gemiddeld vaak<br>4. Regelmatig<br>5. Zeer vaak tot altijd',

  // Gendered Pronouns
  objectivePronounM: 'hem',
  objectivePronounF: 'haar',
  subjectivePronounM: 'hij',
  subjectivePronounF: 'zij',
  possessivePronounM: 'zijn',
  possessivePronounF: 'haar',

  skip: 'Overslaan',
};
