import { Auth, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import * as mutations from '@/graphql/mutations';
import store from '@/store/store';
import i18n from '@/assets/languages/i18n';
import {
  eventDateOptions,
  BASE_DATE_FORMAT,
  questionTemplates,
  cardStatusLabels,
  questionTypes,
} from '@/utils/enums';

const { FIRST_DAY } = eventDateOptions;
const [NEW, STARTED, FINISHED, LOCKED, CLOSED] = Object.keys(cardStatusLabels);
const {
  SCALE: { id: SCALE },
} = questionTypes;

const {
  PARTICIPANT_NAME,
  PROGRAM_NAME,
  SUBJECTIVE_PRONOUN,
  OBJECTIVE_PRONOUN,
  POSSESSIVE_PRONOUN,
} = questionTemplates;

// Rest API Endpoints
export const restAPIEndpoints = {
  pushScheduler: '/pushScheduler',
};

/**
 * @description Sends an API post request to API Gateway
 * @param {string} endpoint
 * @param {Object} body
 * @author Kristine de Vries
 */
export const apiPostRequest = async (endpoint, body) => {
  const data = await API.post('restAPI', endpoint, {
    body,
  });
  return data;
};

/**
 * @description Returns the necessary graphql operation
 * @author Kristine de Vries
 */
export const graphql = async (operation, inputValue) =>
  inputValue
    ? API.graphql(graphqlOperation(operation, { ...inputValue }))
    : API.graphql(graphqlOperation(operation));

/**
 * @description Remove properties from object when their value is an empty string
 * (DynamoDB does not allow empty string saving)
 * @author Kristine de Vries
 */
export const removeEmptyProperties = object =>
  Object.fromEntries(
    Object.entries(object).filter(([key]) => object[key] !== '' && object[key] !== null),
  );

/**
 * @description Set empty string properties to null
 * @author Kristine de Vries
 */
export const nullifyEmptyProperties = object =>
  Object.keys(object).reduce((acc, key) => {
    acc[key] = object[key] === '' ? null : object[key];
    return acc;
  }, {});

/**
 * @description Mutates an item in the database
 * @param {Object} payload Can be anything necessary for the mutation, in
 * this case we use item (the saved data) and mutation (name of the mutation)
 * @author Kristine de Vries
 */
export const mutateItem = async payload => {
  const { item, mutationName } = payload;
  const mutation = mutations[mutationName];

  // Remove empty strings when creating, or set to null when updating
  const itemToBeMutated = mutationName.includes('create')
    ? removeEmptyProperties(item)
    : nullifyEmptyProperties(item);

  const { data: mutatedItem } = await graphql(mutation, { input: itemToBeMutated });

  return mutatedItem[mutationName];
};

/**
 * @description Returns current authenticated cognito user
 * @author Kristine de Vries
 */
export const getCurrentUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

/**
 * @description Transforms an array of objects into an object of objects with keys of each object's id
 * unless a different key parameter has been passed to the function
 * @param {Array} array
 * @author Kristine de Vries
 */
export const arrayToObject = (array = [], key = 'id') =>
  array.reduce((obj, item) => {
    if (item) {
      obj[item[key]] = item; // eslint-disable-line no-param-reassign
      return obj;
    }
    return obj;
  }, {});

/**
 * @description Removes specified keys from an object
 * @param {Object} object
 * @param {Array} keysToBeRemoved
 * @author Kristine de Vries
 */
export const removeKeys = (object, keysToBeRemoved) =>
  Object.keys(object)
    .filter(key => !keysToBeRemoved.includes(key))
    .reduce((acc, curr) => {
      acc[curr] = object[curr];
      return acc;
    }, {});

/**
 * Creates a composite key based on two other keys
 * @param {string} key1
 * @param {string} key2
 * @author Kristine de Vries
 */
export const createCompositeKey = (key1, key2) => `${key1}-${key2}`;

/**
 * @description Formats user properties like finishedForms, personalGoals or starredExercises to an object
 * with composite keys
 * @param {Array} array
 * @param {string} key1
 * @param {string} key2
 * @author Kristine de Vries
 */
export const formatArrayUserFieldToObject = (array, key1, key2) =>
  Object.fromEntries(
    Object.entries(
      arrayToObject(
        array.map(item => ({
          id: createCompositeKey(item[key1], item[key2]),
          ...item,
        })),
      ),
    ).map(([key, value]) => [key, removeKeys(value, 'id')]),
  );

/**
 * @description Gets the number of days till event.
 * Returns 0 for currently on going events.
 * @param {Object} event
 * @return {number}
 * @author Kristine de Vries
 */
export const getDaysToEvent = event => {
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
  // Get utc time in milliseconds from the start of today
  const currentDate = +moment().utc().startOf('day');

  const array = event.dates.map(dateObject => {
    const startDate = +moment.utc(dateObject.startDateTime).startOf('day');
    const endDate = +moment.utc(dateObject.endDateTime).endOf('day');

    if (currentDate >= startDate && currentDate <= endDate) {
      // Event is going on right now
      return 0;
    }
    // Check if event is in the future or past and return the difference in days
    return startDate > currentDate
      ? parseInt((startDate - currentDate) / oneDayInMilliseconds, 10)
      : Math.abs(parseInt((endDate - currentDate) / oneDayInMilliseconds, 10));
  });

  // If event is currently ongoing, return 0
  if (array.includes(0)) {
    return 0;
  }
  // Otherwise return the smallest distance in days to any of event start dates/end dates
  return Math.min(...array);
};

/**
 * @description Sorts an array (e.g. exercises, subexercises, roles) in an descending or ascending order
 * based on a key parameter
 * @param {Array} array
 * @param {String} key
 * @param {bool} ascending
 * @author Kristine de Vries
 */
export const sortArray = (array = [], key = 'order', ascending = true) =>
  array.sort((a, b) => {
    if (a[key] < b[key]) {
      return ascending ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return ascending ? 1 : -1;
    }
    return 0;
  });

/**
 * @description Returns a currently ongoing event, or the closest event to today (either past or future)
 * @param {Object} events
 * @param {number} currentDate
 * @author
 */
export const getCurrentEvent = (events, currentEventId) => {
  // Returns an array of arrays of event objects of form { distanceInDaysToEvent, eventId }
  // Only return events with statusGo true
  const eventArrays = Object.values(events)
    .filter(item => item.statusGo && item.registrationStatusGo)
    .map(event => {
      const distanceInDaysToEvent = getDaysToEvent(event);
      return { distanceInDaysToEvent, eventId: event.id };
    });
  // Flattens the array of event arrays
  const eventArray = [].concat(...eventArrays);

  // If a specific event is being initialized, return that event as the current event
  // Otherwise return next closest event in array, else return empty object to trigger user Sign out
  if (currentEventId) {
    const currentEvent = eventArray.find(item => item.eventId === currentEventId);
    return currentEvent || sortArray(eventArray, 'distanceInDaysToEvent')[0] || {};
  }

  // Otherwise sort the array in an ascending order and return the closest event to today
  return sortArray(eventArray, 'distanceInDaysToEvent')[0] || {};
};

/**
 * @description Formats date in a specified format
 * @param {date} date
 * @param {string} dateFormat
 * @author Kristine de Vries
 */
export const formatDate = (date, dateFormat, utc) =>
  utc ? moment.utc(date).format(dateFormat) : moment(date).format(dateFormat);

/**
 * @description Calculates the unlock date of form/module based on its openDaysToEvent and
 * openDateReference props and event start/end dates
 * @param {number} openDaysToEvent
 * @param {string} openDateReference
 * @param {unix timestamp in milliseconds} firstDay
 * @param {unix timestamp in milliseconds} lastDay
 * @author Kristine de Vries
 */
export const getFormOrModuleUnlockDate = (
  openDaysToEvent,
  openDateReference,
  firstDay,
  lastDay,
) => {
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

  if (openDateReference === FIRST_DAY) {
    return formatDate(firstDay + openDaysToEvent * oneDayInMilliseconds, BASE_DATE_FORMAT);
  }
  return formatDate(lastDay + openDaysToEvent * oneDayInMilliseconds, BASE_DATE_FORMAT);
};

/**
 * @description Checks whether the form/module has a close date set, then calculates that date
 * based on closeDaysToEvent and closeDateReference props and event start/end dates
 * @param {number} closeDaysToEvent
 * @param {string} closeDateReference
 * @param {unix timestamp in milliseconds} firstDay
 * @param {unix timestamp in milliseconds} lastDay
 * @author Kristine de Vries
 */
export const getFormOrModuleCloseDate = (
  closeDaysToEvent,
  closeDateReference,
  firstDay,
  lastDay,
) => {
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
  if (closeDateReference) {
    if (closeDateReference === FIRST_DAY) {
      return formatDate(firstDay + closeDaysToEvent * oneDayInMilliseconds, BASE_DATE_FORMAT);
    }
    return formatDate(lastDay + closeDaysToEvent * oneDayInMilliseconds, BASE_DATE_FORMAT);
  }
  return null;
};

/*
 * @description Returns translations of an item for a given language, otherwise default's to current locale
 * and otherwise default to item's defaultLanguage
 * @param {Object} item
 * @param {string} language
 * @author Kristine de Vries
 */
export const getTranslations = (item, currentLanguage) => {
  const language = currentLanguage || i18n.locale;

  return item?.translations[language]
    ? item?.translations[language]
    : item?.translations[item.defaultLanguage];
};

/**
 * @description Get client language
 * IE - .userLanguage (windows users)
 * Others - .language
 * @returns {String}
 * @author Thiago Fazzi
 */
export const getBrowserLanguage = () => {
  const locale = window.navigator.userLanguage || window.navigator.language;
  return locale ? locale.split('-')[0] : null;
};

/**
 * @description Define the root i18n locale and the html lang property
 * @param {Object} instance
 * @param {String} language 'en'
 * @author Thiago Fazzi
 * @author Kristine de Vries
 */
export const setLanguage = (instance, language) => {
  const vueInstance = instance;
  if (vueInstance.$root.$i18n.locale !== language) {
    vueInstance.$root.$i18n.locale = language;
    const getHTMLTag = document.documentElement;
    getHTMLTag.setAttribute('lang', language);
  }
};

/**
 * @description Resets app state
 * @author Kristine de Vres
 */
export const resetApp = async () => {
  await Auth.signOut();
  sessionStorage.clear();
  await store.dispatch('resetState');
};

/**
 * @description Returns user's full name
 * @param {string} firstName
 * @param {string} prefix
 * @param {string} lastName
 * @returns {string}
 * @author Thiago Fazzi
 */
export const formatName = (firstName, prefix, lastName) =>
  prefix ? `${firstName} ${prefix} ${lastName}` : `${firstName} ${lastName}`;

/**
 * @description Email validation
 * @param {String} email
 * @returns {Boolean}
 * @author Thiago Fazzi
 */
export const validateEmail = email => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * @description Replaces question templates in question title with the correct string.
 * @param {object} question
 * @param {object participant
 * @param {object} event
 * @author Milo Silva
 * @author Thiago Fazzi
 */
export const replaceTemplates = (question, participant, event) => {
  const { firstName, gender } = participant;
  const { title: programName } = event;

  // Trainers do not have gender, so set to `M` by default
  const genderForStrings = gender || 'M';

  const replacementString = {
    [PARTICIPANT_NAME]: firstName,
    [PROGRAM_NAME]: programName,
    [SUBJECTIVE_PRONOUN]: i18n.t(`subjectivePronoun${genderForStrings}`),
    [OBJECTIVE_PRONOUN]: i18n.t(`objectivePronoun${genderForStrings}`),
    [POSSESSIVE_PRONOUN]: i18n.t(`possessivePronoun${genderForStrings}`),
  };

  let { title, questionSupporter } = question;

  Object.values(questionTemplates).forEach(template => {
    const regex = new RegExp(template, 'g');

    title = title?.replace(regex, replacementString[template]) ?? '';
    questionSupporter = questionSupporter?.replace(regex, replacementString[template]) ?? '';
  });

  return { ...question, title, questionSupporter };
};

/**
 * @description Returns facilitator type questions (questions with a {trainerName} template in the question,
 * which is replaced by the actual trainer's name). In case of multiple trainers generates additional questions in the question flow.
 * In case of multiple facilitator questions containing the same name template in a row
 * (for example, open question, scale question, where both container {trainerName}), questions are returned in a mixed order:
 * e.g. [open question trainer 1, scale question trainer 1, open question trainer 2, scale question trainer 2]
 * Works the same way if training has multiple actors and questions have {actorName} template
 * @param {questionItem: Object, nextQuestionItem: Object, users: Array, template: string}
 * @author Kristine de Vries
 */
export const getFacilitatorQuestions = ({
  questionItem,
  nextQuestionItem,
  users = [],
  template,
}) => {
  const { title } = questionItem;

  const facilitatorTemplate = new RegExp(template, 'g');

  // If both current and next question contains a facilitator template, mix questions:
  if (users.length > 1 && nextQuestionItem) {
    return users
      .map(user => {
        const { id, firstName, prefix, lastName, anonymised, role } = user;
        const facilitatorName = anonymised ? i18n.t(role) : formatName(firstName, prefix, lastName);

        return sortArray(
          [
            {
              ...questionItem,
              facilitatorId: id,
              title: title.replace(facilitatorTemplate, facilitatorName),
            },
            {
              ...nextQuestionItem,
              facilitatorId: id,
              title: nextQuestionItem.title.replace(facilitatorTemplate, facilitatorName),
            },
          ],
          'order',
        );
      })
      .flat();
  }

  // There are multiple trainers, but the next question does not container the facilitator template
  if (users.length > 1) {
    return users.map(user => {
      const { id, firstName, prefix, lastName, anonymised, role } = user;
      const facilitatorName = anonymised ? i18n.t(role) : formatName(firstName, prefix, lastName);
      return {
        ...questionItem,
        facilitatorId: id,
        title: title.replace(facilitatorTemplate, facilitatorName),
      };
    });
  }

  if (users[0] && nextQuestionItem) {
    const { id, firstName, prefix, lastName, anonymised, role } = users[0];
    const facilitatorName = anonymised ? i18n.t(role) : formatName(firstName, prefix, lastName);
    return [
      {
        ...questionItem,
        facilitatorId: id,
        title: title.replace(facilitatorTemplate, facilitatorName),
      },
      {
        ...nextQuestionItem,
        facilitatorId: id,
        title: nextQuestionItem.title.replace(facilitatorTemplate, facilitatorName),
      },
    ];
  }
  if (users[0]) {
    const { id, firstName, prefix, lastName } = users[0];
    const facilitatorName = `${firstName} ${prefix ? `${prefix} ${lastName}` : lastName}`;
    return {
      ...questionItem,
      facilitatorId: id,
      title: title.replace(facilitatorTemplate, facilitatorName),
    };
  }
  // If the question contains facilitator synthax, but no such facilitators are found return null.
  // These questions will be filtered out from the form questions.
  return null;
};

/**
 * @description Returns the answer to the question if there is one
 * for facilitator questions, the answer with the correct facilitatorId is returned
 * @author Kristine de Vries
 */
export const getQuestionAnswer = (question = {}) => {
  const { id, facilitatorId, answers = [], type, max } = question;
  const questionAnswer = facilitatorId
    ? answers.find(answer => answer.facilitatorId === facilitatorId)
    : answers[0];

  return (
    questionAnswer || {
      questionId: id,
      facilitatorId,
      textAnswer: null,
      scaleAnswer: type === SCALE ? max : null,
      scoreAnswer: null,
      optionAnswer: null,
      explanation: null,
      contactManagerName: null,
      contactManagerEmail: null,
      completed: null,
    }
  );
};

/**
 * @description Shows bootstrap Toast component notification
 * @author Kristine de Vries
 */
export const showToast = (vueInstance, data = {}) => {
  const {
    title = vueInstance.$t('error'),
    body = vueInstance.$t('somethingWentWrong', {
      supportEmail: vueInstance.$t('zuidemaEmail'),
      supportPhone: vueInstance.$t('zuidemaPhoneNumber'),
    }),
    variant = 'danger',
    delay = 3000,
  } = data;
  vueInstance.$bvToast.toast(body, {
    title,
    variant,
    solid: true,
    autoHideDelay: delay,
  });
};

/**
 * @description Calculate the difference in days between the beginning of today and the date that the form becomes available.
 * Format the text accordingly to the difference.
 * @param date
 * @returns {string}
 */
export const getFormAvailableDate = dateString => {
  const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
  const today = +moment().startOf('day');
  const date = +moment(dateString);
  const differenceInDays = parseInt((date - today) / oneDayInMilliseconds, 10);

  if (differenceInDays <= 1) {
    return i18n.t('tomorrow');
  }
  if (differenceInDays === 2) {
    return i18n.t('dayAfterTomorrow');
  }
  if (differenceInDays < 7) {
    return `${i18n.t('after')} ${differenceInDays} ${i18n.t('days')}`;
  }
  if (differenceInDays < 28) {
    const weeks = Math.round(differenceInDays / 7);
    return weeks > 1
      ? `${i18n.t('after')} ${weeks} ${i18n.t('weeks')}`
      : `${i18n.t('after')} ${weeks} ${i18n.t('week')}`;
  }
  const months = Math.round(differenceInDays / 28);
  return months > 1
    ? `${i18n.t('after')} ${months} ${i18n.t('months')}`
    : `${i18n.t('after')} ${months} ${i18n.t('month')}`;
};

/**
 * @description Returns correct status label for a form
 * @param {Object} form
 * @author Kristine de Vries
 */
export const getFormStatus = ({ form, finishedFormIds, answers }) => {
  const { unlockDate, closeDate, questions } = form;

  const currentDate = formatDate({}, BASE_DATE_FORMAT);

  if (finishedFormIds.includes(form.id)) {
    return FINISHED;
  }
  if (unlockDate > currentDate) {
    return LOCKED;
  }
  if (closeDate && closeDate <= currentDate) {
    return CLOSED;
  }
  if (
    questions.find(questionId =>
      Object.values(answers).some(answer => answer.questionId === questionId),
    )
  ) {
    return STARTED;
  }
  return NEW;
};
