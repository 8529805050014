<template>
  <b-table-simple class="z-table">
    <!-- Table header -->
    <b-thead v-if="headerRow && !!headerRow.length" class="z-table-header">
      <!-- Question header -->
      <b-th class="p-0">
        <p class="px-4 py-1 mb-0">{{ headerRow[0].value }}</p>
      </b-th>
      <b-th
        class="p-0 z-table-questionnaire-right-col"
        :style="{ '--right-col-width': getRightColumnWidth(type) }"
      >
        <!-- Answers header -->
        <TableRightColumn
          :data="headerRow.slice(1, headerRow.length)"
          class="z-table-right-col"
          contentKey="value"
        />
      </b-th>
    </b-thead>
    <!-- Table body -->
    <b-tbody>
      <b-tr
        v-for="(dataItem, dataItemIndex) in data"
        :key="dataItemIndex"
        class="z-table-questionnaire-row"
      >
        <!-- Left column -->
        <b-td
          :style="{ '--left-col-width': getLeftColumnWidth(type) }"
          class="px-4 z-table-questionnaire-left-col"
          >{{ dataItem[0].value }}</b-td
        >
        <!-- Right column -->
        <b-td
          :style="{ '--right-col-width': getRightColumnWidth(type) }"
          class="p-0 z-table-questionnaire-right-col"
        >
          <!-- Score answers -->
          <TableRightColumn
            v-if="type === 'RATING'"
            :data="dataItem.slice(1, dataItem.length)"
            :isAnswerColumn="dataItemIndex !== data.length - 1"
            class="z-table-right-col"
            contentKey="value"
          />
          <!-- Text answer -->
          <div v-else class="d-flex align-items-center px-4 z-table-right-col">
            <p class="mb-0">{{ dataItem[1].value }}</p>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import TableRightColumn from '@/components/TableRightColumn';

/**
 * @file
 * @description 360 Questionnaire table content component,
 * when only one 360 questionnaire measurement is available
 * @author Kristine de Vries
 */
export default {
  name: 'TableContentQuestionnaire',
  components: {
    TableRightColumn,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
      default: '',
    },
    headerRow: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * @description Get the width of the left table column for OPEN type questions
     * @author Kristine de Vries
     */
    getLeftColumnWidth(type) {
      if (type === 'OPEN') {
        return '8.75rem';
      }
      return null;
    },
    /**
     * @description Get the width of the right table column depending on section type and number of supporters
     * @author Kristine de Vries
     */
    getRightColumnWidth(type) {
      if (type === 'RATING') {
        const { supporterCount } = this.data;
        const width = supporterCount * 2.25;
        return `${width}rem`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_tables.scss';

.z-table-questionnaire-row {
  &:nth-child(even) {
    background-color: $secondary;
  }
}

.z-table-questionnaire-right-col {
  width: var(--right-col-width);
}

.z-table-questionnaire-left-col {
  width: var(--left-col-width);
}
</style>
