<template>
  <b-form>
    <b-form-group class="mb-4" :label="$t('name')" label-class="text-light">
      <b-form-input
        trim
        :disabled="!editable"
        v-model="contactManagerName"
        :placeholder="$t('contactManagerNamePlaceholder')"
        size="lg"
        class="z-form-control"
        @input="setInputValue"
      />
    </b-form-group>

    <ValidationProvider ref="validator" rules="email" v-slot="{ errors }" mode="lazy">
      <b-form-group class="mb-4" :label="$t('emailAddress')" label-class="text-light">
        <b-form-input
          ref="email"
          trim
          :disabled="!editable"
          v-model="contactManagerEmail"
          :placeholder="$t('emailPlaceholder')"
          :state="errors[0] ? false : null"
          size="lg"
          type="email"
          class="z-form-control"
          @input="setInputValue"
        />
        <b-form-invalid-feedback>{{ $t('invalidEmail') }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </b-form>
</template>

<script>
/**
 * @file
 * @description Component for displaying ContactManager questions
 * @author Thiago Fazzi
 */
import { ValidationProvider } from 'vee-validate';
import { questionProps } from '@/mixins/functionalMixins';

export default {
  name: 'ContactManager',
  mixins: [questionProps],
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      contactManagerName: null,
      contactManagerEmail: null,
    };
  },
  created() {
    const { contactManagerName, contactManagerEmail } = this.value;
    this.contactManagerName = contactManagerName;
    this.contactManagerEmail = contactManagerEmail;
  },
  methods: {
    /**
     * @description Emit an input event with both contactManagerName and contactManagerEmail
     * @author Kristine de Vries
     */
    setInputValue() {
      this.$emit('input', {
        contactManagerName: this.contactManagerName,
        contactManagerEmail: this.contactManagerEmail,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/base.scss';

.z-form-control {
  border: none;

  &.is-invalid {
    box-shadow: none;
  }
}
</style>
