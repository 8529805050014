<template>
  <form>
    <div class="d-flex flex-wrap mb-8">
      <div v-for="option in options" :key="option" class="mr-3">
        <b-button
          block
          :ref="option"
          :disabled="!editable"
          :variant="value === option ? 'primary' : 'secondary'"
          name="score"
          class="d-flex align-items-center justify-content-center py-3 mb-3"
          tabindex="-1"
          @click="submit(option)"
          @keydown.enter.prevent
        >
          <!-- Radio button circle -->
          <Icon
            :src="value === option ? 'radioActive' : 'radioInactive'"
            class="mr-2"
            width="1rem"
            height="1rem"
          />

          <!-- Label -->
          <span>{{ option }}</span>
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
/**
 * @file
 * @description Component for displaying Radio questions
 * @author Thiago Fazzi
 */
import Icon from '@/components/Icon';
import { getTranslations } from '@/utils/functions';
import { questionProps, inputValue } from '@/mixins/functionalMixins';

export default {
  name: 'Radio',
  components: {
    Icon,
  },
  mixins: [questionProps, inputValue],
  computed: {
    /**
     * @description Get translated options
     * @returns {string} option
     * @author Thiago Fazzi
     */
    options() {
      const { options } = getTranslations(this.question);
      return options.map(({ title }) => title);
    },
  },
  methods: {
    /**
     * @description Emit selected option to the parent component
     * @param {string} option
     * @author Thiago Fazzi
     */
    submit(value) {
      this.$emit('input', value);
    },
  },
};
</script>
