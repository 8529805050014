<template>
  <div class="d-flex flex-column w-100">
    <BaseHeader
      :title="isISQ ? $t('ISQ') : $t('ISI')"
      :goBackLabel="$t('dashboard')"
      class="sticky-top"
    >
      <template #actionButtons>
        <div class="d-flex align-self-end">
          <!-- Filters -->
          <GraphFilters
            :data="graphData"
            :activeFilters="graphFilters"
            @graphFilterChange="handleGraphFilterChange"
          />
        </div>
      </template>
    </BaseHeader>

    <b-container class="align-self-center col-10 col-lg-8 p-8 pt-9 mx-0">
      <b-row>
        <!-- Title -->
        <div>
          <h3 class="mb-0 z-title">
            {{ isISQ ? $t('ISQResultsTitle') : $t('ISIResultsTitle') }}
          </h3>

          <!-- Description -->
          <p class="mt-6 mb-9">
            {{ $t('ISQISIResultsDescription') }}
          </p>
        </div>
      </b-row>

      <!-- Graph -->
      <b-row>
        <Graph :data="graphData" :isISQ="isISQ" :activeFilters="graphFilters" />
      </b-row>

      <!-- Table description -->
      <b-row>
        <p class="my-8">
          {{ $t('ISQISIResultsTableDescription') }}
        </p>
      </b-row>

      <!-- Table List -->
      <b-row>
        <TableList :data="tableData" :isISQ="isISQ" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseHeader from '@/components/layout/BaseHeader';
import { highlightAnswer } from '@/mixins/functionalMixins';
import { sortArray, getTranslations } from '@/utils/functions';
import {
  formTypes,
  behaviourTypes,
  influenceStylesForGraph,
  ISQISIGraphDataGroups,
} from '@/utils/enums';

import GraphFilters from './components/GraphFilters';
import Graph from './components/Graph';
import TableList from './components/TableList';

const { ISQ, ISI } = formTypes;

/**
 * @file
 * @description ISQ/ISI results screen
 * @author Kristine de Vries
 */
export default {
  name: 'ISQISIResults',
  components: {
    BaseHeader,
    Graph,
    TableList,
    GraphFilters,
  },
  mixins: [highlightAnswer],
  data() {
    return {
      isISQ: this.$route.params.type === ISQ,
      forms: [],
      questions: {},
      graphFilters: ISQISIGraphDataGroups.map(({ type }) => type),
    };
  },
  computed: {
    ...mapGetters(['getStateItems']),
    /**
     * @description Get answers from global state
     * @author Kristine de Vries
     */
    answers() {
      return Object.values(this.getStateItems('answers'));
    },
    /**
     * @description Get supporters from global state, filter for non-questionnaire supporters
     * @author Kristine de Vries
     */
    supporters() {
      return Object.values(this.getStateItems('supporters')).filter(
        ({ isQuestionnaire }) => !isQuestionnaire,
      );
    },
    /**
     * @description Get table data
     * @author Kristine de Vries
     */
    tableData() {
      return this.getISQISITableData();
    },
    /**
     * @description Get graph data
     * @author Kristine de Vries
     */
    graphData() {
      return this.getISQISIGraphData();
    },
  },
  created() {
    // Get data from state
    this.forms = Object.values(this.getStateItems('forms'));
    this.questions = this.getStateItems('questions');
  },
  methods: {
    /**
     * @description Formats an array of answers into a data object with answers grouped per
     * behaviour type and containing additional data, which is needed for rendering ISQ or ISI result graph
     * @author Kristine de Vries
     */
    formatAnswersForISQISIGraph(answers, formType) {
      // For ISQ forms group data per behaviour type
      if (formType === ISQ) {
        return answers
          .filter(({ questionId }) => this.questions[questionId]?.behaviourType)
          .map(item => ({ ...item, behaviourType: this.questions[item.questionId].behaviourType }))
          .reduce((acc, curr) => {
            const behaviourType = behaviourTypes[curr.behaviourType];
            const alreadyExistingType = acc[curr.behaviourType];
            return {
              ...acc,
              [curr.behaviourType]: {
                answers: alreadyExistingType ? [...alreadyExistingType.answers, curr] : [curr],
                behaviourType: behaviourType.ISQGraphValue,
                influenceStyle: behaviourType.influenceStyle,
                totalScore:
                  alreadyExistingType && alreadyExistingType.totalScore
                    ? alreadyExistingType.totalScore + curr.scoreAnswer
                    : curr.scoreAnswer,
              },
            };
          }, {});
      }

      // For ISI forms group data per influence style
      return answers
        .filter(({ questionId }) => this.questions[questionId]?.influenceStyle)
        .map(item => ({ ...item, influenceStyle: this.questions[item.questionId].influenceStyle }))
        .reduce((acc, curr) => {
          const { influenceStyle, scoreAnswer } = curr;
          const influenceStyleSection = influenceStylesForGraph[influenceStyle];
          const alreadyExistingStyle = acc[influenceStyle];

          return {
            ...acc,
            [influenceStyle]: {
              answers: alreadyExistingStyle ? [...alreadyExistingStyle.answers, curr] : [curr],
              influenceStyle: influenceStyleSection.ISQGraphValue,
              totalScore:
                alreadyExistingStyle && alreadyExistingStyle.totalScore
                  ? alreadyExistingStyle.totalScore + scoreAnswer
                  : scoreAnswer,
            },
          };
        }, {});
    },
    /**
     * @description Returns answer data formatted for ISQ results graph
     * @author Kristine de Vries
     */
    getISQISIGraphData() {
      const formType = this.forms.find(form => form.type === ISQ) ? ISQ : ISI;
      const participantData = {
        data: this.formatAnswersForISQISIGraph(this.answers, formType),
      };

      const supporterData = sortArray(this.supporters, 'createdAt')
        .filter(({ finishedForms }) => Object.values(finishedForms).length)
        .reduce((acc, curr, index) => {
          const { firstName, prefix, lastName, answers: supporterAnswers } = curr;
          return {
            ...acc,
            [`supporter${index}`]: {
              data: this.formatAnswersForISQISIGraph(Object.values(supporterAnswers), formType),
              firstName,
              prefix,
              lastName,
            },
          };
        }, {});

      return {
        participantData,
        ...supporterData,
      };
    },
    /**
     * @description Returns answer data formatted for ISQ/ISI results table
     * @author Kristine de Vries
     */
    getISQISITableData() {
      const ISQISIForm = this.forms.find(item => item.type === ISQ || item.type === ISI);

      // Fallback in case ISQ/ISI Form is not found
      if (!ISQISIForm) {
        return {};
      }

      return ISQISIForm.questions
        .map(questionId => this.questions[questionId])
        .reduce((acc, curr) => {
          const { id, order, behaviourType, influenceStyle } = curr;
          const { title } = getTranslations(curr);

          // Get participant's answer to question
          const {
            scoreAnswer: participantAnswer,
            id: participantAnswerId,
            highlighted: participantAnswerHiglihghted,
          } = this.answers.find(answer => answer.questionId === curr.id) || {};

          // Get supporter answers to the question
          const supporterAnswers = sortArray(this.supporters, 'createdAt')
            .filter(item => Object.values(item.finishedForms).length)
            .map((supporter, index) => {
              const { firstName, lastName, id: supporterId } = supporter;
              const { scoreAnswer, highlighted, questionId, formId } =
                Object.values(supporter.answers).find(
                  answer => answer.questionId === id && answer.formId === ISQISIForm.id,
                ) || {};
              return {
                supporterId,
                order: index + 1,
                scoreAnswer,
                highlighted,
                questionId,
                formId,
                firstName,
                lastName,
              };
            });

          // Create a data object combining all necesary data for the table for each behaviour type
          const newItem = {
            id,
            order,
            title,
            behaviourType,
            answers: [
              {
                order: 0,
                id: participantAnswerId,
                scoreAnswer: participantAnswer,
                highlighted: participantAnswerHiglihghted,
              },
              ...supporterAnswers,
            ],
          };

          const behaviourTypeData = acc[behaviourType];
          const influenceStyleData = acc[influenceStyle];

          return this.isISQ
            ? {
                ...acc,
                [behaviourType]: behaviourTypeData ? [...behaviourTypeData, newItem] : [newItem],
              }
            : {
                ...acc,
                [influenceStyle]: influenceStyleData ? [...influenceStyleData, newItem] : [newItem],
              };
        }, {});
    },
    /**
     * @description Adds/removes filters to graphFilters array in state when a filter switch is toggled on/off
     * @author kristine de Vries
     */
    handleGraphFilterChange(key) {
      this.graphFilters = this.graphFilters.includes(key)
        ? this.graphFilters.filter(item => item !== key)
        : [...this.graphFilters, key];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';
</style>
