<template>
  <div>
    <!-- Description -->
    <p class="text-light text-center my-4 z-code-description">{{ $t('loginCodeSent') }}</p>

    <!-- Code input -->
    <CodeInput ref="codeInput" type="number" :fields="6" @change="onChange" @submit="submit" />

    <!-- Submit button -->
    <PrimaryButton
      :label="$t('validate')"
      :loading="loading"
      :disabled="!isCodeValid"
      class="my-4"
      @click="submit"
    />

    <!-- Secondary buttons -->
    <div class="d-flex flex-row align-items-center justify-content-between">
      <SecondaryButton :label="$t('otherEmail')" @click="navigate('EMAIL')" />
      <SecondaryButton :label="$t('codeNotWorking')" @click="openModal" />
    </div>
  </div>
</template>

<script>
/**
 * @file
 * @description Code step component of the login flow
 * @author Kristine de Vries
 */
import PrimaryButton from '@/components/PrimaryButton';
import SecondaryButton from '@/components/SecondaryButton';
import { modalTypes } from '@/utils/enums';

import CodeInput from './CodeInput';

const { loginCodeHelp } = modalTypes;

export default {
  name: 'Code',
  components: {
    CodeInput,
    PrimaryButton,
    SecondaryButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    codeParam: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  mounted() {
    if (this.codeParam) {
      this.code = this.codeParam;
      this.$refs.codeInput.values = this.codeParam;
    }
  },
  computed: {
    /**
     * @description Check if code input length is valid
     * @author Kristine de Vries
     */
    isCodeValid() {
      return this.code.length === 6;
    },
  },
  methods: {
    /**
     * @description Updates code value in state
     * @author Kristine de Vries
     */
    onChange(code) {
      this.code = code;
    },
    /**
     * @description Submit user's code input
     * @author Kristine de Vries
     */
    submit() {
      this.$emit('submit', this.code);
    },
    /**
     * @description Emit navigation event with given path
     * @author Kristine de Vries
     */
    navigate(path) {
      this.$emit('navigate', path);
    },
    /**
     * @description Emit open modal event
     * @author Kristine de Vries
     */
    openModal() {
      this.$emit('openModal', loginCodeHelp);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-code-description {
  font-size: $font-size-sm;
  font-family: $font-family-display;
  font-weight: $font-weight-regular;
}
</style>
