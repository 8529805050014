var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},_vm._l((_vm.sections),function(section,index){return _c('div',{key:section},[_c('CollapsibleButton',{attrs:{"id":section,"visible":_vm.activeSections.includes(section),"label":_vm.$t(section)},on:{"click":function($event){return _vm.toggleSection(section)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-table-simple',{staticClass:"z-table"},[_c('b-thead',{staticClass:"z-table-header"},[(_vm.isISQ)?_c('b-th',{staticClass:"px-4 py-2 align-middle z-table-left-col"},[_vm._v(_vm._s(_vm.$t('behaviourType')))]):_vm._e(),_c('b-th',{staticClass:"p-0"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('TableMiddleColumn',{staticClass:"py-2",attrs:{"content":_vm.$t('question')}}),_c('TableRightColumn',{attrs:{"data":_vm.answerHeaderData,"contentKey":"label"}})],1)])],1),_c('b-tbody',{staticClass:"z-table-body"},_vm._l((_vm.tableItems[index]),function(dataSection,sectionIndex){return _c('b-tr',{key:_vm.isISQ ? dataSection[0].behaviourType : dataSection.id},[(_vm.isISQ)?_c('b-td',{staticClass:"px-4 text-center z-table-left-col"},[_vm._v(_vm._s(_vm.$t(dataSection[0].behaviourType)))]):_vm._e(),_c('b-td',{staticClass:"p-0"},[_vm._l((dataSection),function(question,questionIndex){return _c('div',{key:question.order,class:[
                    'd-flex',
                    'justify-content-between',
                    [_vm.getRowBackgroundColor(_vm.tableItems[index], sectionIndex, questionIndex)],
                  ]},[_c('TableMiddleColumn',{attrs:{"content":`${question.order + 1}. ${question.title}`}}),_c('TableRightColumn',{attrs:{"data":question.answers,"contentKey":"scoreAnswer","isAnswerColumn":""}})],1)}),_c('div',{class:[
                    'd-flex',
                    'justify-content-between',
                    [_vm.getRowBackgroundColor(_vm.tableItems[index], sectionIndex, dataSection.length)],
                  ]},[_c('TableMiddleColumn',{attrs:{"content":_vm.$t('total')}}),_c('TableRightColumn',{staticClass:"z-score",attrs:{"data":_vm.totalScores[index][sectionIndex]}})],1)],2)],1)}),1)],1)]},proxy:true}],null,true)})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }