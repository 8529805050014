<template>
  <div class="px-0 w-100">
    <!-- Graph -->
    <canvas ref="questionnaireGraph" class="z-questionnaire-graph-canvas" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import colors from '@/assets/styles/partials/_export.module.scss';

const {
  primary,
  graphBlue,
  graphNavy,
  graphLightBlue,
  graphYellow,
  graphOrangeRed,
  graphTeal,
  graphPurple,
  shadowGrey,
  paleGrey,
  light,
  black,
} = colors;

// Plugin for the chart to draw the borders in the x axis
const xAxisBorders = {
  id: 'xAxisBorders',
  beforeDraw(chart, args, options) {
    const { numberOfCategories } = options;
    const {
      ctx,
      chartArea: { left, bottom, width },
    } = chart;

    ctx.strokeStyle = shadowGrey;
    ctx.lineWidth = 1;

    // Draw outer container for the X axis
    ctx.strokeRect(left, bottom, width, 36);

    // Draw a border between each tick container
    const distanceX = width / numberOfCategories;
    Array.from(Array(numberOfCategories)).forEach((_, index) => {
      ctx.beginPath();
      ctx.moveTo(left + index * distanceX, bottom);
      ctx.lineTo(left + index * distanceX, bottom + 36);
      ctx.stroke();
    });
  },
};

/**
 * @file
 * @description 360 Questionnaire results Graph component
 * @author Kristine de Vries
 */
export default {
  name: 'Graph',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      chart: {},
    };
  },
  mounted() {
    this.chart = this.renderChart();
  },
  methods: {
    /**
     * @description Renders 360 Questionnaire results graph
     * @author Kristine de Vries
     */
    renderChart() {
      const ctx = this.$refs.questionnaireGraph?.getContext('2d');

      const datasetConfig = {
        backgroundColor: [
          primary,
          graphBlue,
          graphNavy,
          graphLightBlue,
          graphYellow,
          graphOrangeRed,
          graphTeal,
          graphPurple,
        ],
        borderColor: [light],
        borderWidth: 1,
        borderRadius: 3,
      };

      return new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.data.map(({ question }) => question.slice(1)),
          // If we have the 2nd questionnaire measurement data display both
          // measurements, otherwise just the first one
          datasets: this.data.some(item => item.y2)
            ? [
                {
                  data: this.data.map(({ y1 }) => y1),
                  ...datasetConfig,
                },
                {
                  data: this.data.map(({ y2 }) => y2),
                  ...datasetConfig,
                },
              ]
            : [
                {
                  data: this.data.map(({ y1 }) => y1),
                  ...datasetConfig,
                },
              ],
        },
        options: {
          layout: {
            padding: {
              right: 1,
            },
          },
          scales: {
            x: {
              grid: {
                drawTicks: false,
                z: 1,
                color: ({ tick }) => {
                  const { value } = tick || {};
                  return value === 4 || value === 7 ? black : shadowGrey;
                },
              },
              ticks: {
                padding: 8,
                autoSkip: false,
                font: {
                  size: 18,
                  family: 'SFMono',
                },
              },
            },
            y: {
              beginAtZero: true,
              min: 0.5,
              max: 5,
              ticks: {
                padding: 8,
                stepSize: 0.25,
                autoSkip: false,
                font: {
                  size: 14,
                  family: 'SFMono',
                },
                callback: tick => (tick % 0.5 === 0 ? tick : ''),
              },
              grid: {
                drawTicks: false,
                color: ({ tick: { value } }) => (value % 0.5 === 0 ? shadowGrey : paleGrey),
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: tooltipItems => {
                  const { dataIndex } = tooltipItems[0] || {};
                  return this.data[dataIndex]?.title;
                },
              },
            },
            xAxisBorders: {
              numberOfCategories: this.data.length,
            },
          },
        },
        plugins: [xAxisBorders],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .z-questionnaire-graph-canvas {
    max-width: 100%;
    max-height: 18rem;
  }
}
</style>
