export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($input: CreateAnswerInput!) {
    createAnswer(input: $input) {
      id
      tenantId
      userId
      questionId
      eventId
      registrationId
      facilitatorId
      optionAnswer
      textAnswer
      scaleAnswer
      scoreAnswer
      explanation
      contactManagerName
      contactManagerEmail
      completed
    }
  }
`;

export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($input: UpdateAnswerInput!) {
    updateAnswer(input: $input) {
      id
      userId
      questionId
      eventId
      registrationId
      facilitatorId
      optionAnswer
      textAnswer
      scaleAnswer
      scoreAnswer
      explanation
      contactManagerName
      contactManagerEmail
      highlighted
      completed
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      prefix
      lastName
      email
      contactEmail
      phone
      profileImageKey
      gender
      notes
      dateOfBirth
      linkedIn
      company
      occupation
      yearsOfExperience
      city
      privacyDateOfBirth
      privacyYearsOfExperience
      privacyCity
      privacyLanguage
      privacyGender
      preferredLanguage
      finishedOnboarding
      authMethod
      finishedForms {
        eventId
        formId
        open
      }
      starredExercises {
        exerciseId
        eventId
      }
      archived
      archivedDate
      pushData {
        deviceToken
        pushArn
        pushEnabled
      }
      personalGoals {
        eventId
        moduleId
        personalGoal
      }
    }
  }
`;

export const updateSupporter = /* GraphQL */ `
  mutation UpdateSupporter($input: UpdateSupporterInput!) {
    updateSupporter(input: $input) {
      id
      reminderSent
      feedbackRequested
    }
  }
`;
