export default {
  // Login screen
  login: 'Log in',
  validate: 'Validate',
  emailAddress: 'Email address',
  loginCodeSent:
    'A login code has been sent to your email or phone number.\nThis code is valid for 3 minutes.',
  codeNotWorking: 'Not working?',
  otherEmail: 'Different email address?',
  needHelp: 'Need help?',
  password: 'Password',
  help: 'Help',
  emailHelpDescription: "Can't figure it out? Contact us via %{supportEmail} or \n%{supportPhone}.",
  passwordHelpDescription:
    "Can't make it work? Choose one of the options below or contact us. We'll be eager to help at %{supportEmail} or %{supportPhone}.",
  invalidEmailTitle: 'Unknown email address',
  invalidEmailDescription:
    'Your email address is not registered in our system. Please check your details and try again.',
  loginCode: 'Login code',
  loginCodeDescription: 'Check if you have received the login code on your email or phone number.',
  loginCodeResent: 'The login code has been resent to your email or phone number.',
  resendCode: 'Resend code',
  useCode: 'Use code',
  usePassword: 'Use password',
  invalidCode: 'Login code incorrect. Please check your details and try again.',
  invalidPassword:
    "The password is incorrect. Please check your details or try again. If it doesn't work, please contact us at %{supportEmail} or %{supportPhone}.",
  tooManyFailedAttempts: 'Try again later',
  tooManyFailedAttemptsDescription:
    'You have requested a new code too many times. Try again later.',
  emailUs: 'Mail us',

  // Dashboard
  dashboard: 'Dashboard',
  dashboardHeaderTitle: 'Dear %{firstName}',
  dashboardHeaderSubtitle:
    'On this comprehensive web version of the Zuidema Influence Hub App, you can fill out your questionnaire and view the results. Besides you can answer the questions from the intake, evaluation and effect measurement for your convenience. This web version works best on Edge, Firefox, Chrome and Safari. Go to the Zuidema Influence Hub App on your phone or tablet to get access to all other functionalities, like your profile, pre-reads, takeaways and notes.',
  new: 'New',
  finished: 'Finished',
  continue: 'Continue',
  available: 'Available %{unlockTime}',
  locked: 'Not accessible yet',
  closed: 'Closed',
  after: 'over',
  tomorrow: 'tomorrow',
  dayAfterTomorrow: 'day after tomorrow',
  days: 'days',
  week: 'week',
  weeks: 'weeks',
  month: 'month',
  months: 'months',
  ISQResultsTitle: 'Results Influence Style Questionnaire (ISQ)',
  ISIResultsTitle: 'Results Influence Style Inventory (ISI)',
  ISQResultsSubtitle: 'Show and analyze your chart',
  myEvents: 'My programmes',

  // ISQ/ ISI Result screen
  ISI: 'ISI',
  ISQ: 'ISQ',
  ISQISIResultsDescription:
    'You can choose which lines you want to view via the filter symbol at the top of your screen.',
  ISQISIResultsTableDescription:
    'Check your scores per influence style and highlight those you find interesting by clicking on them.',
  OVERREDEN: 'Persuading',
  STELLEN: 'Asserting',
  OVERBRUGGEN: 'Bridging',
  INSPIREREN: 'Attracting',
  WEGBEWEGEN: 'Moving Away',
  VOORSTELLENDOEN: 'Proposing',
  ARGUMENTEREN: 'Reasoning',
  WAARDEREN: 'Evaluating',
  NORMENSTELLEN: 'Stating Expectations',
  FACILITEREN: 'Using Incentives / Pressures',
  BETREKKEN: 'Involving',
  LUISTEREN: 'Active Listening',
  ONTHULLEN: 'Disclosing',
  KOPPELEN: 'Finding Common Ground',
  VISIEDELEN: 'Sharing Vision',
  AFSTANDNEMEN: 'Disengaging',
  ONTWIJKEN: 'Avoiding',
  you: 'You',
  behaviourType: 'Influence Behaviour',
  question: 'Question',
  total: 'Total',
  supporters: 'Supporters',

  // Questionnaire results screen
  questionnaireResultsTitle: 'Results 360° feedback Leadership Questionnaire',
  questionnaireResultsSubtitle: 'Check your results',
  questionnaireResultsDescription:
    '<p>Analyze the scores:</p><ul><li>On what aspects do you have high scores?</li><li>On what aspects are your scores lower?</li><li>What do you consider your main challenge?</li></ul><p>You can highlight the Rating questions you find interesting by clicking on them.</p>',
  ratingQuestions: 'Rating questions',
  supporter: 'Supporter %{order}',

  // Header
  logout: 'Logout',

  // Footer
  questions: 'Any questions?',
  call: 'Call',
  mail: 'e-mail',

  // Zuidema contact information
  zuidemaUrlNL: 'www.zuidema.nl',
  zuidemaPhoneNumber: '+31 (0) 33 434 58 00',
  zuidemaEmail: 'customerservice@zuidema.nl',

  // General
  tryAgain: 'Try again',
  error: 'Error',
  somethingWentWrong:
    "A problem has occured. Please try again. If it doesn't work, please contact us at %{supportEmail} or %{supportPhone}.",
  noAccess: 'You do not have access to this app',

  // Form Types
  INTAKE: 'Intake',
  EVALUATION: 'Evaluation',
  EFFECT: 'Effect Measurement',

  // User roles
  Trainer: 'Trainer',
  Actor: 'Actor',

  // Forms
  start: 'Start {formType}',
  questionProgress: 'Question {step} of the {maxSteps}',
  textareaPlaceholder: 'Type your answer here',
  explain: 'Please explain your answer',
  answerSummary: 'Answer Summary',
  ISQISISummaryTitle: 'You completed the %{formType}',
  ISQISISummaryDescription:
    '<p>Your answers have been saved successfully.</p><p>The results provide a graph, visualizing which influence behaviour you use most, whether according to the supporters you invited or yourself.</p>',
  viewGraph: 'Check your graph',
  notAnswered: 'Not answered',
  back: 'Back',
  previous: 'Previous',
  next: 'Next',
  finish: 'Finish',
  almostNever: 'Never or rarely',
  veryOften: 'Very often',
  invalidEmail: 'Enter a valid email address',
  errorSavingAnswer: 'Something went wrong while saving your answer.',
  name: 'Name',
  contactManagerNamePlaceholder: 'e.g. Lisa de Jong',
  email: 'E-mail',
  emailPlaceholder: 'e.g. example@info.com',
  tooltipRatingQuestions:
    '1. Never or rarely<br>2. Occasionally<br>3. Average frequency<br>4. Quite often<br>5. Very often',

  // Gendered Pronouns
  objectivePronounM: 'him',
  objectivePronounF: 'her',
  subjectivePronounM: 'he',
  subjectivePronounF: 'she',
  possessivePronounM: 'his',
  possessivePronounF: 'her',

  skip: 'Skip',
};
