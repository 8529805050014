<template>
  <div class="mb-7">
    <b-embed type="iframe" aspect="16by9" :src="embedUrl" allowfullscreen> </b-embed>
  </div>
</template>

<script>
/**
 * @file
 * @description Component for displaying  Video questions
 * @author Thiago Fazzi
 */
import { getTranslations } from '@/utils/functions';
import { questionProps } from '@/mixins/functionalMixins';

export default {
  name: 'Video',
  mixins: [questionProps],
  computed: {
    /**
     * @description Current youtube video id
     * @returns {string}
     * @author Thiago Fazzi
     */
    youtubeId() {
      return getTranslations(this.question).youtubeId;
    },
    /**
     * @description Current youtube video embedded url
     * @author Thiago Fazzi
     */
    embedUrl() {
      return `https://www.youtube.com/embed/${this.youtubeId}`;
    },
  },
};
</script>
