<template>
  <b-form-group class="mb-7">
    <!-- Current score -->
    <div
      class="
        d-flex
        justify-content-center
        align-items-center
        bg-secondary
        mb-6
        z-current-score-container
      "
    >
      <p class="mb-0 z-current-score-label">{{ inputValue }}</p>
    </div>
    <!-- Range slider -->
    <b-form-input
      v-model="inputValue"
      :disabled="!editable"
      :min="question.min"
      :max="question.max"
      :step="question.step"
      type="range"
    />

    <!-- Add frequecncy labels -->
    <b-form-row class="mb-7 z-range-input-label">
      <b-col>
        <p class="text-light">
          {{ question.min }}
        </p>
      </b-col>
      <b-col class="text-right">
        <p class="text-light">
          {{ question.max }}
        </p>
      </b-col>
    </b-form-row>
  </b-form-group>
</template>

<script>
/**
 * @file
 * @description Component for displaying Scale questions
 * @author Thiago Fazzi
 * @author Kristine de Vries
 */
import { questionProps } from '@/mixins/functionalMixins';

export default {
  name: 'Scale',
  mixins: [questionProps],
  props: {
    value: {
      type: Number,
    },
  },
  computed: {
    inputValue: {
      get() {
        if (this.value !== null && this.value !== undefined) return this.value;
        const { max } = this.question || {};
        return max || 0;
      },
      set(val) {
        this.$emit('input', +val);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/base.scss';
@import '@styles/partials/_forms.scss';

.z-current-score-container {
  height: 2.75rem;
  width: 3.25rem;
}

.z-current-score-label {
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
}

.custom-range {
  height: 0.75rem;
  // TODO: Fill active track color on Chrome and Safari
  // Fill active track color on firefox
  &::-moz-range-progress {
    background-color: $primary;
    height: 100%;
    border-radius: $custom-range-track-border-radius;
  }
  // Fill active track color on IE
  &::-ms-fill-lower {
    background-color: $primary;
    height: 100%;
    border-radius: $custom-range-track-border-radius;
  }
}
</style>
