<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
import { getBrowserLanguage, setLanguage } from '@/utils/functions';
import i18n from '@/assets/languages/i18n';

export default {
  name: 'App',
  created() {
    const browserLanguage = getBrowserLanguage();
    const language = i18n.availableLocales.includes(browserLanguage) ? browserLanguage : 'en';
    setLanguage(this, language);
  },
};
</script>
