/**
 * @file
 * @description Validators for VeeValidate
 * @author Thiago Fazzi
 */
import { required, email } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
});

extend('email', email);
