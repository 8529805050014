<template>
  <b-dropdown right no-caret variant="transparent" @show="showDropdown" @hide="hideDropdown">
    <template #button-content>
      <IconButton
        class="p-0"
        src="iconFilter"
        width="1.5rem"
        height="1.5rem"
        :class="isFilterActive ? 'z-isqisi-header-btn-active' : ''"
      />
    </template>
    <!-- Header -->
    <p class="px-5 mb-0 z-dropdown-header-label">{{ $t('supporters') }}</p>
    <b-dropdown-divider></b-dropdown-divider>
    <!-- Switch items -->
    <div
      class="d-flex flex-row px-5 my-2 align-items-center z-graph-filter-item"
      v-for="[key, { firstName, prefix, lastName }] in Object.entries(data)"
      :key="key"
    >
      <!-- Switches -->
      <toggle-button
        sync
        :value="activeFilters.includes(key)"
        @change="toggleFilter(key)"
        :color="ISQISIGraphDataGroups.find(item => item.type === key).color"
        :width="36"
        :height="22"
        class="mb-0"
      />
      <!-- Label -->
      <p class="mb-0 ml-2 z-switch-label">
        {{ firstName ? formatName(firstName, prefix, lastName) : $t('you') }}
      </p>
    </div>
  </b-dropdown>
</template>

<script>
import IconButton from '@/components/IconButton';
import { formatName } from '@/utils/functions';
import { ISQISIGraphDataGroups } from '@/utils/enums';

/**
 * @file
 * @description ISQ/ISI graph filters
 * @author Kristine de Vries
 */
export default {
  name: 'GraphFilters',
  components: {
    IconButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    activeFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      ISQISIGraphDataGroups,
      isFilterActive: false,
    };
  },
  methods: {
    /**
     * @description Format participant's/supporter's name
     * @author Kristine de Vries
     */
    formatName,
    /**
     * @description Emit click event
     * @author Kristine de Vries
     */
    toggleFilter(key) {
      this.$emit('graphFilterChange', key);
    },
    /**
     * @description Called when dropdown becomes visible, sets filter active state
     * @author Kristine de Vries
     */
    showDropdown() {
      this.isFilterActive = true;
    },
    /**
     * @description Called when dropdown becomes hidden, sets filter active state
     * @author Kristine de Vries
     */
    hideDropdown() {
      this.isFilterActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-graph-filter-item {
  min-width: 18rem;
}

.z-dropdown-header-label {
  font-family: $font-family-display;
  font-weight: $font-weight-medium;
  font-size: $font-size-lg;
}

.z-switch-label {
  font-family: $font-family-display;
  font-weight: $font-weight-regular;
}

.z-isqisi-header-btn-active {
  color: $primary;

  &:hover,
  &:focus {
    color: $primary-dark;
  }
}
</style>
