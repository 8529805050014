<template>
  <form>
    <b-form-row class="mb-8">
      <!-- Add buttons 1 to 5 -->
      <b-col v-for="item in scoreAnswers" :key="item">
        <b-button
          block
          :variant="value === item ? 'primary' : 'secondary'"
          :disabled="!editable"
          :ref="item"
          class="py-3"
          name="score"
          tabindex="-1"
          @click="submitAnswer(item, $event)"
          @keydown.enter.prevent
        >
          {{ item }}
        </b-button>
      </b-col>
    </b-form-row>

    <!-- Add frequecncy labels -->
    <b-form-row class="mb-7 z-range-input-label">
      <b-col>
        <p class="text-light">{{ $t('almostNever') }}</p>
      </b-col>
      <b-col class="text-right">
        <p class="text-light">{{ $t('veryOften') }}</p>
      </b-col>
    </b-form-row>
  </form>
</template>

<script>
/**
 * @file
 * @description Component used for displaying questions of type 'ISQ/ISI/Rating'
 * @author Thiago Fazzi
 */
import { questionProps } from '@/mixins/functionalMixins';

export default {
  name: 'Score',
  mixins: [questionProps],
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      ref: 'ISQScoreQuestion',
      scoreAnswers: [1, 2, 3, 4, 5],
    };
  },
  methods: {
    /**
     * @description Emits answer to parent component
     * @author Thiago Fazii
     */
    submitAnswer(answer, e) {
      e.preventDefault();
      this.$emit('input', answer);
    },
    /**
     * @description Answer the questions by pressing the keys between 1 and 5
     * @param {Object} e
     * @author Thiago Fazzi
     */
    setOption(e) {
      e.preventDefault();
      const keyRange = [49, 50, 51, 52, 53, 97, 98, 99, 100, 101];
      if (keyRange.includes(e.which)) {
        this.$refs[e.key.toString()][0].focus();
        this.$emit('input', +e.key);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/partials/_forms.scss';
</style>
