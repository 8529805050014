<template>
  <div class="w-100">
    <!-- Collapsible table section -->
    <div v-for="{ title, data, key, type, headerRow } in sections" :key="key">
      <!-- Collapsible -->
      <CollapsibleButton
        :id="key"
        :visible="activeSections.includes(key)"
        :label="title || $t('ratingQuestions')"
        @click="toggleSection(key)"
      >
        <template #content>
          <!-- Results with only QUESTIONNAIRE data -->
          <TableContentQuestionnaire
            v-if="!hasFeedbackData"
            :data="data"
            :type="type"
            :headerRow="headerRow"
          />
          <!-- Results when we have both QUESTIONNAIRE and QUESTIONNAIRE_FEEDBACK data -->
          <TableContentQuestionnaireFeedback v-else :data="data" :type="type" />
        </template>
      </CollapsibleButton>
    </div>
  </div>
</template>

<script>
import CollapsibleButton from '@/components/CollapsibleButton';
import TableContentQuestionnaire from './TableContentQuestionnaire';
import TableContentQuestionnaireFeedback from './TableContentQuestionnaireFeedback';

/**
 * @file
 * @description 360 Questionnaire table list component
 * @author Kristine de Vries
 */
export default {
  name: 'TableList',
  components: {
    CollapsibleButton,
    TableContentQuestionnaire,
    TableContentQuestionnaireFeedback,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      activeSections: [],
    };
  },
  computed: {
    /**
     * @description Get table data sections
     * @author Kristine de Vries
     */
    sections() {
      return this.data.sections;
    },
    /**
     * @description Return true if we have data from QUESTIONNAIRE_FEEDBACK form as well
     * @author Kristine de Vries
     */
    hasFeedbackData() {
      return this.data.hasFeedbackData;
    },
  },
  methods: {
    /**
     * @description Toggles active state of a selected section
     * @author Kristine de Vries
     */
    toggleSection(sectionKey) {
      if (this.activeSections.includes(sectionKey)) {
        this.activeSections = this.activeSections.filter(item => item !== sectionKey);
      } else {
        this.activeSections = [...this.activeSections, sectionKey];
      }
    },
  },
};
</script>
