<template>
  <b-button class="p-0 z-secondary-btn" variant="transparent" @click="click">
    {{ label }}
  </b-button>
</template>

<script>
/**
 * @file
 * @description Secondary button component
 * @author Kristine de Vries
 */
export default {
  name: 'SecondaryButton',
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    /**
     * @description Emit click event
     * @author Kristine de Vries
     */
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-secondary-btn {
  font-size: $font-size-sm;
  font-family: $font-family-display;
  font-weight: $font-weight-regular;
  color: $light;
}
</style>
