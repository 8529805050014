<template>
  <div class="z-secondary-header-wrapper">
    <header
      class="
        d-flex
        w-100
        align-items-center
        justify-content-between
        bg-white
        px-4
        z-secondary-header
      "
    >
      <!-- Go to dashboard button -->
      <div class="d-flex z-secondary-header-btn-wrapper">
        <IconButton
          class="d-flex align-items-center justify-content-center p-0"
          src="iconArrowLeft"
          width="1.5rem"
          height="1.5rem"
          @click="$router.push({ name: 'dashboard' })"
        >
          <template #label v-if="!isMobile()">
            <p class="mb-0 ml-1 z-secondary-header-btn-label">
              {{ goBackLabel }}
            </p>
          </template>
        </IconButton>
      </div>

      <!-- Header title -->
      <h6 class="mb-0 px-4 text-center z-secondary-header-title">
        {{ title }}
      </h6>

      <!-- Header right side action buttons -->
      <div class="d-flex z-secondary-header-btn-wrapper">
        <slot name="actionButtons" />
      </div>
    </header>

    <!-- Header footer slot -->
    <slot name="footer" />
  </div>
</template>

<script>
/**
 * @file
 * @description Base header component
 * @author Kristine de Vries
 */
import IconButton from '@/components/IconButton';
import { userAgent } from '@/mixins/functionalMixins';

export default {
  name: 'BaseHeader',
  mixins: [userAgent],
  components: {
    IconButton,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    goBackLabel: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import '@styles/base.scss';

.z-secondary-header-wrapper {
  box-shadow: 0 0.5px 1px $shadow-grey;
}

.z-secondary-header {
  min-height: 3.125rem;
}

.z-secondary-header-title {
  font-weight: $font-weight-semibold;
  font-size: $font-size-lg;
}

.z-secondary-header-btn-label {
  font-size: $font-size-lg;
}

.z-secondary-header-btn-wrapper {
  min-width: 14vw;

  &:nth-of-type(even) {
    justify-content: flex-end;
  }
}

@media print {
  .z-secondary-header-wrapper {
    display: none;
  }
}
</style>
