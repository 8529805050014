<template>
  <b-dropdown :text="label" class="z-dropdown" variant="transparent">
    <!-- Dropdown items  -->
    <slot name="dropdown-items" />
  </b-dropdown>
</template>

<script>
/**
 * @file
 * @description Dropdown button button component
 * @author Kristine de Vries
 */
export default {
  name: 'DropdownButton',
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-dropdown /deep/ .btn {
  padding: 0;
  font-family: $font-family-display;
  font-weight: $font-weight-regular;
}
</style>
