var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-stretch"},[(_vm.isAnswerColumn)?_vm._l((_vm.data),function(item,index){return _c('b-button',{key:index,class:[
        'p-0',
        'text-center',
        'z-table-right-col-cell',
        'z-score',
        'z-answer',
        [item.highlighted ? 'z-highlighted' : ''],
      ],attrs:{"variant":"transparent"},on:{"click":function($event){return _vm.highlightAnswer(item)}}},[_vm._v(" "+_vm._s(item[_vm.contentKey])+" ")])}):_vm._l((_vm.data),function(item,index){return _c('p',{key:index,staticClass:"mb-0 text-center align-self-center z-table-right-col-cell"},[_vm._v(" "+_vm._s(_vm.contentKey ? item[_vm.contentKey] : item)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }