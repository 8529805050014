<template>
  <div class="pt-8 z-summary">
    <h2 class="mb-6 z-summary-title">{{ $t('answerSummary') }}</h2>

    <div
      v-for="(question, questionIndex) in questionsWithAnswers"
      :key="question.order"
      class="d-flex flex-column mb-4"
    >
      <p class="z-summary-question-title mb-0">
        {{ `${question.order + 1}. ${question.title}` }}
      </p>
      <div
        :class="[
          questionIndex === questionsWithAnswers.length - 1
            ? 'z-summary-answer-container-last'
            : 'z-summary-answer-container',
        ]"
      >
        <p v-for="(answer, index) in question.answers" :key="index" class="z-summary-answer mb-0">
          {{ answer }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { questionTypes, formFlowSteps } from '@/utils/enums';
import { getQuestionAnswer } from '@/utils/functions';

const { SUMMARY } = formFlowSteps;

/**
 * @file
 * @description Answer summary screen
 * @author Thiago Fazzi
 * @author Kristine de Vries
 */
export default {
  name: 'Summary',
  props: {
    questions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    /**
     * @description Get questions with answers
     * @returns {Array}
     * @author Kristine de Vries
     */
    questionsWithAnswers() {
      return this.questions
        .filter(({ type }) => type !== SUMMARY)
        .map(question => {
          const { type } = question;
          const answer = getQuestionAnswer(question);
          const answerType = questionTypes?.[type]?.answerType;

          let answers = [answer?.[answerType] || this.$t('notAnswered')];

          // Do not display answers for page type questions
          if (answerType === 'completed') {
            answers = [''];
          }
          // For contact manager questions, display both contactManagerName and contactManagerEmail
          if (answerType === 'contactManager') {
            const { contactManagerName, contactManagerEmail } = answer;

            answers =
              contactManagerName || contactManagerEmail
                ? [
                    `${this.$t('name')}: ${contactManagerName}`,
                    `${this.$t('email')}: ${contactManagerEmail}`,
                  ]
                : [this.$t('notAnswered')];
          }

          return {
            ...question,
            answers,
          };
        });
    },
  },
};
</script>

<style lang="scss">
@import '@styles/base.scss';

.z-summary {
  padding-bottom: $spacer * 6;

  &-title {
    line-height: 1.8;
    font-size: $font-size-xxl;
    font-weight: $font-weight-bold;
    padding-bottom: $spacer * 2;
  }

  &-answer-container {
    border-bottom: 0.5px solid $shadow-grey;

    &-last {
      border-bottom: 0;
    }
  }
}

.z-summary-question-title {
  font-size: $font-size-lg;
  font-family: $font-family-display;
  font-weight: $font-weight-medium;
}

.z-summary-answer {
  color: $light;
  padding-left: map-get($spacers, 9);

  &:first-child {
    padding-top: map-get($spacers, 2);
  }

  &:last-child {
    padding-bottom: map-get($spacers, 4);
  }
}
</style>
