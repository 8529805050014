<template>
  <b-button :variant="variant" block :disabled="disabled" class="z-btn-primary" @click="click">
    <!-- Loading indicator -->
    <b-spinner small v-if="loading" />
    <!-- Label -->
    <span v-else> {{ label }}</span>
    <!-- Right side detail  -->
    <slot name="rightDetail" />
  </b-button>
</template>

<script>
/**
 * @file
 * @description Primary button component
 * @author Kristine de Vries
 */
export default {
  name: 'PrimaryButton',
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
  methods: {
    /**
     * @description Emit click event
     * @author Kristine de Vries
     */
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-btn-primary {
  font-family: $font-family-display;
  font-weight: $font-weight-medium;
}
</style>
