<template>
  <div
    :class="['p-4', 'pb-6', 'mb-4', 'z-hub-card', [clickable ? 'z-hub-card-clickable' : '']]"
    @click="click"
  >
    <!-- Card overlay -->
    <div class="z-hub-card-overlay" />

    <!-- Card status tag -->
    <CardTag
      v-if="currentStatus"
      :icon="currentStatus.icon"
      :label="
        item.availableFrom
          ? $t('available', { unlockTime: item.availableFrom })
          : $t(`${currentStatus.label}`)
      "
      :backgroundColor="currentStatus.backgroundColor"
    />

    <!-- Title -->
    <div class="d-flex mb-2 mt-3 flex-row">
      <Icon src="iconCardHub" width="1.25rem" height="1.25rem" />
      <h6 class="mb-0 ml-2 z-hub-card-title">{{ item.titleOverview }}</h6>
    </div>

    <!-- Subtitle -->
    <p class="mb-0 z-hub-card-subTitle">{{ item.subtitle }}</p>
  </div>
</template>

<script>
/**
 * @file
 * @description Hub card component
 * @author Kristine de Vries
 */
import Icon from '@/components/Icon';
import { cardStatusLabels } from '@/utils/enums';

import CardTag from './CardTag';

export default {
  name: 'HubCard',
  components: {
    CardTag,
    Icon,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    const { status } = this.item;

    return {
      currentStatus: cardStatusLabels[status] || null,
    };
  },
  computed: {
    /**
     * @description Returns false for locked cards
     * @author Kristine de Vries
     */
    clickable() {
      const { status } = this.item;
      return status !== 'LOCKED';
    },
  },
  methods: {
    /**
     * @description Emit click event to the parent component
     * @author Kristine de Vries
     */
    click() {
      if (this.clickable) {
        this.$emit('click', this.item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_hubcards.scss';
</style>
