<template>
  <b-container class="mt-9 col-10 col-lg-8 col-xl-5 pb-8 px-0">
    <h2 class="mb-8 z-form-title">{{ title }}</h2>
    <div v-html="description" class="z-form-description" />

    <PrimaryButton
      ref="button"
      :label="$t('start', { formType: `${$t(formType)}` })"
      :disabled="false"
      class="my-8"
      @click="click"
    />
  </b-container>
</template>

<script>
/**
 * @file
 * @description Introduction component for the form
 * @author Thiago Fazzi
 */
import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'Introduction',
  components: {
    PrimaryButton,
  },
  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },
    description: {
      type: String,
      require: true,
      default: '',
    },
    formType: {
      type: String,
      require: true,
      default: '',
    },
  },
  mounted() {
    document.addEventListener('keyup', this.useKeyboard);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.useKeyboard);
  },
  methods: {
    /**
     * @description Emits click event when user has pressed enter
     * @author Kristin de Vries
     */
    useKeyboard(e) {
      const { which, shiftKey } = e;
      if (which === 13 && !shiftKey) {
        this.click();
      }
    },
    /**
     * @description Emit event to the parent component
     * @author Thiago Fazzi
     */
    click() {
      this.$emit('startForm');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/base.scss';

.z-form-title {
  font-family: $font-family-display;
  font-size: $font-size-xxl;
  font-weight: $font-weight-semibold;
  line-height: 1.6rem;
}

.z-form-description {
  font-size: $font-size-lg;
  font-weight: $font-weight-regular;
  line-height: 1.6rem;
}
</style>
