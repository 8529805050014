<template>
  <b-carousel v-model="currentStep" :interval="0" class="w-100">
    <!-- Text slides with image -->
    <b-carousel-slide
      v-for="(question, index) in questions"
      :key="`${question.id}${question.facilitatorId}`"
    >
      <template #img>
        <b-container class="col-10 col-lg-8 col-xl-5 pb-8 px-0">
          <!-- Current question -->
          <div v-if="questionTypes[question.type]" :class="['z-question-slide', question.type === 'OPEN' ? '--open' : '' ]">
            <!-- Display the progress -->
            <p class="text-light">
              <small>{{ $t('questionProgress', { step: currentStep + 1, maxSteps }) }}</small>
              <Tooltip v-if="displayTooltip" :title="$t('tooltipRatingQuestions')" />
            </p>

            <!-- Display the question title -->
            <h4 :class="question.required ? 'mb-8 z-required' : 'mb-8'">
              {{ question.title }}
            </h4>

            <!-- Display the component for the current question type -->
            <component
              ref="component"
              :is="questionTypes[question.type].component"
              :value="
                question.type === 'CONTACTMANAGER'
                  ? getAnswer(question)
                  : answers[index][questionTypes[question.type].answerType]
              "
              :question="question"
              :editable="editable"
              @input="val => handleAnswerChange(questionTypes[question.type].answerType, val)"
            />

            <!-- Display the explanation field (if enabled) -->
            <template v-if="question.explanationField">
              <h4 class="mb-8">{{ $t('explain') }}</h4>
              <b-form-textarea
                :value="answers[index].explanation"
                :placeholder="$t('textareaPlaceholder')"
                :disabled="!editable"
                size="lg"
                trim
                rows="3"
                max-rows="6"
                class="mb-9"
                @input="val => handleAnswerChange('explanation', val)"
              />
            </template>
          </div>

          <!-- Summary -->
          <template v-else>
            <ISQISISummary
              v-if="(formType === 'ISQ' || formType === 'ISI') && isFormFinished"
              :isFormFinished="isFormFinished"
              :formType="formType"
            />
            <Summary v-else :questions="questions" />
          </template>
        </b-container>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import { questionTypes } from '@/utils/enums';
import { getQuestionAnswer, validateEmail } from '@/utils/functions';

import Summary from './Summary';
import ISQISISummary from './ISQISISummary';
import Score from './questions/Score';
import Video from './questions/Video';
import Open from './questions/Open';
import Radio from './questions/Radio';
import Scale from './questions/Scale';
import Html from './questions/Html';
import ContactManager from './questions/ContactManager';

const {
  ISQ: { id: ISQ },
  ISI: { id: ISI },
  RATING: { id: RATING },
  CONTACTMANAGER: { id: CONTACTMANAGER },
  OPEN: { ID: OPEN },
} = questionTypes;

/**
 * @file
 * @description Questionnaire component for the form flow
 * @author Thiago Fazzi
 * @author Kristine de Vries
 */
export default {
  name: 'Questionnaire',
  components: {
    Score,
    Video,
    Open,
    Radio,
    Scale,
    Html,
    ContactManager,
    Summary,
    ISQISISummary,
    Tooltip,
  },
  props: {
    questions: {
      type: Array,
      required: true,
      default: () => [],
    },
    questionIndex: {
      type: Number,
      required: true,
      default: 0,
    },
    currentQuestion: {
      type: Object,
      required: true,
      default: () => {},
    },
    maxSteps: {
      type: Number,
      required: true,
      default: 0,
    },
    isFormAnswerable: {
      type: Boolean,
      required: true,
      default: false,
    },
    isFormFinished: {
      type: Boolean,
      required: true,
      default: false,
    },
    answers: {
      type: Array,
      required: true,
      default: () => [],
    },
    editable: {
      type: Boolean,
      required: true,
      default: true,
    },
    formType: {
      type: String,
      required: true,
      default: '',
    },
    isSkippable: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      questionTypes,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.useKeyboard);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.useKeyboard);
  },
  computed: {
    /**
     * @description Current question slide index
     * @returns {number}
     * @author Kristine de Vries
     */
    currentStep: {
      get() {
        return this.questionIndex;
      },
      set(index) {
        this.$emit('input', index);
      },
    },
    /**
     * @description Displays tooltip next to question number if it is ISI, ISQ or a rating question
     * @returns {boolean}
     * @author Miguel Aguiar
     */
    displayTooltip() {
      const { type } = this.currentQuestion;
      return type === 'ISI' || type === 'ISQ' || type === 'RATING';
    },
  },
  methods: {
    /**
     * @description Gets current question answer depending on answerType or explanation field
     * @returns {Object}
     * @author Kristine de Vries
     */
    getAnswer(question, property) {
      const answer = getQuestionAnswer(question);

      // If specific property is specifiied (like explanation)
      // return this property
      if (property) {
        return answer[property];
      }
      // Otherwise return question's default answer type
      const { type } = question;
      const answerType = questionTypes[type]?.answerType;

      // For contact manager questions return an object with both contactManagerName and contactManagerEmail
      if (answerType === 'contactManager') {
        return {
          contactManagerName: answer.contactManagerName,
          contactManagerEmail: answer.contactManagerEmail,
        };
      }
      return answer[answerType];
    },
    /**
     * @description Emits answerChange event
     * @author Kristine de Vries
     */
    handleAnswerChange(field, value) {
      this.$emit('answerChange', field, value);
    },
    /**
     * @description Emits navigation event when component is controlled
     * by key controls
     * @author Kristine de Vries
     */
    navigate(difference) {
      this.$emit('navigate', difference);
    },
    /**
     * @description Emits navigateToIntroduction even when component is controlled
     * by key controls
     * @author Kristine de Vries
     */
    navigateToIntroduction() {
      this.$emit('navigateToIntroduction');
    },
    /**
     * @description Check if question has an answer, if an explanation field is also required
     * checks if this is also filled in
     * @returns {boolean}
     * @author Kristine de Vries
     */
    checkIfQuestionAnswered() {
      const { explanationField, required, type } = this.currentQuestion;
      const answer = this.answers[this.currentStep];

      const questionWithAnswer = { ...this.currentQuestion, answers: [answer] };

      const hasAnswer = this.getAnswer(questionWithAnswer);

      if (type === CONTACTMANAGER) {
        const { contactManagerName, contactManagerEmail } = answer;
        return contactManagerName && contactManagerEmail && validateEmail(contactManagerEmail);
      }

      if (explanationField && required) {
        return hasAnswer && this.getAnswer(questionWithAnswer, 'explanation');
      }

      return hasAnswer;
    },
    /**
     * @description Function to control the form using the keyboard keys
     * Answer '1 to 5'
     * Next question 'Enter'
     * Previous question 'Esc'
     * @param {Object} e
     * @author Thiago Fazzi
     */
    useKeyboard(e) {
      const { which } = e;
      const { type, explanationField } = this.currentQuestion;

      // Disable keyboard navigation when one of following conditions is met
      if (type === OPEN || explanationField || this.isSkippable) {
        return;
      }

      switch (which) {
        case 49: // 1
        case 50: // 2
        case 51: // 3
        case 52: // 4
        case 53: // 5
        case 97: // Numpad 1 WinOS
        case 98: // Numpad 2 WinOS
        case 99: // Numpad 3 WinOS
        case 100: // Numpad 4 WinOS
        case 101: // Numpad 5 WinOS
          if (type === ISQ || type === ISI || type === RATING) {
            this.$refs.component?.[this.currentStep]?.setOption(e);
          }
          break;
        case 39: // Right arrow
          // If current question has answer or if it is optional - navigate forward
          if (this.checkIfQuestionAnswered() || !this.currentQuestion.required) {
            this.navigate(1);
          }
          break;
        case 27: // 'Esc'
        case 37: // Left arrow
          if (this.currentStep > 0) {
            this.navigate(-1);
          } else {
            this.navigateToIntroduction();
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-question-slide {
  padding-top: $spacer * 5;
  padding-bottom: $spacer * 10;
}

.z-question-slide.--open {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20vh);
  padding-bottom: 0;
}

.z-required:before {
  content: '*';
  color: red;
}
</style>
