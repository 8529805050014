<template>
  <transition appear mode="out-in" name="z-fade">
    <span
    tabindex="0"
    v-b-tooltip.right.html
    :title="title"
    class="z-tooltip-icon">
    ?
    </span>
  </transition>
</template>

<script>
/**
 * @file
 * @description Tooltip component
 * @author Miguel Aguiar
 */
export default {
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';
@import '@styles/partials/_transitions.scss';

.z-tooltip-icon {
  cursor: default;
  margin-left: $spacer * 0.4;
  color: white;
  background-color: $light;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  border-radius: 100%;
  font-size: 0.6rem;
  text-align: center;
  vertical-align: middle;
}

.tooltip {
  margin-left: $spacer * 0.5;

  > :is(.tooltip-inner) {
    text-align: start;
    padding: ($spacer * 0.7) $spacer;
    font-size: $font-size-xs;
  }
}
</style>
