<template>
  <b-button variant="transparent" @click="click">
    <Icon :src="src" :width="width" :height="height" />
    <slot name="label"></slot>
  </b-button>
</template>

<script>
import Icon from '@/components/Icon';

/**
 * @file
 * @description Icon button component
 * @author Kristine de Vries
 */
export default {
  name: 'IconButton',
  components: {
    Icon,
  },
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    height: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
  },
  methods: {
    /**
     * @description Emit click event
     * @author Kristine de Vries
     */
    click() {
      this.$emit('click');
    },
  },
};
</script>
