export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      tenantId
      firstName
      preferredLanguage
      archived
      gender
      finishedForms {
        eventId
        formId
        open
      }
      role
      events {
        items {
          event {
            id
            title
            archived
            language
            dates {
              startDateTime
              endDateTime
            }
            users {
              items {
                user {
                  id
                  firstName
                  prefix
                  lastName
                  role
                  anonymised
                }
              }
            }
            firstDay
            lastDay
            statusGo
          }
        }
      }
      registrations {
        items {
          id
          statusGo
          eventId
          event {
            id
            title
            archived
            language
            dates {
              startDateTime
              endDateTime
            }
            users {
              items {
                user {
                  id
                  firstName
                  prefix
                  lastName
                  role
                  anonymised
                }
              }
            }
            firstDay
            lastDay
            statusGo
          }
        }
      }
    }
  }
`;

export const getUserConnectionsBasedOnEvent = /* GraphQL */ `
  query GetUser($id: ID!, $eventId: ID!) {
    getUser(id: $id) {
      answers(filter: { eventId: { eq: $eventId } }) {
        items {
          id
          userId
          tenantId
          eventId
          questionId
          facilitatorId
          optionAnswer
          textAnswer
          scaleAnswer
          scoreAnswer
          explanation
          contactManagerName
          contactManagerEmail
          highlighted
          completed
          createdAt
        }
      }
      supporters(filter: { eventId: { eq: $eventId } }) {
        items {
          id
          firstName
          prefix
          lastName
          email
          isQuestionnaire
          createdAt
          finishedForms {
            formId
            finishedAt
          }
          answers {
            questionId
            formId
            scoreAnswer
            textAnswer
            highlighted
          }
        }
      }
    }
  }
`;

export const getEvent = /* GraphQL */ `
  query GetEvent($tenantId: ID!, $id: ID!) {
    getEvent(tenantId: $tenantId, id: $id) {
      archived
      statusGo
      title
      dates {
        startDateTime
        endDateTime
      }
      firstDay
      lastDay
      formQuestions {
        items {
          formId
          questionId
          form {
            id
            type
            archived
            defaultLanguage
            translations {
              items {
                title
                titleOverview
                subtitle
                description
                language
              }
            }
          }
          question {
            id
            order
            required
            type
            formId
            defaultLanguage
            translations {
              items {
                title
                questionSupporter
                language
                html
                youtubeId
                options {
                  title
                }
              }
            }
            influenceStyle
            behaviourType
            min
            max
            step
            explanationField
            archived
          }
          openDaysToEvent
          openDateReference
          closeDaysToEvent
          closeDateReference
          anonymiseSupporters
        }
      }
      modules {
        items {
          module {
            id
            type
          }
        }
      }
    }
  }
`;
