import colors from '@/assets/styles/partials/_export.module.scss';

const {
  blue,
  light,
  green,
  orange,
  primary,
  graphBlue,
  graphNavy,
  graphLightBlue,
  graphYellow,
  graphOrangeRed,
  graphTeal,
  graphPurple,
} = colors;

// Date formats
export const BASE_DATE_FORMAT = 'YYYY-MM-DD';
export const AWS_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss[Z]';

// Appstore and Google playstore links
export const APPSTORE_LINK = 'https://apps.apple.com/us/app/zuidema-influence-hub/id1495169282';
export const PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.ZuidemaKitApp';

// Login flow steps
export const authSteps = {
  EMAIL: {
    component: 'Email',
    submit: 'submitEmail',
  },
  CODE: {
    component: 'Code',
    submit: 'signInWithCode',
  },
  PASSWORD: {
    component: 'Password',
    submit: 'signInWithPassword',
  },
};

// Modal types
export const modalTypes = {
  loginCodeHelp: 'loginCodeHelp',
  loginPasswordHelp: 'loginPasswordHelp',
  loginWrongCode: 'loginWrongCode',
  loginWrongPassword: 'loginWrongPassword',
};

// User roles
export const userRoles = {
  TRAINER: 'Trainer',
  PARTICIPANT: 'Participant',
  ACTOR: 'Actor',
};

// Event date reference options
export const eventDateOptions = {
  FIRST_DAY: 'FIRST_DAY',
  LAST_DAY: 'LAST_DAY',
};

// Card status label types
export const cardStatusLabels = {
  NEW: {
    id: 'NEW',
    icon: 'iconCardAlert',
    label: 'new',
    backgroundColor: blue,
  },
  STARTED: {
    id: 'STARTED',
    icon: 'iconCardClock',
    label: 'continue',
    backgroundColor: orange,
  },
  FINISHED: {
    id: 'FINISHED',
    icon: 'iconCardCheckMark',
    label: 'finished',
    backgroundColor: green,
  },
  LOCKED: {
    id: 'LOCKED',
    icon: 'iconCardLock',
    label: 'locked',
    backgroundColor: light,
  },
  CLOSED: {
    id: 'CLOSED',
    icon: 'iconCardXMark',
    label: 'closed',
    backgroundColor: primary,
  },
};

export const formTypes = {
  ISI: 'ISI',
  ISQ: 'ISQ',
  INTAKE: 'INTAKE',
  EVALUATION: 'EVALUATION',
  EFFECT: 'EFFECT',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  QUESTIONNAIRE_FEEDBACK: 'QUESTIONNAIRE_FEEDBACK',
};

const { ISQ, ISI, INTAKE, EVALUATION, EFFECT } = formTypes;

// pre-defined order for the cards on the dashboard
export const dashboardCardOrder = {
  ISQ: 1,
  ISI: 1,
  ISQISI_RESULTS: 2,
  QUESTIONNAIRE_RESULTS: 3,
  INTAKE: 4,
  EVALUATION: 5,
  EFFECT: 6,
};

// Participant webapp supported form types
export const supportedFormTypes = [ISQ, ISI, INTAKE, EVALUATION, EFFECT];

export const questionTypes = {
  OPEN: {
    id: 'OPEN',
    name: 'open',
    component: 'Open',
    answerType: 'textAnswer',
  },
  RADIO: {
    id: 'RADIO',
    name: 'radio',
    component: 'Radio',
    answerType: 'optionAnswer',
  },
  SCALE: {
    id: 'SCALE',
    name: 'scale',
    component: 'Scale',
    answerType: 'scaleAnswer',
  },
  CONTACTMANAGER: {
    id: 'CONTACTMANAGER',
    name: 'contactManager',
    component: 'ContactManager',
    answerType: 'contactManager',
  },
  ISQ: {
    id: 'ISQ',
    name: 'isq',
    component: 'Score',
    answerType: 'scoreAnswer',
  },
  ISI: {
    id: 'ISI',
    name: 'isi',
    component: 'Score',
    answerType: 'scoreAnswer',
  },
  RATING: {
    id: 'RATING',
    name: 'rating',
    component: 'Score',
    answerType: 'scoreAnswer',
  },
  VIDEO: {
    id: 'VIDEO',
    name: 'videoPage',
    component: 'Video',
    answerType: 'completed',
  },
  HTML: {
    id: 'HTML',
    name: 'contentPage',
    component: 'Html',
    answerType: 'completed',
  },
};

export const formFlowSteps = {
  INTRODUCTION: 'INTRODUCTION',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  SUMMARY: 'SUMMARY',
};

// Facilitator question templates
export const facilitatorTemplates = {
  TRAINER_NAME: '{trainerName}',
  ACTOR_NAME: '{actorName}',
};

// Other question templates
export const questionTemplates = {
  PARTICIPANT_NAME: '{participantName}',
  PROGRAM_NAME: '{programName}',
  SUBJECTIVE_PRONOUN: '{subjectivePronoun}',
  POSSESSIVE_PRONOUN: '{possessivePronoun}',
  OBJECTIVE_PRONOUN: '{objectivePronoun}',
};

// gender types
export const genderTypes = {
  M: 'Male',
  F: 'Female',
  N: 'Neutral',
};

// Module types
export const moduleTypes = {
  SUPPORTERINVITE: 'SUPPORTERINVITE',
};

export const resultsModuleTypes = {
  ISQISI_RESULTS: 'ISQISI_RESULTS',
  QUESTIONNAIRE_RESULTS: 'QUESTIONNAIRE_RESULTS',
};

// Push notification types
export const pushNotifications = {
  SUPPORTERS_INVITE: 'SUPPORTERS_INVITE',
  SUPPORTERS_INVITE_REMINDER: 'SUPPORTERS_INVITE_REMINDER',
};
// Influence styles
export const influenceStyleTypes = {
  OVERREDEN: 'OVERREDEN',
  STELLEN: 'STELLEN',
  OVERBRUGGEN: 'OVERBRUGGEN',
  INSPIREREN: 'INSPIREREN',
  WEGBEWEGEN: 'WEGBEWEGEN',
};

const { OVERREDEN, STELLEN, OVERBRUGGEN, INSPIREREN, WEGBEWEGEN } = influenceStyleTypes;

// Behaviour types
export const behaviourTypes = {
  VOORSTELLENDOEN: { label: 'VOORSTELLENDOEN', ISQGraphValue: 1, influenceStyle: OVERREDEN },
  ARGUMENTEREN: { label: 'ARGUMENTEREN', ISQGraphValue: 2, influenceStyle: OVERREDEN },
  WAARDEREN: { label: 'WAARDEREN', ISQGraphValue: 3, influenceStyle: STELLEN },
  NORMENSTELLEN: { label: 'NORMENSTELLEN', ISQGraphValue: 4, influenceStyle: STELLEN },
  FACILITEREN: { label: 'FACILITEREN', ISQGraphValue: 5, influenceStyle: STELLEN },
  BETREKKEN: { label: 'BETREKKEN', ISQGraphValue: 6, influenceStyle: OVERBRUGGEN },
  LUISTEREN: { label: 'LUISTEREN', ISQGraphValue: 7, influenceStyle: OVERBRUGGEN },
  ONTHULLEN: { label: 'ONTHULLEN', ISQGraphValue: 8, influenceStyle: OVERBRUGGEN },
  KOPPELEN: { label: 'KOPPELEN', ISQGraphValue: 9, influenceStyle: INSPIREREN },
  VISIEDELEN: { label: 'VISIEDELEN', ISQGraphValue: 10, influenceStyle: INSPIREREN },
  AFSTANDNEMEN: { label: 'AFSTANDNEMEN', ISQGraphValue: 11, influenceStyle: WEGBEWEGEN },
  ONTWIJKEN: { label: 'ONTWIJKEN', ISQGraphValue: 12, influenceStyle: WEGBEWEGEN },
};

// Behaviour types
export const influenceStylesForGraph = {
  OVERREDEN: { label: 'OVERREDEN', ISQGraphValue: 1 },
  STELLEN: { label: 'STELLEN', ISQGraphValue: 3 },
  OVERBRUGGEN: { label: 'OVERBRUGGEN', ISQGraphValue: 5 },
  INSPIREREN: { label: 'INSPIREREN', ISQGraphValue: 7 },
  WEGBEWEGEN: { label: 'WEGBEWEGEN', ISQGraphValue: 9 },
};

const {
  VOORSTELLENDOEN,
  ARGUMENTEREN,
  WAARDEREN,
  NORMENSTELLEN,
  FACILITEREN,
  BETREKKEN,
  LUISTEREN,
  ONTHULLEN,
  KOPPELEN,
  VISIEDELEN,
  AFSTANDNEMEN,
  ONTWIJKEN,
} = behaviourTypes;

// Connection matrix between influence styles and behaviour types - used for ISQ graph sections
export const graphSections = {
  [OVERREDEN]: [VOORSTELLENDOEN, ARGUMENTEREN],
  [STELLEN]: [WAARDEREN, NORMENSTELLEN, FACILITEREN],
  [OVERBRUGGEN]: [BETREKKEN, LUISTEREN, ONTHULLEN],
  [INSPIREREN]: [KOPPELEN, VISIEDELEN],
  [WEGBEWEGEN]: [AFSTANDNEMEN, ONTWIJKEN],
};

/**
 * ISQ graph data groups
 */
export const ISQISIGraphDataGroups = [
  {
    type: 'participantData',
    color: green,
  },
  {
    type: 'supporter0',
    color: graphBlue,
  },
  {
    type: 'supporter1',
    color: graphNavy,
  },
  {
    type: 'supporter2',
    color: graphLightBlue,
  },
  {
    type: 'supporter3',
    color: graphYellow,
  },
  {
    type: 'supporter4',
    color: graphOrangeRed,
  },
  {
    type: 'supporter5',
    color: graphTeal,
  },
  {
    type: 'supporter6',
    color: graphPurple,
  },
];

export const browsers = {
  CHROME: 'CHROME',
  FIREFOX: 'FIREFOX',
  SAFARI: 'SAFARI',
  EDGE: 'EDGE',
  OPERA: 'OPERA',
  OTHER: 'OTHER',
};

// Max allowed supporters
export const MAX_QUESTIONNAIRE_SUPPORTERS = 15;
