<template>
  <div>
    <div class="w-100 z-header">
      <!-- Zuidema logo -->
      <a href="https://zuidema.nl/" target="_blank">
        <Icon
          :src="getLogoAsset()"
          :class="['position-absolute', isMobile() ? 'z-header-mobile-icon' : 'z-header-icon']"
        />
      </a>
      <!-- Header action buttons -->
      <div
        :class="
          isMobile ? 'z-header-action-button-container-mobile' : 'z-header-action-button-container'
        "
      >
        <!-- Button to switch events -->
        <DropdownButton
          :label="isMobile() ? '' : $t('myEvents')"
          class="mr-4 z-header-switch-events-button"
        >
          <template #dropdown-items>
            <b-dropdown-item-button
              v-for="event in events"
              :active="event.id === currentEventId"
              :key="event.id"
              @click="handleEventItemClick(event.id)"
            >
              {{ event.title }}
            </b-dropdown-item-button>
          </template>
        </DropdownButton>

        <!-- Logout button -->
        <SecondaryButton :label="$t('logout')" class="z-header-logout-button" @click="signOut" />
      </div>
    </div>
    <!-- Title and subtitle -->
    <b-container class="pt-6 pb-8 px-0 col-10 col-lg-8 z-dashboard-header-text-container">
      <h1 class="mt-9 z-dashboard-header-title">{{ title }}</h1>
      <p class="z-dashboard-header-subtitle">{{ subTitle }}</p>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Auth } from 'aws-amplify';
import SecondaryButton from '@/components/SecondaryButton';
import DropdownButton from '@/components/DropdownButton';
import Icon from '@/components/Icon';
import { userAgent } from '@/mixins/functionalMixins';

/**
 * @file
 * @description Dashboard header component
 * @author Kristine de Vries
 */
export default {
  name: 'Header',
  components: {
    SecondaryButton,
    DropdownButton,
    Icon,
  },
  props: {
    title: {
      type: String,
      requied: true,
      default: '',
    },
    subTitle: {
      type: String,
      requied: true,
      default: '',
    },
  },
  mixins: [userAgent],
  data() {
    return {
      selectedEventId: '',
      windowWidth: null,
    };
  },
  /**
   * @description Update window width property when component is mounted and when screen size changes
   * @author Kristine de Vries
   */
  mounted() {
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    ...mapGetters(['getStateItems']),
    /**
     * @description Get events with statusGo true that also have atleast one registration with statusGo true
     * @author Kristine de Vries
     */
    events() {
      const events = this.getStateItems('events');
      return Object.values(events).filter(
        ({ registrationStatusGo, statusGo }) => registrationStatusGo && statusGo,
      );
    },
    /**
     * @description Get currently active event id
     * @author Kristine de Vries
     */
    currentEventId() {
      return this.getStateItems('currentEventId');
    },
  },
  methods: {
    ...mapActions(['resetState', 'initiateData']),
    /**
     * @description Get zuidema logo asset depending on device type and screenWidth
     * @author Kristine de Vries
     */
    getLogoAsset() {
      if (this.isMobile()) {
        return 'zuidemaLogoSmall';
      }
      return this.windowWidth >= 992 ? 'zuidemaLogo' : 'zuidemaLogoSmall';
    },
    /**
     * @description Signs out user and resets the state
     * @author Kristine de Vries
     */
    async signOut() {
      await Auth.signOut();
      sessionStorage.clear();
      await this.resetState();
      this.$router.go();
    },
    /**
     * @description Switches currentEvent to the selected event
     * @author Kristine de Vries
     */
    async handleEventItemClick(newEventId) {
      if (newEventId !== this.currentEventId) {
        // Set the new event id in local storage (necessary to prevent issues when fetching data on page refresh)
        localStorage.setItem('currentEventId', newEventId);
        this.initiateData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-header {
  position: fixed;
  z-index: 1;
  background-color: $white;
  min-height: 3.125rem;
  box-shadow: 0 0.5px 1px $shadow-grey;
}

.z-header-icon {
  cursor: pointer;
  top: 0;
  left: 3rem;
}

.z-header-mobile-icon {
  left: 0;
}

.z-dashboard-header-title {
  font-family: $font-family-display;
  font-weight: $font-weight-black;
  font-size: $font-size-xxl;
}

.z-dashboard-header-subtitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-lg;
  color: $light;
}

.z-header-action-button-container {
  position: fixed;
  top: map-get($spacers, 3);
  right: 3rem;
}

.z-header-action-button-container-mobile {
  position: fixed;
  top: map-get($spacers, 3);
  right: 1rem;
}

.z-header-switch-events-button {
  /deep/ .btn {
    color: $light;
    font-size: $font-size-lg;

    &:hover,
    &:focus,
    &:active {
      color: $dark;
    }
  }

  /deep/ .active {
    background-color: $white;
    color: $primary;
  }

  /deep/ .dropdown-item {
    &:active {
      background-color: $white;
      color: $primary;
    }
  }
}

.z-header-logout-button {
  color: $primary;
  font-size: $font-size-lg;

  &:hover,
  &:focus,
  &:active {
    color: $primary-dark !important;
  }
}
</style>
