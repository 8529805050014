import Vue from 'vue';
import { Amplify, Amplify as AmplifyModules } from 'aws-amplify';
import { BootstrapVue } from 'bootstrap-vue';
import VueMasonry from 'vue-masonry-css';
import ToggleButton from 'vue-js-toggle-button';

import '@/utils/validators';
import store from '@/store/store';
import i18n from '@/assets/languages/i18n';
import App from '@/App';
import router from '@/router';
import '@styles/bootstrap.scss';
import awsConfig from '../config/amplify';

Amplify.configure(awsConfig);

Vue.use(AmplifyModules);
Vue.use(BootstrapVue);
Vue.use(VueMasonry);
Vue.use(ToggleButton);

Vue.config.productionTip = false;

const vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

export default vm;
