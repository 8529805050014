<template>
  <div class="d-flex justify-content-center align-items-stretch">
    <!-- Answers -->
    <template v-if="isAnswerColumn">
      <b-button
        v-for="(item, index) in data"
        variant="transparent"
        :key="index"
        :class="[
          'p-0',
          'text-center',
          'z-table-right-col-cell',
          'z-score',
          'z-answer',
          [item.highlighted ? 'z-highlighted' : ''],
        ]"
        @click="highlightAnswer(item)"
      >
        {{ item[contentKey] }}
      </b-button>
    </template>
    <!-- Other type of content -->
    <template v-else>
      <p
        v-for="(item, index) in data"
        :key="index"
        class="mb-0 text-center align-self-center z-table-right-col-cell"
      >
        {{ contentKey ? item[contentKey] : item }}
      </p>
    </template>
  </div>
</template>

<script>
import eventBus from '@/utils/eventBus';

/**
 * @file
 * @description ISQ/ISI table right column component
 * @author Kristine de Vries
 */
export default {
  name: 'TableRightColumn',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    contentKey: {
      type: String,
      required: false,
      default: '',
    },
    isAnswerColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    /**
     * @description Emit highlightAnswer event
     * @author Kristine de Vries
     */
    highlightAnswer(item) {
      eventBus.$emit('highlightAnswer', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_tables.scss';
</style>
