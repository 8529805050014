<template>
  <b-form-textarea
    v-model="inputValue"
    class="z-open-question"
    :placeholder="$t('textareaPlaceholder')"
    :disabled="!editable"
    size="lg"
    trim
    rows="3"
    max-rows="999"
  />
</template>

<script>
/**
 * @file
 * @description Component for displaying Open questions
 * @author Thiago Fazzi
 */
import { questionProps, inputValue } from '@/mixins/functionalMixins';

export default {
  name: 'Open',
  mixins: [questionProps, inputValue],
};
</script>

<style lang="scss" scoped>
  @import '@styles/base.scss';
.z-open-question {
  max-height: calc(100vh - 25rem);
}
</style>
