<template>
  <!-- Container -->
  <b-container class="d-flex flex-column flex-grow-1 justify-content-center">
    <!-- Alert modal -->
    <AlertModal
      :isOpen="isModalOpen"
      :title="alertModal.title"
      :description="alertModal.description"
      :buttons="alertModal.buttons"
      @close="closeModal"
    />
    <transition appear mode="out-in" name="z-fade">
      <b-row class="justify-content-center" :key="component">
        <div class="d-flex flex-column justify-content-center align-items-center z-login-container">
          <!-- Logo icon -->
          <Icon src="logoRounded" width="6rem" height="6rem" />

          <!-- Login step component -->
          <component
            ref="component"
            :is="component"
            :loading="loading"
            :codeParam="codeParam"
            @submit="submitStep"
            @navigate="navigate"
            @openModal="openModal"
          />
        </div>
      </b-row>
    </transition>
  </b-container>
</template>

<script>
/**
 * @file
 * @description Login screen
 * @author Kristine de Vries
 */
import { Auth } from 'aws-amplify';
import { mapActions } from 'vuex';

import Icon from '@/components/Icon';
import AlertModal from '@/components/modals/AlertModal';
import { authSteps } from '@/utils/enums';
import { toggleModal, showError } from '@/mixins/functionalMixins';

import Email from './components/Email';
import Code from './components/Code';
import Password from './components/Password';

const [EMAIL, CODE] = Object.keys(authSteps);

const INVALID_EMAIL = 'INVALID_EMAIL';
const INVALID_PASSWORD = 'INVALID_PASSWORD';

export default {
  name: 'Login',
  components: {
    Email,
    Code,
    Password,
    Icon,
    AlertModal,
  },
  mixins: [toggleModal, showError],
  data() {
    return {
      currentStep: EMAIL,
      loading: false,
      cognitoUser: null,
      codeParam: null,
    };
  },
  computed: {
    /**
     * @description Render correct component depending on current auth step
     * @author Kristine de Vries
     */
    component() {
      const { component } = authSteps[this.currentStep] || {};
      return component;
    },
  },
  methods: {
    ...mapActions(['initiateData', 'resetState']),
    /**
     * @description Returns a random string
     * @author Kristine de Vries
     */
    getRandomString() {
      return (
        Math.random().toString(36).substring(2, 15).toUpperCase() +
        Math.random().toString(36).substring(2, 15)
      );
    },
    /**
     * @description Create a password after verifying that it matches the cognito password policy
     * @returns {String}
     * @author Kristine de Vries
     */
    createPassword() {
      // Regex check to allow (uppercase, lowercase, numeric) and disallow (special characters)
      const passwordPolicyRegex =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?!.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;
      const password = this.getRandomString().substring(5, 20);

      if (!passwordPolicyRegex.test(password)) {
        return this.createPassword();
      }
      return password;
    },
    /**
     * @description Use the correct submit function depending on current component
     * @author Kristine de Vries
     */
    async submitStep(data) {
      if (authSteps[this.currentStep]) {
        await this[authSteps[this.currentStep].submit](data);
      }
    },
    /**
     * @description Initiate sign in with cognito
     * @author Kristine de Vries
     */
    async submitEmail(email) {
      try {
        this.loading = true;
        const user = await Auth.signIn(email.toLowerCase());

        // Navigate to code input screen:
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          // In DEV mode, set the login code
          if (process.env.NODE_ENV === 'development') {
            this.codeParam = user?.challengeParam?.loginVerificationCode;
          }
          this.cognitoUser = user;
          this.currentStep = CODE;
        }
      } catch (e) {
        this.handleLoginError(e, INVALID_EMAIL);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Sign the user in with confirmation code that has been sent to user's email.
     * @author Kristine de Vries
     */
    async signInWithCode(code) {
      const { loginWrongCode } = this.modalTypes;

      try {
        this.loading = true;
        await Auth.sendCustomChallengeAnswer(this.cognitoUser, code);
        // Load the current cognito and database user
        await this.initiateData();
        // Navigate to the app
        this.$router.push({ name: 'dashboard' });
      } catch (e) {
        if (
          e === 'not authenticated' ||
          e === 'The user is not authenticated' ||
          e.code === 'NotAuthorizedException'
        ) {
          this.openModal(loginWrongCode);
        } else {
          this.handleLoginError(e);
        }
      } finally {
        this.loading = false;
      }
    },

    /**
     * @description Sign user in with password
     * @author Kristine de Vries
     */
    async signInWithPassword(password) {
      try {
        this.loading = true;
        const user = await Auth.signIn(this.cognitoUser.challengeParam.email, password);
        // If user is in FORCE_CHANGE_PASSWORD state
        // (happens when the trainer resets user's password),
        // automatically generate a new random string password for the user
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, this.createPassword());
        }
        // Load the current cognito and database user
        await this.initiateData();
        // Navigate to the app
        this.$router.push({ name: 'dashboard' });
      } catch (e) {
        this.handleLoginError(e, INVALID_PASSWORD);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Resend login confirmation code to user's email or phone number
     * @author Kristine de Vries
     */
    async resendLoginCode() {
      try {
        const user = await Auth.signIn(this.cognitoUser.challengeParam.email);
        this.cognitoUser = user;

        this.showError({
          title: this.$t('loginCode'),
          body: this.$t('loginCodeResent'),
          variant: 'success',
        });
      } catch (e) {
        this.handleLoginError(e);
      }
    },
    /**
     * @description Navigate between the different login flow components
     * @author Kristine de Vries
     */
    navigate(path) {
      this.currentStep = path;
    },
    /**
     * @description Handles cognito login errors
     * @param {Object} error
     * @param {String} errorType
     * @author Kristine de Vries
     */
    async handleLoginError(error, errorType) {
      const { loginWrongPassword } = this.modalTypes;

      // If user has no access to the app, because they dont have a valid event or registration
      // or a correct role - show no access alert
      if (error.message === 'No access') {
        this.showError({
          title: this.$t('error'),
          body: this.$t('noAccess'),
        });
        // Logout current cognito user, reset vuex state and navigate back to EMAIL
        await Auth.signOut();
        await this.resetState();
        this.currentStep = EMAIL;
      } else {
        switch (error.code) {
          // user with this email was not found
          case 'NotAuthorizedException':
            if (errorType === INVALID_EMAIL) {
              this.showError({
                title: this.$t('invalidEmailTitle'),
                body: this.$t('invalidEmailDescription'),
              });
            } else if (errorType === INVALID_PASSWORD) {
              this.openModal(loginWrongPassword);
            }
            break;
          case 'LimitExceededException':
            this.showError({
              title: this.$t('tooManyFailedAttempts'),
              body: this.$t('tooManyFailedAttemptsDescription'),
            });
            break;
          default:
            this.showError({});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_transitions.scss';

.z-login-container {
  width: 15rem;
}
</style>
