var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PrimaryButton',{class:[
      'd-flex',
      'flex-row',
      'justify-content-between',
      'align-items-center',
      'px-4',
      'py-2',
      'mt-4',
      'z-collapsible-btn',
      'text-left',
      [_vm.visible ? 'open' : ''],
    ],attrs:{"label":_vm.label},on:{"click":_vm.click},scopedSlots:_vm._u([{key:"rightDetail",fn:function(){return [_c('Icon',{class:_vm.visible ? 'z-rotate-in' : 'z-rotate-out',attrs:{"src":"iconChevronDown","height":"1.5rem","width":"1.5rem"}})]},proxy:true}])}),_c('b-collapse',{attrs:{"id":_vm.id,"visible":_vm.visible}},[_c('div',[_vm._t("content")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }