<template>
  <b-modal v-model="isModalOpen" :title="title" centered class="modal" @close="$emit('close')">
    <!-- Modal description -->
    <p>{{ description }}</p>

    <!-- Modal footer -->
    <template slot="modal-footer">
      <div class="w-100 d-flex justify-content-between z-footer-btn-container">
        <b-button
          v-for="item in buttons"
          :key="item.label"
          size="sm"
          variant="primary"
          class="w-100 mx-2"
          @click="item.onClick()"
        >
          {{ item.label }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
/**
 * @file
 * @description Modal alert component
 * @author Kristine de Vries
 */
export default {
  name: 'AlertModal',
  computed: {
    /**
     * @description Depending on the prop, set modal's visibility
     * @author Kristine de Vries
     */
    isModalOpen: {
      get() {
        return this.isOpen;
      },
      set(value) {
        return value;
      },
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    description: {
      type: String,
      default: '',
      required: true,
    },
    buttons: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.z-footer-btn-container {
  button {
    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
</style>
