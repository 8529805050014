<template>
  <div class="mb-7" v-html="html" />
</template>

<script>
/**
 * @file
 * @description Component for displaying Html questions
 * @author Thiago Fazzi
 */
import { questionProps } from '@/mixins/functionalMixins';
import { getTranslations } from '@/utils/functions';

export default {
  name: 'Html',
  mixins: [questionProps],
  computed: {
    html() {
      return getTranslations(this.question).html;
    },
  },
};
</script>
