import { mapActions } from 'vuex';
import { modalTypes, browsers } from '@/utils/enums';
import { showToast } from '@/utils/functions';
import eventBus from '@/utils/eventBus';

const { CHROME, FIREFOX, SAFARI, EDGE, OPERA, OTHER } = browsers;

/**
 * @description Open and close functions for modals
 * @author Kristine de Vries
 */
export const toggleModal = {
  data() {
    return {
      isModalOpen: false,
      modalType: '',
      modalTypes,
      alertModals: {
        loginCodeHelp: {
          title: 'codeNotWorking',
          description: 'loginCodeDescription',
          buttons: [
            {
              label: 'usePassword',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'navigate',
                  params: 'PASSWORD',
                },
              ],
            },
            {
              label: 'resendCode',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'resendLoginCode',
                },
              ],
            },
          ],
        },
        loginPasswordHelp: {
          title: 'help',
          description: 'passwordHelpDescription',
          descriptionParams: {
            supportEmail: this.$t('zuidemaEmail'),
            supportPhone: this.$t('zuidemaPhoneNumber'),
          },
          buttons: [
            {
              label: 'useCode',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'navigate',
                  params: 'CODE',
                },
              ],
            },
          ],
        },
        loginWrongCode: {
          title: 'tryAgain',
          description: 'invalidCode',
          descriptionParams: {
            supportEmail: this.$t('zuidemaEmail'),
            supportPhone: this.$t('zuidemaPhoneNumber'),
          },
          buttons: [
            {
              label: 'usePassword',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'navigate',
                  params: 'PASSWORD',
                },
              ],
            },
            {
              label: 'resendCode',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'resendLoginCode',
                },
              ],
            },
          ],
        },
        loginWrongPassword: {
          title: 'tryAgain',
          description: 'invalidPassword',
          descriptionParams: {
            supportEmail: this.$t('zuidemaEmail'),
            supportPhone: this.$t('zuidemaPhoneNumber'),
          },
          buttons: [
            {
              label: 'useCode',
              onClick: [
                {
                  callback: 'closeModal',
                },
                {
                  callback: 'navigate',
                  params: 'CODE',
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    /**
     * @description Render correct alert modal content depending on modalType
     * @author Kristine de Vries
     */
    alertModal() {
      const {
        title = '',
        description = '',
        descriptionParams,
        buttons = [],
      } = this.alertModals[this.modalType] || {};

      return {
        title: this.$t(title),
        description: this.$t(description, descriptionParams),
        buttons: buttons.map(({ label, onClick = [] }) => ({
          label: this.$t(label),
          onClick: () => onClick.map(({ callback, params }) => this[callback](params)),
        })),
      };
    },
  },
  methods: {
    /**
     * @description Opens the modal
     * @author Kristine de Vries
     */
    openModal(modalType = '') {
      this.modalType = modalType;
      this.isModalOpen = true;
    },
    /**
     * @description Closes the modal
     * @author Kristine de Vries
     */
    closeModal() {
      this.isModalOpen = false;
    },
  },
};

/**
 * @description Triggers a bootstrap toast/notification component. By default shows general error alert
 * @author Kristine de Vries
 */
export const showError = {
  methods: {
    /**
     * @description Shows bootstrap Toast component notification
     * @author Kristine de Vries
     */
    showError(data) {
      showToast(this, data);
    },
  },
};

/**
 * @description Returns user agent specific information about user's session
 * @author Kristine de Vries
 */
export const userAgent = {
  methods: {
    /**
     * @description Detects and returns the current browser type
     * @author Kristine de Vries
     */
    getBrowser() {
      const { userAgent: browserInfo } = navigator;

      if (browserInfo.match(/chrome|chromium|crios/i)) {
        return CHROME;
      }
      if (browserInfo.match(/firefox|fxios/i)) {
        return FIREFOX;
      }
      if (browserInfo.match(/safari/i)) {
        return SAFARI;
      }
      if (browserInfo.match(/opr\//i)) {
        return OPERA;
      }
      if (browserInfo.match(/edg/i)) {
        return EDGE;
      }
      return OTHER;
    },
    /**
     * @description Returns true if user is on a mobile device
     * @author Kristine de Vries
     */
    isMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
    },
    /**
     * @description Returns true if user is on an iOS device
     * @author Kristine de Vries
     */
    isIOS() {
      const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

      return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem));
    },
  },
};

/**
 * @file
 * @description Mixin to add input bind into components
 * @author Thiago Fazzi
 */
export const inputValue = {
  props: {
    value: {
      type: [String, Number, Object],
    },
  },
  computed: {
    /**
     * @description Apply two-way data binding into custom components
     * @author Thiago Fazzi
     */
    inputValue: {
      get() {
        return this.value || '';
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};

/**
 * @file
 * @description Mixin to add common question props into components
 * @author Thiago Fazzi
 */
export const questionProps = {
  props: {
    question: {
      type: Object,
      required: true,
      default: () => {},
    },
    editable: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
};

/**
 * @description Highlights a participant or a supporter answer in results tables
 * @author Kristine de Vries
 */
export const highlightAnswer = {
  mounted() {
    // Listen to any highlightAnswer events
    eventBus.$on('highlightAnswer', this.handleAnswerToggle);
  },
  beforeDestroy() {
    // Remove the event listener
    eventBus.$off('highlightAnswer', this.handleAnswerToggle);
  },
  methods: {
    ...mapActions(['updateItemInstantly']),
    /**
     * @description Updates answer's higlighted property
     * @author Kristine de Vries
     */
    async handleAnswerToggle(answer) {
      const { supporterId, id, highlighted, questionId } = answer;

      // If there is a firstName we are updating supporter's answers, otherwise participant's answer
      if (supporterId) {
        // Get supporter's answers
        const { answers } = this.supporters.find(supporter => supporter.id === supporterId);

        // Toggle highlighted property value for the selected question
        const updatedSupporterAnswers = Object.values(answers).map(item =>
          item.questionId === questionId ? { ...item, highlighted: !highlighted } : item,
        );

        await this.updateItemInstantly({
          name: 'supporters',
          item: { id: supporterId, answers: updatedSupporterAnswers },
          mutationName: 'updateSupporter',
          oldItem: { id: supporterId, answers },
        });
      } else {
        await this.updateItemInstantly({
          name: 'answers',
          item: { id, highlighted: !highlighted },
          mutationName: 'updateAnswer',
          oldItem: { id, highlighted },
        });
      }
    },
  },
};
