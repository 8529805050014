<template>
  <object
    :is="require(`@/assets/img/${src}.svg`)"
    class="z-icon"
    :style="{
      '--icon-width': width,
      '--icon-height': height,
    }"
  />
</template>

<script>
/**
 * @file
 * @description Icon component
 * @author Kristine de Vries
 */
export default {
  name: 'Icon',
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    height: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto',
    },
  },
};
</script>

<style lang="scss" scoped>
.z-icon {
  flex-shrink: 0;
  width: var(--icon-width);
  height: var(--icon-height);
}
</style>
