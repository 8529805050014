<template>
  <div>
    <!-- Active state toggle -->
    <PrimaryButton
      :label="label"
      :class="[
        'd-flex',
        'flex-row',
        'justify-content-between',
        'align-items-center',
        'px-4',
        'py-2',
        'mt-4',
        'z-collapsible-btn',
        'text-left',
        [visible ? 'open' : ''],
      ]"
      @click="click"
    >
      <template #rightDetail>
        <Icon
          src="iconChevronDown"
          height="1.5rem"
          width="1.5rem"
          :class="visible ? 'z-rotate-in' : 'z-rotate-out'"
        />
      </template>
    </PrimaryButton>

    <!-- Collapsed content slot -->
    <b-collapse :id="id" :visible="visible">
      <!-- Wrap the collapsed content in a <div> because of https://github.com/twbs/bootstrap/issues/12093 -->
      <div>
        <slot name="content" />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import PrimaryButton from '@/components/PrimaryButton';
import Icon from '@/components/Icon';

/**
 * @file
 * @description Collapsible button component
 * @author Kristine de Vries
 */
export default {
  name: 'CollapsibleButton',
  components: {
    PrimaryButton,
    Icon,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: true,
      default: '',
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    /**
     * @description Emit click event
     * @author Kristine de Vries
     */
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_transitions.scss';
</style>
