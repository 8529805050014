<template>
  <div>
    <!-- Header -->
    <Header
      :title="$t('dashboardHeaderTitle', { firstName })"
      :subTitle="$t('dashboardHeaderSubtitle')"
    />
    <b-container class="col-10 col-lg-8 pb-8 px-0 flex-grow-1">
      <!-- Loading indicator -->
      <Loading v-if="loading" />

      <!-- Dashboard cards -->
      <masonry v-else :cols="{ default: 2, 540: 1 }" gutter="1rem">
        <HubCard v-for="card in cards" :key="card.id" :item="card" @click="navigateToItem" />
      </masonry>
    </b-container>
  </div>
</template>

<script>
/**
 * @file
 * @description Dashboard screen
 * @author Kristine de Vries
 */
import { mapGetters } from 'vuex';

import Loading from '@/components/Loading';
import { formatDate, sortArray } from '@/utils/functions';
import {
  cardStatusLabels,
  formTypes,
  supportedFormTypes,
  resultsModuleTypes,
  BASE_DATE_FORMAT,
  dashboardCardOrder,
} from '@/utils/enums';

import HubCard from './components/HubCard';
import Header from './components/Header';

const { ISQ, ISI, QUESTIONNAIRE } = formTypes;
const { ISQISI_RESULTS, QUESTIONNAIRE_RESULTS } = resultsModuleTypes;

const {
  LOCKED: { id: LOCKED },
} = cardStatusLabels;

export default {
  name: 'Dahsboard',
  components: {
    Header,
    HubCard,
    Loading,
  },
  data() {
    return {
      firstName: '',
    };
  },
  computed: {
    ...mapGetters(['getStateItems', 'getFormsWithStatus']),
    /**
     * @description Get supporters
     * @author Kristine de Vries
     */
    supporters() {
      return this.getStateItems('supporters') || {};
    },
    /**
     * @description Get forms
     * @author Kristine de Vries
     */
    forms() {
      return this.getFormsWithStatus() || [];
    },
    /**
     * @description Get dashboard cards
     * @author Kristine de Vries
     */
    cards() {
      const forms = this.getFormsWithStatus() || [];
      const formCards = forms.filter(({ type }) => supportedFormTypes.includes(type));
      const resultsModules = this.getResultsModules();

      return this.getSortedCards({ cards: [...formCards, ...resultsModules] });
    },
    /**
     * @description Get loading state
     * @author Kristine de Vries
     */
    loading() {
      return this.getStateItems('loading', false);
    },
  },
  created() {
    const { firstName } = this.getStateItems('user');
    this.firstName = firstName;
  },
  methods: {
    /**
     * @description Returns the date of the last answer created in the ISQ form
     * @author Kristine de Vries
     */
    getISQFinishedDate({ ISQISIForm }) {
      const questions = this.getStateItems('questions');
      const answers = this.getStateItems('answers');

      // If ISQ/ISI form is not found return null
      if (!ISQISIForm) return null;

      const { id, questions: ISQquestions } = ISQISIForm;

      // Find last question in ISQ form
      const lastQuestionOrderNumber = ISQquestions.length - 1;
      const lastQuestion = Object.values(questions).find(
        item => item.order === lastQuestionOrderNumber && item.formId === id,
      );

      // If last question is not found return null
      if (!lastQuestion) return null;

      // Find last question's answer
      const { id: questionId } = lastQuestion;
      const answer = Object.values(answers).find(item => item.questionId === questionId);

      // If answer found return formated date string, otherwise return null
      return answer ? formatDate(answer.createdAt, BASE_DATE_FORMAT) : null;
    },
    /**
     * @description Returns the earliest date that the form was finished
     * or null if the form has not been finished.
     * @author Milo Silva
     * @author Kristine de Vries
     */
    getQuestionnaireFinishedDate() {
      const questionnaireFormId = Object.values(this.forms).find(
        ({ type }) => type === QUESTIONNAIRE,
      )?.id;

      // Find all supporters who have completed the form
      const finishedSupporters = Object.values(this.supporters).filter(
        ({ finishedForms }) => !!finishedForms[questionnaireFormId],
      );

      if (!questionnaireFormId || !finishedSupporters.length) {
        return null;
      }

      // Find earliest date a supporter completed the form
      const earliestFinishDate = finishedSupporters.reduce((acc, current) => {
        const { finishedForms } = current;
        const { finishedAt } = finishedForms[questionnaireFormId];
        const finishDate = new Date(finishedAt);

        return finishDate < acc ? finishDate : acc;
      }, new Date());

      return formatDate(earliestFinishDate, BASE_DATE_FORMAT);
    },

    /**
     * @description Adds results modules to the cards list if the specific form type is in the event.
     * @author Milo Silva
     * @author Kristine de Vries
     */
    getResultsModules() {
      const ISQISIForm = Object.values(this.forms).find(
        item => item.type === ISQ || item.type === ISI,
      );
      const ISQISIFinishedDate = this.getISQFinishedDate({ ISQISIForm });
      const hasQuestionnaireForm = this.forms.some(form => form.type === QUESTIONNAIRE);
      const questionnaireFinishedDate = this.getQuestionnaireFinishedDate();

      const resultsModules = [];

      if (ISQISIForm) {
        const { type } = ISQISIForm;
        resultsModules.push({
          id: ISQISI_RESULTS,
          type: ISQISI_RESULTS,
          formType: type,
          titleOverview: type === ISQ ? this.$t('ISQResultsTitle') : this.$t('ISIResultsTitle'),
          subtitle: this.$t('ISQResultsSubtitle'),
          status: !ISQISIFinishedDate ? LOCKED : null,
          unlockDate: ISQISIFinishedDate,
        });
      }
      if (hasQuestionnaireForm) {
        resultsModules.push({
          id: QUESTIONNAIRE_RESULTS,
          type: QUESTIONNAIRE_RESULTS,
          titleOverview: this.$t('questionnaireResultsTitle'),
          subtitle: this.$t('questionnaireResultsSubtitle'),
          status: !questionnaireFinishedDate ? LOCKED : null,
          unlockDate: questionnaireFinishedDate,
        });
      }

      return resultsModules;
    },
    /**
     * @description Sort cards by pre-defined order estabilished in the enums
     * @author Kristine de Vries
     * @author Miguel Aguiar
     */
    getSortedCards({ cards }) {
      const cardsToSort = cards.map(card => ({
        ...card,
        cardOrder: dashboardCardOrder[card.type],
      }));
      return sortArray(cardsToSort, 'cardOrder');
    },
    /**
     * @description Navigates to form screen or ISQ/ISI results screen
     * @author Kristine de Vries
     */
    navigateToItem({ id, formType }) {
      if (id === ISQISI_RESULTS) {
        this.$router.push({ name: 'ISQISIResults', params: { type: formType } });
      } else if (id === QUESTIONNAIRE_RESULTS) {
        this.$router.push({ name: 'QuestionnaireResults' });
      } else {
        this.$router.push({ name: 'form', params: { id } });
      }
    },
  },
};
</script>
