<template>
  <div>
    <!-- Email input -->
    <b-form-input
      v-model="email"
      :placeholder="$t('emailAddress')"
      type="email"
      class="mb-4 z-email-input"
      @keyup.enter.native="submit"
    />

    <!-- Submit button -->
    <PrimaryButton
      :label="$t('login')"
      :loading="loading"
      :disabled="!isEmailValid"
      class="mb-4"
      @click="submit"
    />

    <!-- Help description -->
    <p class="text-light text-center mt-3 mb-0 z-help-description">
      {{
        $t('emailHelpDescription', {
          supportEmail: $t('zuidemaEmail'),
          supportPhone: $t('zuidemaPhoneNumber'),
        })
      }}
    </p>
  </div>
</template>

<script>
/**
 * @file
 * @description Email step component of the login flow
 * @author Kristine de Vries
 */
import PrimaryButton from '@/components/PrimaryButton';

export default {
  name: 'Email',
  components: {
    PrimaryButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    /**
     * @description Checks if an email address is valid
     * @param {string} email
     * @author Kristine de Vries
     */
    isEmailValid() {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(this.email);
    },
  },
  methods: {
    /**
     * @description Submit user's email input
     * @author Kristine de Vries
     */
    submit() {
      this.$emit('submit', this.email);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-email-input {
  margin-top: $spacer * 2.1;
}

.z-help-description {
  font-size: $font-size-sm;
  font-family: $font-family-display;
  font-weight: $font-weight-regular;
  white-space: pre-line;
}
</style>
