import { userRoles, facilitatorTemplates, cardStatusLabels } from '@/utils/enums';
import {
  getFacilitatorQuestions,
  getTranslations,
  sortArray,
  replaceTemplates,
  getFormStatus,
  getFormAvailableDate,
} from '@/utils/functions';

const {
  LOCKED: { id: LOCKED },
} = cardStatusLabels;

export default {
  /**
   * @description Returns current event
   * @author Kristine de Vries
   */
  getCurrentEvent: state => () => state?.events?.[state?.currentEventId] || {},
  /**
   * @description Returns state items depending on specified type, e.g. 'forms'
   * If a fallback value is defined and the item does not exist, return the fallback value.
   * Otherwise fall back to an empty object
   * @author Kristine de Vries
   */
  getStateItems: state => (type, fallbackValue) =>
    state?.[type] || (fallbackValue !== undefined ? fallbackValue : {}),
  /**
   * @description Returns forms with status labels and availability
   * @author Kristine de Vries
   */
  getFormsWithStatus: state => () => {
    const {
      currentEventId: eventId,
      answers,
      forms,
      user: { finishedForms },
    } = state;

    const finishedFormIds = Object.values(finishedForms)
      .map(item => (item && item.eventId === eventId ? item.formId : null))
      .filter(formId => formId !== null);

    return Object.values(forms).map(form => {
      const status = getFormStatus({ form, finishedFormIds, answers });
      const { unlockDate } = form;
      return {
        ...form,
        ...getTranslations(form),
        status,
        availableFrom: status === LOCKED && unlockDate ? getFormAvailableDate(unlockDate) : null,
      };
    });
  },

  /**
   * @description Returns selected form's questions in app's language with answers and facilitator questions
   * (questions with facilitator template names like {trainerName} or {actorName})
   * @param {string} formId
   * @author Kristine de Vries
   * @author Thiago Fazzi
   */
  getFormQuestions: state => formId => {
    const { forms, questions, answers, events, currentEventId, user: participant } = state;

    const form = forms[formId] || {};
    const currentEvent = events[currentEventId] || {};

    const { TRAINER, ACTOR } = userRoles;
    const { TRAINER_NAME, ACTOR_NAME } = facilitatorTemplates;

    const trainers = currentEvent.users.items
      .map(item => item.user)
      .filter(user => user.role === TRAINER);

    const actors = currentEvent.users.items
      .map(item => item.user)
      .filter(user => user.role === ACTOR);

    // Get selected form questions and answers
    const questionsWithTranslationsAndAnswers = form.questions
      .map(questionId => questions[questionId])
      .map(question => ({
        ...question,
        ...getTranslations(question),
        answers: Object.values(answers).filter(item => item.questionId === question.id),
      }));

    return sortArray(questionsWithTranslationsAndAnswers)
      .map(question => replaceTemplates(question, participant, currentEvent))
      .map((item, index) => {
        // Current item is a TRAINER_NAME facilitator type question
        if (item.title?.includes(TRAINER_NAME)) {
          const nextQuestion = questionsWithTranslationsAndAnswers[index + 1];
          const previousQuestion = questionsWithTranslationsAndAnswers[index - 1];
          // If previous question already includes the facilitator template, the current item
          // will already be grouped together with the previous question,
          // so return null, which is later filtered out
          if (previousQuestion && previousQuestion.title.includes(TRAINER_NAME)) {
            return null;
          }
          return getFacilitatorQuestions({
            questionItem: item,
            nextQuestionItem:
              nextQuestion && nextQuestion.title.includes(TRAINER_NAME) ? nextQuestion : null,
            users: trainers,
            template: TRAINER_NAME,
          });
        }

        // Current item is a ACTOR_NAME facilitator type question
        if (item.title?.includes(ACTOR_NAME)) {
          const nextQuestion = questionsWithTranslationsAndAnswers[index + 1];
          const previousQuestion = questionsWithTranslationsAndAnswers[index - 1];

          // If previous question already includes the facilitator template, the current item
          // will already be grouped together with the previous question,
          // so return null, which is later filtered out
          if (previousQuestion && previousQuestion.title.includes(ACTOR_NAME)) {
            return null;
          }
          return getFacilitatorQuestions({
            questionItem: item,
            nextQuestionItem:
              nextQuestion && nextQuestion.title.includes(ACTOR_NAME) ? nextQuestion : null,
            users: actors,
            template: ACTOR_NAME,
          });
        }
        // Else just return the question
        return item;
      })
      .filter(question => question !== null)
      .flat()
      .map((item, index) => ({ ...item, order: index }));
  },
};
