import Vue from 'vue';
import Router from 'vue-router';

import Login from '@/views/login/Login';
import Dashboard from '@/views/dashboard/Dashboard';
import Form from '@/views/form/Form';
import ISQISIResults from '@/views/ISQISIResults/ISQISIResults';
import QuestionnaireResults from '@/views/questionnaireResults/QuestionnaireResults';
import store from '@/store/store';
import { setLanguage, resetApp } from '@/utils/functions';

import RouterBase from './RouterBase';
import vm from '../main';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login',
      components: { default: RouterBase },
      children: [{ path: '/login', name: 'login', component: Login }],
    },
    {
      path: '/dashboard',
      components: { default: RouterBase },
      meta: { requiresAuth: true },
      children: [
        { path: '', name: 'dashboard', component: Dashboard, meta: { displayFooter: true } },
        { path: 'form/:id', name: 'form', component: Form, meta: { displayFooter: false } },
        {
          path: ':type/results',
          name: 'ISQISIResults',
          component: ISQISIResults,
          meta: { displayFooter: true },
        },
        {
          path: 'questionnaireResults',
          name: 'QuestionnaireResults',
          component: QuestionnaireResults,
          meta: { displayFooter: true },
        },
      ],
    },
  ],
  mode: 'history',
});

/**
 * @description Before each route that requires authentication - check if there is an authenticated
 * cognito user with correct access permissions. If not - reset the app and navigate to login route.
 * @author Kristine de Vries
 */
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  try {
    // If page is refreshed, fetch the latest data and check user's language and access
    if (!from.name) {
      await store.dispatch('initiateData');

      const { archived, preferredLanguage } = store.getters.getStateItems('user');
      // Trainers do not have a preferred language, for them set the app to event's language by default
      const { language } = store.getters.getCurrentEvent();

      // Set user's preferredLanguage
      setLanguage(vm, preferredLanguage || language);

      if (archived === 'true' && requiresAuth) {
        await resetApp();
        return next({ name: 'login' });
      }

      if (!requiresAuth) return next({ name: 'dashboard' });
    }

    return next();
  } catch (e) {
    // If user no longer has access to the app, because their event/registrations statusGo has changed to false
    // show no access alert and reset the app state
    if (e.message === 'No access') {
      vm.$bvToast.toast(vm.$t('noAccess'), {
        title: vm.$t('error'),
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000,
      });
      // Logout current cognito user and reset vuex state
      await resetApp();
    }
    return requiresAuth ? next({ name: 'login' }) : next();
  }
});

export default router;
