<template>
  <footer class="bg-secondary text-light text-center mt-3 px-3 py-8 w-100 z-footer">
    {{ $t('questions') }}<br />
    <!-- Phone number -->
    <span class="z-footer-line">
      {{ $t('call') }}:
      <a class="z-footer-link" :href="`tel:${$t('zuidemaPhoneNumber')}`">
        <u>{{ $t('zuidemaPhoneNumber') }} </u>
      </a>
      |&nbsp;
    </span>
    <!-- Email address -->
    <span class="z-footer-line">
      {{ $t('mail') }}:
      <a class="z-footer-link" :href="`mailto:${$t('zuidemaEmail')}`">
        <u>{{ $t('zuidemaEmail') }}</u>
      </a>
      |&nbsp;
    </span>
    <!-- Website url -->
    <span class="z-footer-line">
      <a class="z-footer-link" href="https://zuidema.nl" target="_blank">
        <u>{{ $t('zuidemaUrlNL') }}</u>
      </a>
    </span>
  </footer>
</template>

<script>
/**
 * @file
 * @description Footer component
 * @author Miguel Aguiar
 */
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">
@import '@styles/base.scss';

footer {
  bottom: 0;
}

// Custom styling for the footer
.z-footer {
  font-size: $font-size-sm;
}

.z-footer-line {
  display: inline-block;
}

.z-footer-link {
  color: $light;
  @include hover() {
    color: darken($light, 15%);
  }
}
</style>
