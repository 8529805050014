<template>
  <div class="d-flex flex-column w-100">
    <BaseHeader
      :title="$t('questionnaireResultsTitle')"
      :goBackLabel="$t('dashboard')"
      class="sticky-top"
    />

    <b-container class="align-self-center col-10 col-lg-8 p-8 pt-9 mx-0">
      <b-row>
        <!-- Title -->
        <h3 class="mb-8 z-title">
          {{ $t('questionnaireResultsTitle') }}
        </h3>
      </b-row>

      <!-- Graph -->
      <b-row>
        <Graph :data="data.graphData" />
      </b-row>

      <!-- Table description -->
      <b-row>
        <p class="mb-0 mt-8" v-html="$t('questionnaireResultsDescription')" />
      </b-row>

      <!-- Table List -->
      <b-row>
        <TableList :data="data.tableData" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mean } from 'lodash';

import BaseHeader from '@/components/layout/BaseHeader';
import { sortArray, formatName, getTranslations, replaceTemplates } from '@/utils/functions';
import { questionTypes, formTypes, MAX_QUESTIONNAIRE_SUPPORTERS } from '@/utils/enums';
import { highlightAnswer } from '@/mixins/functionalMixins';

import Graph from './components/Graph';
import TableList from './components/TableList';

const { QUESTIONNAIRE, QUESTIONNAIRE_FEEDBACK } = formTypes;
const {
  OPEN: { id: OPEN },
  RATING: { id: RATING },
} = questionTypes;

/**
 * @file
 * @description 360 Questionnaire results screen
 * @author Kristine de Vries
 */
export default {
  name: 'QuestionnaireResults',
  components: {
    BaseHeader,
    TableList,
    Graph,
  },
  mixins: [highlightAnswer],
  data() {
    return {
      forms: [],
      answers: [],
      questions: {},
    };
  },
  computed: {
    ...mapGetters(['getStateItems']),
    /**
     * @description Get supporters from global state, filter for non-questionnaire supporters
     * @author Kristine de Vries
     */
    supporters() {
      return Object.values(this.getStateItems('supporters')).filter(
        ({ isQuestionnaire }) => isQuestionnaire,
      );
    },
    /**
     * @description Get questionnaire results data
     * @author Kristine de Vries
     */
    data() {
      return this.getQuestionnaireResultsData();
    },
  },
  created() {
    // Get data from state
    this.forms = Object.values(this.getStateItems('forms'));
    this.questions = this.getStateItems('questions');
    this.answers = Object.values(this.getStateItems('answers'));
  },
  methods: {
    /**
     * @description Get questionnaire results table data
     * @author Milo Silva
     * @author Kristine de Vries
     */
    getQuestionnaireResultsData() {
      // Get questionnaire form
      const questionnaireForm = this.forms.find(item => item.type === QUESTIONNAIRE);
      const { id: questionnaireFormId, anonymiseSupporters } = questionnaireForm;

      const questionnaireFeedbackForm =
        this.forms.find(({ type }) => type === QUESTIONNAIRE_FEEDBACK) || {};
      const { id: questionnaireFeedbackFormId } = questionnaireFeedbackForm;

      const events = this.getStateItems('events');
      const currentEventId = this.getStateItems('currentEventId');
      const currentEvent = events[currentEventId] || {};
      const participant = this.getStateItems('user');

      // Get questionnaire questions
      const questionnaireQuestions = sortArray(
        questionnaireForm.questions
          .map(questionId => this.questions[questionId])
          .map(question => ({ ...question, ...getTranslations(question) }))
          .map(question => replaceTemplates(question, participant, currentEvent)),
        'order',
      );

      // Get questionnaire feedback questions
      const questionnaireFeedbackQuestions = sortArray(
        questionnaireFeedbackForm.questions.map(questionId => this.questions[questionId]),
        'order',
      );

      // Filter for supporters who have finished 360 Questionnaire
      const finishedSupporters = sortArray(this.supporters, 'createdAt').filter(
        ({ finishedForms }) => Object.keys(finishedForms).includes(questionnaireFormId),
      );

      // If there are also answers in QUESTIONNAIRE_FEEDBACK form set hasFeedbackData to true
      const hasFeedbackData = this.supporters.some(supporter =>
        Object.values(supporter.answers).some(
          answerItem => answerItem.formId === questionnaireFeedbackFormId,
        ),
      );

      // Create an empty array to sum supporter scores
      const supporterTotalScores = hasFeedbackData
        ? Array(MAX_QUESTIONNAIRE_SUPPORTERS)
            .fill(null)
            .map(() => [{ isTotalScore: true }, { isTotalScore: true }])
        : [...finishedSupporters].fill({ value: 0 });

      // If anonymiseSupporters is true, replace supporter names with 's1', 's2', etc
      // Otherwise use the initials for each supporter
      const supporterInitials = finishedSupporters.map((supporter, index) => ({
        value: anonymiseSupporters
          ? `S${index + 1}`
          : supporter.firstName[0] + supporter.lastName[0],
      }));

      // I we have 2nd measurement form answers populate data for the max number of allowed supporters
      const supporterInitialsWithFeedback = Array(MAX_QUESTIONNAIRE_SUPPORTERS)
        .fill({})
        .map((item, index) => supporterInitials[index] || item);

      /**
       * Format closed questions into an array of table rows
       * Each row is made up of individual cells
       * Use 'value' property to render a raw value
       */
      const closedQuestionRows = questionnaireQuestions
        .filter(question => question.type === RATING)
        .reduce((acc, currentQuestion) => {
          const { id: questionId, order, type, questionSupporter: title } = currentQuestion;

          // Get supporter answers to the question
          const supporterAnswers = finishedSupporters.map((supporter, index) => {
            const { id: supporterId, answers } = supporter;

            const { scoreAnswer, highlighted } =
              Object.values(answers).find(
                answerItem =>
                  answerItem.questionId === questionId && answerItem.formId === questionnaireFormId,
              ) || {};

            // Get the matching answers to the same question from QUESTIONNAIRE_FEEDBACK form
            const {
              scoreAnswer: feedbackScoreAnswer,
              highlighted: feedbackHighlighted,
              questionId: feedbackQuestionId,
            } = Object.values(answers).find(answerItem => {
              // Get the matching question from questionnaire 2nd measurement form based on question's order,
              // as an extra check - make sure that type is the same.
              // As discussed with Zuidema, it is currently their responsibility
              // to ensure the questions in both QUESTIONNAIRE and QUESTIONNAIRE_FEEDBACK forms match
              const questionnaireFeedbackQuestionId = questionnaireFeedbackQuestions.find(
                question => question.order === order && question.type === type,
              )?.id;

              return (
                answerItem.questionId === questionnaireFeedbackQuestionId &&
                answerItem.formId === questionnaireFeedbackFormId
              );
            }) || {};

            // If score is valid, add to total score for supporter
            if (!hasFeedbackData && !Number.isNaN(scoreAnswer)) {
              supporterTotalScores[index] = {
                // eslint-disable-next-line no-unsafe-optional-chaining
                value: supporterTotalScores[index]?.value + parseInt(scoreAnswer, 10),
              };
            }

            // If there is also a 2nd measurement/feedback score,
            // add that to the totals row as well
            if (hasFeedbackData) {
              if (scoreAnswer && !Number.isNaN(scoreAnswer)) {
                supporterTotalScores[index][0] = {
                  value: (supporterTotalScores[index][0]?.value || 0) + parseInt(scoreAnswer, 10),
                  isTotalScore: true,
                };
              }

              if (feedbackScoreAnswer && !Number.isNaN(feedbackScoreAnswer)) {
                supporterTotalScores[index][1] = {
                  value:
                    (supporterTotalScores[index][1]?.value || 0) +
                    parseInt(feedbackScoreAnswer, 10),
                  isTotalScore: true,
                };
              }
            }

            const questionnaireAnswer = {
              formId: questionnaireFormId,
              questionId,
              value: scoreAnswer,
              supporterId,
              highlighted,
              isAnswer: true,
            };

            return hasFeedbackData
              ? [
                  questionnaireAnswer,
                  {
                    formId: questionnaireFeedbackFormId,
                    questionId: feedbackQuestionId,
                    value: feedbackScoreAnswer,
                    supporterId,
                    highlighted: feedbackHighlighted,
                    isAnswer: true,
                  },
                ]
              : questionnaireAnswer;
          });

          // When we have 2nd measurement form populate answer data for the max number of allowed supporters
          const supporterAnswersWithFeedback = Array(MAX_QUESTIONNAIRE_SUPPORTERS)
            .fill([{ isAnswer: true }, { isAnswer: true }])
            .map((item, index) => supporterAnswers[index] || item)
            .flat();

          const questionTitle = {
            value: `${order + 1}. ${title}`,
          };

          // Concatenate question title and supporter answers into a single row
          const questionRows = hasFeedbackData
            ? [[questionTitle], supporterInitialsWithFeedback, supporterAnswersWithFeedback]
            : [questionTitle, ...supporterAnswers];
          return [...acc, questionRows];
        }, []);

      // Add supporter initials to the header row
      const headerRow = hasFeedbackData
        ? null
        : [{ value: this.$t('question') }, ...supporterInitials];

      // Create a row for total scores of each supporter

      const totalScoresRows = hasFeedbackData
        ? [
            [{ value: `${this.$t('total')}:` }],
            supporterInitialsWithFeedback,
            supporterTotalScores.flat(),
          ]
        : [{ value: `${this.$t('total')}:` }, ...supporterTotalScores];

      // Add header and total scores rows to the table
      const closedQuestionTable = hasFeedbackData
        ? [...closedQuestionRows.flat(), ...totalScoresRows]
        : [...closedQuestionRows, totalScoresRows];

      const closedQuestionsData = {
        type: RATING,
        key: RATING,
        data: hasFeedbackData ? closedQuestionTable : [headerRow, ...closedQuestionTable],
      };

      // Format open questions into an array of open question tables
      const openQuestionsData = questionnaireQuestions
        .filter(question => question.type === OPEN)
        .reduce((acc, currentQuestion) => {
          const { id: questionId, order, type, questionSupporter: title } = currentQuestion;

          const supporterAnswers = finishedSupporters.map((supporter, index) => {
            const { firstName, prefix, lastName, answers } = supporter;

            const { textAnswer } =
              Object.values(answers).find(
                answerItem =>
                  answerItem.questionId === questionId && answerItem.formId === questionnaireFormId,
              ) || {};

            // Get the matching answers to the same question from QUESTIONNAIRE_FEEDBACK form
            const { textAnswer: feedbackTextAnswer } =
              Object.values(answers).find(answerItem => {
                // Get the matching question from questionnaire 2nd measurement form based on question's order,
                // as an extra check - make sure that type is the same.
                // As discussed with Zuidema, it is currently their responsibility
                // to ensure the questions in both QUESTIONNAIRE and QUESTIONNAIRE_FEEDBACK forms match
                const questionnaireFeedbackQuestionId =
                  questionnaireFeedbackQuestions.find(
                    question => question.order === order && question.type === type,
                  )?.id || {};

                return (
                  answerItem.questionId === questionnaireFeedbackQuestionId &&
                  answerItem.formId === questionnaireFeedbackFormId
                );
              }) || {};

            // If anonymiseSupporters is true, replace supporter names with 'supporter 1', 'supporter 2', etc
            const supporterName = anonymiseSupporters
              ? this.$t('supporter', { order: index + 1 })
              : formatName(firstName, prefix, lastName);

            return hasFeedbackData
              ? [[{ value: supporterName }], [{ value: textAnswer }, { value: feedbackTextAnswer }]]
              : [supporterName, textAnswer].map(value => ({ value }));
          });

          const tableData = {
            title,
            key: questionId,
            type: OPEN,
            data: hasFeedbackData ? supporterAnswers.flat() : supporterAnswers,
          };

          return [...acc, tableData];
        }, []);

      // Combine all data into list of open & closed question table sections
      const tableData = {
        sections: [closedQuestionsData, ...openQuestionsData],
        supporterCount: finishedSupporters.length,
        hasFeedbackData,
      };

      // Calculate mean score for each question
      const graphData = questionnaireQuestions
        .filter(question => question.type === RATING)
        .map(question => {
          const { id: questionId, order, type, questionSupporter } = question;

          // Combine all supporter scores for current question into an array
          const scores = finishedSupporters.map(
            supporter => {
              const { answers } = supporter;
              // Find QUESTIONNAIRE answers
              const { scoreAnswer } =
                Object.values(answers).find(
                  answerItem =>
                    answerItem.questionId === questionId &&
                    answerItem.formId === questionnaireFormId,
                ) || {};
              // Find QUESTIONNAIRE_FEEDBACK answers
              const { scoreAnswer: feedbackScoreAnswer } =
                Object.values(answers).find(answerItem => {
                  // Get the matching question from questionnaire 2nd measurement form based on question's order,
                  // as an extra check - make sure that type is the same.
                  // As discussed with Zuidema, it is currently their responsibility
                  // to ensure the questions in both QUESTIONNAIRE and QUESTIONNAIRE_FEEDBACK forms match
                  const questionnaireFeedbackQuestionId = questionnaireFeedbackQuestions.find(
                    item => item.order === order && item.type === type,
                  )?.id;

                  return (
                    answerItem.questionId === questionnaireFeedbackQuestionId &&
                    answerItem.formId === questionnaireFeedbackFormId
                  );
                }) || {};

              return { measurement1: scoreAnswer, measurement2: feedbackScoreAnswer };
            },
            { measurement1: 0, measurement2: 0 },
          );

          const meanScoreQuestionnaire = mean(
            scores
              .map(({ measurement1 }) => measurement1)
              .filter(score => score && !Number.isNaN(score)),
          ).toFixed(2);

          const meanScoreQuestionnaireFeedback = mean(
            scores
              .map(({ measurement2 }) => measurement2)
              .filter(score => score && !Number.isNaN(score)),
          ).toFixed(2);

          const questionnaireGraphData = {
            // Prefix question number with Q so questions aren't interpreted as sequential values (in case there are gaps)
            question: `Q${order + 1}`,
            title: questionSupporter,
            y1: meanScoreQuestionnaire,
          };

          return hasFeedbackData
            ? { ...questionnaireGraphData, y2: meanScoreQuestionnaireFeedback }
            : questionnaireGraphData;
        });

      return { tableData, graphData };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';
</style>
