<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex justify-content-between z-code-input-container">
      <form v-for="(v, index) in values" class="z-code-input" :key="`${id}-${index}`">
        <input
          class="border-0"
          autocomplete="off"
          :type="type === 'number' ? 'tel' : type"
          :pattern="type === 'number' ? '[0-9]' : null"
          :key="`${id}-${index}`"
          :data-id="index"
          :value="v"
          :ref="iRefs[index]"
          @input="onValueChange"
          @focus="onFocus"
          @keydown="onKeyDown"
          :disabled="disabled"
          :required="required"
          maxlength="1"
        />
      </form>
    </div>
  </div>
</template>

<script>
const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  enter: 13,
};

/**
 * @description Code input component based on vue-verification-code-input
 * @link https://github.com/suweya/vue-verification-code-input/blob/master/src/components/CodeInput.vue
 * @author Kristine de Vries
 */
export default {
  name: 'CodeInput',
  props: {
    type: {
      type: String,
      default: 'number',
    },
    className: String,
    fields: {
      type: Number,
      default: 6,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { fields, values } = this;
    let vals;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields; i += 1) {
        vals.push(values[i] || '');
      }
    } else {
      vals = Array(fields).fill('');
    }
    this.iRefs = [];
    for (let i = 0; i < fields; i += 1) {
      this.iRefs.push(`input_${i}`);
    }
    this.id = +new Date();
    return { values: vals };
  },
  mounted() {
    if (this.$refs.input_0[0]) {
      this.$refs.input_0[0].focus();
    }
  },
  methods: {
    onFocus(e) {
      e.target.select(e);
    },
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id, 10);
      const { type, fields } = this;
      if (type === 'number') {
        e.target.value = e.target.value.replace(/[^\d]/gi, '');
      }
      if (e.target.value === '' || (type === 'number' && !e.target.validity.valid)) {
        return;
      }
      let next;
      const { value } = e.target;
      let { values } = this;
      values = Object.assign([], values);
      if (value.length > 1) {
        let nextIndex = value.length + (index - 1);
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        next = this.iRefs[nextIndex];
        const split = value.split('');
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            values[cursor] = item;
          }
        });
        this.values = values;
      } else {
        next = this.iRefs[index + 1];
        values[index] = value;
        this.values = values;
      }
      if (next) {
        const element = this.$refs[next][0];
        element.focus();
        element.select();
      }
      this.triggerChange(values);
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id, 10);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.iRefs[prevIndex];
      const next = this.iRefs[nextIndex];
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = '';
            this.values = vals;
            this.triggerChange(vals);
          } else if (prev) {
            vals[prevIndex] = '';
            this.$refs[prev][0].focus();
            this.values = vals;
            this.triggerChange(vals);
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            this.$refs[prev][0].focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            this.$refs[next][0].focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        // When all fields are filled and user presses enter, emit the submit action
        case KEY_CODE.enter:
          e.preventDefault();
          if (this.values.filter(val => val !== '').length === this.fields) {
            this.$emit('submit');
          }
          break;
        default:
          break;
      }
    },
    triggerChange(values = this.values) {
      const { fields } = this;
      const val = values.join('');
      this.$emit('change', val);
      if (val.length >= fields) {
        this.$emit('complete', val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-code-input-container {
  width: 15rem;
}

.z-code-input {
  input {
    border: none;
    border-radius: $border-radius-sm;
    height: 2.75rem;
    width: 1.875rem;
    background-color: $secondary;
    text-align: center;
    color: $black;
    font-family: $font-family-mono;
    font-weight: $font-weight-regular;
    font-size: $font-size-lg;

    &:focus {
      outline: none;
      caret-color: $light;
    }
  }
}
</style>
