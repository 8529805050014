<template>
  <div class="px-0 w-100 z-isqisi-graph-container">
    <!-- Influence styles row -->
    <div class="d-flex flex-row bg-secondary z-influence-styles-wrapper">
      <div
        v-for="{ label } in influenceStyles"
        :key="label"
        class="d-flex align-items-center justify-content-center text-center z-item-border z-influence-style-container"
        :style="`width: ${graphSections[label].length * 8.34}%`"
      >
        <p class="z-title mb-0">{{ $t(label) }}</p>
      </div>
    </div>
    <!-- Behaviour type numbers row -->
    <div v-if="isISQ" class="d-flex flex-row z-behaviour-types-wrapper">
      <div
        v-for="{ ISQGraphValue } in behaviourTypes"
        :key="ISQGraphValue"
        class="d-flex align-items-center justify-content-center text-center z-item-border z-behaviour-type-number-container"
      >
        <p class="mb-0">{{ ISQGraphValue }}</p>
      </div>
    </div>
    <!-- Behaviour types row -->
    <div v-if="isISQ" class="d-flex flex-row z-behaviour-types-wrapper">
      <div
        v-for="{ label, ISQGraphValue } in behaviourTypes"
        :key="ISQGraphValue"
        class="d-flex align-items-center justify-content-center text-center z-item-border z-behaviour-type-container"
      >
        <p class="mb-0">{{ $t(label) }}</p>
      </div>
    </div>
    <!-- Graph -->
    <canvas ref="ISQISIGraph" class="z-isqisi-graph-canvas" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { formatName, sortArray } from '@/utils/functions';
import {
  ISQISIGraphDataGroups,
  behaviourTypes,
  influenceStylesForGraph,
  graphSections,
} from '@/utils/enums';
import colors from '@/assets/styles/partials/_export.module.scss';

const { black, shadowGrey, light } = colors;

/**
 * @file
 * @description ISQ/ISI Graph component
 * @author Kristine de Vries
 */
export default {
  name: 'Graph',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    isISQ: {
      type: Boolean,
      required: true,
      default: false,
    },
    activeFilters: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chart: {},
      graphSections,
      influenceStyles: Object.values(influenceStylesForGraph),
      behaviourTypes: Object.values(behaviourTypes),
    };
  },
  mounted() {
    this.chart = this.renderChart();
  },
  watch: {
    /**
     * @description Watch for any change in active filters, upon change update the graph data
     * @author Kristine de Vries
     */
    activeFilters(value, oldValue) {
      this.updateChartData(value, oldValue);
    },
  },
  methods: {
    /**
     * @description Renders ISQ/ISI graph
     * @author Kristine de Vries
     */
    renderChart() {
      const ctx = this.$refs.ISQISIGraph?.getContext('2d');

      return new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.isISQ
            ? this.behaviourTypes.map(({ ISQGraphValue }) => ISQGraphValue)
            : this.influenceStyles.map(({ ISQGraphValue }) => ISQGraphValue),
          datasets: this.getDataSets(),
        },
        options: {
          scales: {
            x: {
              type: 'linear',
              // Add a bit of extra padding inside the graph on both left and right:
              min: 0.8,
              max: this.isISQ ? 12.3 : 9.5,
              ticks: {
                stepSize: 1,
              },
              position: 'top',
              display: false,
              grid: {
                color: 'transparent',
              },
            },
            y: {
              beginAtZero: true,
              min: 5,
              max: 30,
              ticks: {
                stepSize: 1,
                autoSkip: false,
              },
              grid: {
                color: ({ index }) => ((index - 1) % 6 === 0 ? light : shadowGrey),
              },
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              align: 'start',
              onClick: () => {},
              labels: {
                font: {
                  size: 12,
                  family: 'SFPro-Text',
                },
                boxPadding: 4,
                usePointStyle: true,
                boxWidth: 8,
                color: black,
                padding: 12,
              },
            },
            tooltip: {
              callbacks: {
                title: tooltipItems => {
                  const { dataIndex, label } = tooltipItems[0] || {};
                  return (
                    this.$t(
                      this.isISQ
                        ? this.behaviourTypes[dataIndex]?.label
                        : this.influenceStyles[dataIndex]?.label,
                    ) || label
                  );
                },
              },
            },
          },
          elements: {
            line: {
              borderWidth: 2,
              tension: 0,
            },
          },
        },
      });
    },
    /**
     * @description Get initial data sets
     * @author Kristine de Vries
     */
    getDataSets() {
      return Object.entries(this.data).map(([key, value], index) => {
        const { firstName, prefix, lastName, data } = value;
        const color = ISQISIGraphDataGroups[index]?.color;

        return {
          id: key,
          label: firstName ? formatName(firstName, prefix, lastName) : this.$t('you'),
          data: sortArray(Object.values(data), this.isISQ ? 'behaviourType' : 'influenceStyle').map(
            ({ totalScore }) => totalScore,
          ),
          backgroundColor: 'transparent',
          borderColor: color,
          pointBackgroundColor: color,
          pointRadius: 3,
        };
      });
    },
    /**
     * @description Updates chart data when active filters change
     * NOTE: It is important to keep reference to currentDatasets, when setting
     * the new datasets in orer to avoid redrawing all data
     * @author Kristine de Vries
     */
    updateChartData(currentFilters, oldFilters) {
      const currentDatasets = this.chart.data.datasets;
      // If a new filter has been added
      if (currentFilters.length > oldFilters.length) {
        const difference = currentFilters.filter(item => !oldFilters.includes(item));
        const newDataset = this.getDataSets().find(({ id }) => difference.includes(id));
        this.chart.data.datasets = [...currentDatasets, newDataset];
      } else {
        // If a filter has been removed
        const difference = oldFilters.filter(item => !currentFilters.includes(item));
        this.chart.data.datasets = currentDatasets.filter(
          dataset => !difference.includes(dataset.id),
        );
      }
      this.chart.update();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-isqisi-graph-container {
  border: 1px solid $shadow-grey;
}

.z-item-border {
  border-right: 1px solid $shadow-grey;

  &:last-child {
    border-right: 0px;
  }
}

.z-influence-styles-wrapper,
.z-behaviour-types-wrapper {
  border-bottom: 1px solid $shadow-grey;
}

.z-influence-style-container {
  height: 2.5rem;
  font-size: $font-size-xs;
  word-break: break-all;
}

.z-behaviour-type-container {
  min-height: 2.5rem;
  font-size: 0.5rem;
  width: 8.34%;
}

.z-behaviour-type-number-container {
  font-family: $font-family-mono;
  font-size: $font-size-xs;
  height: 2.5rem;
  width: 8.34%;
}

// Resize behaviourType and influence style label text
// for md screens and up
@include media-breakpoint-up(md) {
  .z-behaviour-type-container {
    font-size: 0.625rem;
  }

  .z-influence-style-container {
    font-size: $font-size-md;
  }

  .z-behaviour-type-number-container {
    font-size: $font-size-sm;
  }
}

// Resize behaviourType and influence style label text
// for lg screens and up
@include media-breakpoint-up(lg) {
  .z-behaviour-type-container {
    font-size: $font-size-xs;
  }

  .z-influence-style-container {
    font-size: $font-size-xl;
  }

  .z-behaviour-type-number-container {
    font-size: $font-size-md;
  }
}

@media print {
  .z-isqisi-graph-canvas {
    max-width: 100%;
    max-height: 16rem;
  }
}
</style>
