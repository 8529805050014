<template>
  <!-- Card tag -->
  <b-badge
    class="py-0 px-1 text-white"
    :style="{
      backgroundColor: backgroundColor,
    }"
  >
    <div class="d-flex align-items-center">
      <!-- Icon -->
      <Icon :src="icon" width="1.25rem" height="1.25rem" />

      <!-- Label -->
      <p class="mb-0 z-badge-label">{{ label }}</p>
    </div>
  </b-badge>
</template>

<script>
/**
 * @file
 * @description Card tag component
 * @author Kristine de Vries
 */
import Icon from '@/components/Icon';

export default {
  name: 'CardTag',
  components: { Icon },
  props: {
    icon: {
      type: String,
      requied: true,
      default: '',
    },
    label: {
      type: String,
      requied: true,
      default: '',
    },
    backgroundColor: {
      type: String,
      requied: true,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/base.scss';

.z-badge-label {
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
}
</style>
