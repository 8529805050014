import moment from 'moment';

import { userRoles, formTypes, moduleTypes, pushNotifications } from '@/utils/enums';
import { getUser, getEvent, getUserConnectionsBasedOnEvent } from '@/graphql/queries';

import vm from '../main';
import {
  graphql,
  mutateItem,
  getCurrentUser,
  formatArrayUserFieldToObject,
  arrayToObject,
  getCurrentEvent,
  removeKeys,
  getFormOrModuleUnlockDate,
  getFormOrModuleCloseDate,
  apiPostRequest,
  restAPIEndpoints,
  showToast,
  createCompositeKey,
} from '../utils/functions';

const { TRAINER, PARTICIPANT } = userRoles;
const { SUPPORTERINVITE } = moduleTypes;
const { SUPPORTERS_INVITE, SUPPORTERS_INVITE_REMINDER } = pushNotifications;
const { ISQ, ISI } = formTypes;
const { pushScheduler } = restAPIEndpoints;

export const SET_STATE_ITEM = 'SET_STATE_ITEM';
export const UPDATE_STATE_ITEM = 'UPDATE_STATE_ITEM';
export const UPDATE_USER_ITEM = 'UPDATE_USER_ITEM';
export const RESET_STATE = 'RESET_STATE';

export default {
  /**
   * @description Initiates user and event data
   * @author Kristine de Vries
   */
  async initiateData({ commit }) {
    try {
      commit(SET_STATE_ITEM, {
        name: 'loading',
        data: true,
      });

      // If user has switched events, get current event id from local storage
      const currentEventId = localStorage.getItem('currentEventId');

      // Get current user
      const cognitoUser = await getCurrentUser();
      const {
        data: { getUser: user },
      } = await graphql(getUser, {
        id: cognitoUser.attributes.sub,
      });

      const { id, role, tenantId } = user;

      // Only allow participants and trainers in the app
      if (role !== PARTICIPANT && role !== TRAINER) {
        throw new Error('No access');
      }

      // Get user's events
      const events =
        role === TRAINER
          ? arrayToObject(
              user.events.items.map(item => ({
                ...item.event,
                registrationStatusGo: true,
              })),
            )
          : arrayToObject(
              user.registrations.items.map(item => ({
                ...item.event,
                registrationStatusGo: user.registrations.items.some(
                  registration => item.eventId === registration.eventId && registration.statusGo,
                ),
              })),
            );

      // Get the current event id and set it in state
      const { eventId } = getCurrentEvent(events, currentEventId);

      if (!eventId) {
        throw new Error('No access');
      }

      // Get user's finished forms - initially for a new user will be null, in which case we set it to an empty object
      // Set id to be composite of formId and eventId
      user.finishedForms = user.finishedForms
        ? formatArrayUserFieldToObject(user.finishedForms, 'eventId', 'formId')
        : {};

      commit(SET_STATE_ITEM, {
        name: 'user',
        data: user,
      });

      commit(SET_STATE_ITEM, {
        name: 'events',
        data: events,
      });

      commit(SET_STATE_ITEM, {
        name: 'currentEventId',
        data: eventId,
      });

      if (role !== TRAINER) {
        // Get current registration id and set it in state
        const { id: currentRegistrationId } =
          user.registrations.items
            .filter(registration => registration.eventId === eventId)
            .find(item => item.statusGo) || {};

        commit(SET_STATE_ITEM, {
          name: 'currentRegistrationId',
          data: currentRegistrationId,
        });

        // If user is a participant and does not have a registration with statusGo true or event's
        // statusGo true is false throw a new error
        if (!currentRegistrationId || !events[eventId].statusGo) {
          throw new Error('No access');
        }
      }

      // Get user's question answers for current event and set them in state
      const {
        data: { getUser: eventData },
      } = await graphql(getUserConnectionsBasedOnEvent, {
        id,
        eventId,
      });

      const answers = arrayToObject(eventData.answers.items);
      commit(SET_STATE_ITEM, {
        name: 'answers',
        data: answers,
      });

      // Get supporters and supporter answers
      const supporters = arrayToObject(
        eventData.supporters.items.map(item => ({
          ...item,
          finishedForms: arrayToObject(item.finishedForms || [], 'formId') || {},
          answers: Object.fromEntries(
            Object.entries(
              arrayToObject(
                item.answers
                  ? item.answers.map(answer => ({
                      id: answer.questionId,
                      ...answer,
                    }))
                  : [],
              ) || {},
            ).map(([key, value]) => [key, removeKeys(value, 'id')]),
          ),
        })),
      );

      commit(SET_STATE_ITEM, {
        name: 'supporters',
        data: supporters,
      });

      // Get other current event data and set it in state
      const {
        data: { getEvent: event },
      } = await graphql(getEvent, {
        id: eventId,
        tenantId,
      });

      const {
        firstDay,
        lastDay,
        formQuestions: { items: eventFormQuestions },
        modules: { items: eventModules },
      } = event;

      const startDate = +moment.utc(firstDay);
      const endDate = +moment.utc(lastDay);

      // Filter only form types that are needed in the webapp
      const formQuestions = eventFormQuestions.filter(({ form: { type } }) =>
        Object.keys(formTypes).includes(type),
      );

      // Set form questions. NOTE: Archived questions are not filtered to ensure any forms
      // in the event which are connected to this question don't break
      const questions = arrayToObject(
        formQuestions
          .map(item => item.question)
          .map(item => ({
            ...item,
            translations: arrayToObject(item.translations.items, 'language'),
          })),
      );

      commit(SET_STATE_ITEM, {
        name: 'questions',
        data: questions,
      });

      // Set forms
      const forms = formQuestions.reduce((acc, curr) => {
        const {
          formId,
          archived,
          openDaysToEvent,
          openDateReference,
          closeDaysToEvent,
          closeDateReference,
          anonymiseSupporters,
        } = curr;
        if (acc[formId] || archived === 'true') {
          return acc;
        }
        const form = {
          ...curr.form,
          questions: formQuestions
            .filter(item => item.formId === formId && item.question.archived !== 'true')
            .map(item => item.questionId),
          translations: arrayToObject(curr.form.translations.items, 'language'),
          unlockDate: getFormOrModuleUnlockDate(
            openDaysToEvent,
            openDateReference,
            startDate,
            endDate,
          ),
          closeDate: getFormOrModuleCloseDate(
            closeDaysToEvent,
            closeDateReference,
            startDate,
            endDate,
          ),
          anonymiseSupporters,
        };

        acc[curr.formId] = form;
        return acc;
      }, {});

      commit(SET_STATE_ITEM, {
        name: 'forms',
        data: forms,
      });

      // Set modules
      const modules = arrayToObject(eventModules.map(({ module }) => ({ ...module })));

      commit(SET_STATE_ITEM, {
        name: 'modules',
        data: modules,
      });
    } catch (e) {
      // Reset the state when there is an error
      commit(RESET_STATE);
      throw e;
    } finally {
      commit(SET_STATE_ITEM, {
        name: 'loading',
        data: false,
      });
    }
  },

  /**
   * @description Resets the state to initial state
   * @author Kristine de Vries
   */
  resetState({ commit }) {
    commit(RESET_STATE);
  },

  /**
   * @description Updates or creates an item in the database and updates the vuex state
   * @author Kristine de Vries
   */
  async mutateItem({ commit }, payload) {
    const { item, mutationName, name } = payload;
    const result = await mutateItem({ item, mutationName });
    const { id } = result;

    commit(UPDATE_STATE_ITEM, {
      name,
      id,
      data: result,
    });
    return result;
  },

  /**
   * @description Updates finished forms in the database user table and vuex state
   * @param {object} state
   * @param {function} commit
   * @param {object} payload
   * @author Thiago Fazzi
   */
  async updateFinishedForms({ state, commit }, payload) {
    const { formId, eventId, open } = payload.item;
    const {
      user: { id, tenantId, finishedForms },
      modules,
      forms,
    } = state;

    // Check if we are updating an already finished form (form has been re-opened)
    // or finishing a new form:
    const updatingFinishedForm = Object.values(finishedForms).some(
      item => item.formId === formId && item.eventId === eventId,
    );

    const finishedForm = {
      formId,
      eventId,
      open,
    };

    await mutateItem({
      item: {
        id,
        finishedForms: updatingFinishedForm
          ? [
              ...Object.values(finishedForms).map(item =>
                item.formId === formId && item.eventId === eventId ? finishedForm : item,
              ),
            ]
          : [...Object.values(finishedForms), finishedForm],
      },
      mutationName: 'updateUser',
    });

    const updatedFinishedForms = {
      ...finishedForms,
      [createCompositeKey(eventId, formId)]: {
        formId,
        eventId,
        open: false,
      },
    };

    commit(UPDATE_USER_ITEM, {
      name: 'finishedForms',
      data: updatedFinishedForms,
    });

    const hasSupporterModule = Object.values(modules).some(
      module => module.type === SUPPORTERINVITE,
    );
    const isISQISIForm = forms[formId]?.type === ISQ || forms[formId]?.type === ISI;

    // If user has finished ISQ/ISI form schedule in supporter push notifications
    if (hasSupporterModule && !updatingFinishedForm && isISQISIForm) {
      await apiPostRequest(pushScheduler, {
        userIds: [id],
        eventId,
        tenantId,
        notificationTypes: [SUPPORTERS_INVITE, SUPPORTERS_INVITE_REMINDER],
      });
    }
  },

  /**
   * @description Updates an item in the vuex state first and then in the database
   * Used when we require instant feedback to the user about the changes they have made
   * @author Kristine de Vries
   */
  async updateItemInstantly({ commit }, payload) {
    const { item, oldItem, mutationName, name } = payload;
    const { id } = item;

    try {
      commit(UPDATE_STATE_ITEM, {
        name,
        id,
        data: item,
      });
      await mutateItem({ item, mutationName });
    } catch (e) {
      if (oldItem) {
        commit(UPDATE_STATE_ITEM, {
          name,
          id,
          data: oldItem,
        });
      }
      showToast(vm);
    }
  },
};
