<template>
  <BaseHeader :title="title" :goBackLabel="$t('dashboard')" class="sticky-top">
    <template #footer>
      <!-- Progress bar -->
      <transition appear mode="out-in" name="z-fade">
        <ProgressBar v-if="progressBar" :maxSteps="maxSteps" :questionIndex="questionIndex" />
      </transition>
    </template>
  </BaseHeader>
</template>

<script>
import BaseHeader from '@/components/layout/BaseHeader';
import ProgressBar from '@/components/ProgressBar';

/**
 * @file
 * @description Form screen header
 * @author Kristine de Vries
 */
export default {
  name: 'Header',
  components: {
    BaseHeader,
    ProgressBar,
  },
  props: {
    maxSteps: {
      type: Number,
      required: false,
    },
    questionIndex: {
      type: Number,
      required: false,
    },
    progressBar: {
      type: Boolean,
      require: true,
      default: false,
    },
    title: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/partials/_transitions.scss';
</style>
